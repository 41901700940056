import React, { useEffect } from "react";
import "./Newemp.css";

import { Select } from "@mui/material";
import { useState } from "react";
import { Loader_rotatelines, getdateformatvalue, getdateformatvaluenullstr } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from "../../../Redux/Slice/Loginslice";
import { calladmnewemp_service, callnewemppost_service, handlesetstatuserroradmnewemp } from "../../../Redux/Slice/Adminpanelslices/Admnewempslice";
import { calladminpanel_service } from "../../../Redux/Slice/Adminpanelslices/Adminpanelslice";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
const Newemp = () => {
  const navigate = useNavigate();
  const loginpage_slice = useSelector((state) => state.login);
  const adminpanelpage_slice = useSelector((state) => state.adminpanel);
  const [departments,setDepartments] = useState([]);
  const today = new Date();

  useEffect(()=>{
    if (loginpage_slice.data === null) {
      setDepartments([]);

    } else {
      setDepartments(loginpage_slice.data.departments)
    }
  },[loginpage_slice.data])
 
  
  

  const admnewemppage_slice = useSelector((state) => state.admnewemp);
  useEffect(() => {
    dispatch(handlesetstatuserroradmnewemp());
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (admnewemppage_slice.newid !== null) {
        calladmnewemp_get_api()
      }
      //
    }
  },[])
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (admnewemppage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (admnewemppage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }  else if ((admnewemppage_slice.status !== '200' && admnewemppage_slice.status !=="") || admnewemppage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserroradmnewemp());
      
      navigate("/error")
    }

  },[admnewemppage_slice.error,admnewemppage_slice.status])


  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && admnewemppage_slice.newid === null) {
      calladmnewemp_get_api() }
  },[loginpage_slice.data['access']])


  


  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (admnewemppage_slice.newid != null) {
      setIdvalue(admnewemppage_slice.newid)


      
    }
  },[admnewemppage_slice.newid])
  useEffect(()=>{
    if (departments != null) {
      if (departments.length !== 0) {
        setDeptselected(departments[0].department)
      }
    }
  },[departments])

  const [namevalue, setNamevalue] = useState(""
  );
  const [idvalue, setIdvalue] = useState(null
  );
  
  const [fathervalue, setFathervalue] = useState(""
  );
  const [mobilevalue, setMobilevalue] = useState(""
  );
  const [mailvalue, setMailvalue] = useState(""
  );
  const [adrvalue, setAdrvalue] = useState("");
  const [bloodvalue, setBloodvalue] = useState(""
  );
  const [qualivalue, setQualivalue] = useState(""
  );
  const [deptselected, setDeptselected] = useState(
  );
  const [desigvalue, setDesigvalue] = useState(
  );
  const [desigseqvalue, setDesigseqvalue] = useState(10
  );
  const [tlvalue, settlvalue] = useState(0
  );
  const [birthvalue, setBirthvalue] = useState(getdateformatvaluenullstr(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()))
  );
  const [joinvalue, setJoinvalue] = useState(getdateformatvaluenullstr(new Date())
  );
  const [genderselected, setGenderselected] = useState("Male"
  );
 
  const [imgvalue, setImgvalue] = useState("");

  const [previewUrl, setPreviewUrl] = useState(null
  );





  const namechangehandle = (event) => {
    setNamevalue(event.target.value);
  };
  const idchangehandle = (event) => {
    setIdvalue(event.target.value);
  };
  const fatherchangehandle = (event) => {
    setFathervalue(event.target.value);
  };
  const mobilechangehandle = (event) => {
    setMobilevalue(event.target.value.trim());
  };
  const mailchangehandle = (event) => {
    setMailvalue(event.target.value.trim());
  };
  const adrchangehandle = (event) => {
    setAdrvalue(event.target.value);
  };
  const bloodchangehandle = (event) => {
    setBloodvalue(event.target.value.trim());
  };
  const qualichangehandle = (event) => {
    setQualivalue(event.target.value);
  };
  const newdeptselected = (event) => {
    setDeptselected(event.target.value);
  };
  const desigchangehandle = (event) => {
    setDesigvalue(event.target.value);
  };
  const desigseqchangehandle = (event) => {
    console.log(event.target.value)
    setDesigseqvalue(event.target.value);
  };
  const tlchangehandle = (event) => {
    console.log(event.target.value)
    settlvalue(event.target.value);
  };
  const dobchangehandle = (event) => {
    if (event.target.value === "") {
      setBirthvalue(null)

    } else {
      setBirthvalue(getdateformatvaluenullstr(event.target.value));
    }
  };
  const dojchangehandle = (event) => {
    if (event.target.value === "") {
      setJoinvalue(null)

    } else {
      setJoinvalue(getdateformatvaluenullstr(event.target.value));
    }
    
  };

  const handlegenderselect = (event) => {
    setGenderselected(event.target.value);
  };
  
  const photochangehandle = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImgvalue(file); // Validate file type
      //setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      //setSelectedFile(null); // Clear preview if invalid file
      setPreviewUrl(null);
      setImgvalue("");
      alert("Please select an image file.");
    }
  };

  const dispatch = useDispatch();
  






  const calladmnewemp_get_api = ()=> {
    dispatch(calladmnewemp_service(`${loginpage_slice.data['access']}`))


  
  }


  const calladminpanel_get_api = ()=> {
    dispatch(calladminpanel_service(`${loginpage_slice.data['access']}`))
  
  }


  const [createpressed,setCreatepressed] = useState(false)
  useEffect(()=>{
    
    if (createpressed) {
      console.log(admnewemppage_slice.createsuccess)
      if (admnewemppage_slice.createsuccess === "done") {
        swal("Employee created!", {
          buttons: false,
          icon:"success",
          title:"Success!"
        });
        setCreatepressed(q=>!q)
        setIdvalue(idvalue + 1)
        setNamevalue("");
        setFathervalue("");
        setMobilevalue("");
        setMailvalue("");
        setAdrvalue("");
        setBloodvalue("");
        setQualivalue("");
        if (departments !== null) {
          if (departments.length !== 0) {
            setDeptselected(departments[0].department);
          } else {
            setDeptselected(null)
          }
        } else {
          setDeptselected(null)
        }
        setDesigvalue("");
        setDesigseqvalue(10);
        settlvalue(0);
        setBirthvalue(getdateformatvaluenullstr(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())));
        setJoinvalue(new Date());
        setGenderselected("Male");
        setImgvalue("");
        setPreviewUrl(null);
        




      } else if (admnewemppage_slice.createsuccess === "fail") {
        swal("Failed to create new employee!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
        setCreatepressed(q=>!q)
      }
      
     
    }
  },[admnewemppage_slice.createsuccess])


  const createnewemp_api_call = ()=> {
    if (createpressed === false) {
      setCreatepressed(q=>!q)
    }
    console.log("ss")
    var data = {comp_code: "EMSL",areg_sno:idvalue,
    ssn:idvalue,
    ename:namevalue,
    doj: `${getdateformatvalue(joinvalue)} 00:00:00`,
    dept: deptselected,
    designation: desigvalue,
    sex: genderselected === "Male" ? "M" : genderselected === "Female" ? "F" : "O",
    dob: `${getdateformatvalue(birthvalue)} 00:00:00`,
    empstatus: "W",
    createddate: `${getdateformatvalue(new Date())} 00:00:00`,
    fhgname: fathervalue,
    qualification: qualivalue,
    addr1: adrvalue,
    desig_seq: desigseqvalue,
    phone: mobilevalue,
    email : mailvalue,
    bloodgroup: bloodvalue,
    team_leader_eno: tlvalue

  
  }
  if (imgvalue !== "") {
          
    data = { ...data, ...{ photo: imgvalue } };
  }
  dispatch(callnewemppost_service([loginpage_slice.data['access'],data]))

  }


























    
    
  return (
    <div className="newemp">
      {(admnewemppage_slice.loading || loginpage_slice.loading || adminpanelpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
      {admnewemppage_slice.newid !== null && <div className="newempinner">
      <h3
        className="newemphead"
        style={{ textAlign: "center", paddingBottom: "20px" }}
      >
        New employee
      </h3>
      <div className="newempgridcontainer">
        <div className="userinfogridnewemp">
          <div className="newempitem">
            <span>ID <span id='asteriskspan'>*</span></span>
            <input value={idvalue} onChange={idchangehandle} onWheel={(e) => e.target.blur()} type="number"  placeholder={admnewemppage_slice.newid} />
          </div>
          <div className="newempitem">
            <span>Name <span id='asteriskspan'>*</span></span>
            <input value={namevalue} onChange={namechangehandle} type="text" placeholder="Name" />
          </div>
          <div className="newempitem">
            <span>Father's name</span>
            <input value={fathervalue} onChange={fatherchangehandle} type="text" placeholder="Father's name" />
          </div>
          <div className="newempitem">
            <span>Mobile</span>
            <input value={mobilevalue} onChange={mobilechangehandle} onWheel={(e) => e.target.blur()} type="text" placeholder="01XXXXXXXXX" />
          </div>
          <div className="newempitem">
            <span>Email</span>
            <input value={mailvalue} onChange={mailchangehandle} type="email" placeholder="abc@example.com" />
          </div>
          <div className="newempitem">
            <span>Address <span id='asteriskspan'>*</span></span>
            <input value={adrvalue} onChange={adrchangehandle} type="text" placeholder="Mazar Road, Dhaka" />
          </div>
          <div className="newempitem">
            <span>Blood Group</span>
            <input value={bloodvalue} onChange={bloodchangehandle} type="text" placeholder="A+" />
          </div>
          <div className="newempitem">
            <span>Qualification</span>
            <input value={qualivalue} onChange={qualichangehandle} type="text" placeholder="MBA" />
          </div>
          <div className="newempitem">
            <span>Department <span id='asteriskspan'>*</span></span>
            <select className="selectdeptnewemp" value={deptselected} onChange={newdeptselected}>
            {departments.map((q,i) => (
                    <option key={i}> {q.department}</option>
                  ))}
            </select>
          </div>
          <div className="newempitem">
            <span>Designation <span id='asteriskspan'>*</span></span>
            <input value={desigvalue} onChange={desigchangehandle} type="text" placeholder="Assistant Manager" />
          </div>
          <div className="newempitem">
            <span>Designation sequence</span>
            <input value={desigseqvalue} onChange={desigseqchangehandle} onWheel={(e) => e.target.blur()} type="number" placeholder="0" />
          </div>
          <div className="newempitem">
            <span>Teamleader ID</span>
            <input value={tlvalue} onChange={tlchangehandle} onWheel={(e) => e.target.blur()} type="number" placeholder="0" />
          </div>
          <div className="newempitem">
            <span>Date of birth</span>
            <input value={birthvalue} onChange={dobchangehandle} type="date" placeholder="" />
          </div>
          <div className="newempitem">
            <span>Date of joining <span id='asteriskspan'>*</span></span>
            <input value={joinvalue} onChange={dojchangehandle}  type="date" placeholder="" />
          </div>
          
          <div className="newempitem">
            <span>Gender <span id='asteriskspan'>*</span></span>
            <select className="selectdeptnewemp" value={genderselected} onChange={handlegenderselect}>
                <option >Male</option>
                <option > Female</option>
                <option > Others</option>
            </select>
          </div>
          <div className="newempitem">
            <input type="file" multiple={false}
                  accept="image/*" placeholder="" onChange={photochangehandle}/>
            <img className={`userimg M`} src={previewUrl} alt="" />

          </div>
          
        </div>
        
        <button disabled={(namevalue === "" || idvalue === "" || adrvalue === "" || desigvalue === "") || admnewemppage_slice.loading} onClick={()=>createnewemp_api_call()} className="createempbtn">Create</button>
      </div>
    </div>}
    </div>
  );
};

export default Newemp;
