import React, { useEffect, useState } from 'react'
import Leavedetailscontainer from '../../Elements/Leavedetailscontainer/Leavedetailscontainer'
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../Redux/Slice/Loginslice';
import { callleavestatus_service, handlesetstatuserrorlvstatus } from '../../Redux/Slice/Leavestatusslice';
import { Loader_rotatelines } from '../../App';
import { useNavigate } from 'react-router-dom';

const Leavestatus = () => {
  
  const [resized, setResized] = useState(false);


  const loginpage_slice = useSelector((state) => state.login);
  
  

  const leavestatuspage_slice = useSelector((state) => state.leavestatus);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserrorlvstatus())
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (leavestatuspage_slice.resultsdata !== null) {
      callleavestatus_get_api("null") }
    }
    const resizefunc = () => {
      console.log(resized);
      if (window.innerHeight >= document.documentElement.scrollHeight) {
        if (resized === false) {
          setResized((prev) => !prev);
        }
      }
    };
    const scrollfunc = () => {
      console.log(resized);

      if (
        window.innerHeight + document.documentElement.scrollTop + 200 >=
        document.documentElement.scrollHeight
      ) {
        if (resized === false) {
          setResized((prev) => !prev);
          console.log("oks");
        } else {
          console.log("ss");
        }
      }
    };
    window.addEventListener("resize", resizefunc);
    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("resize", resizefunc);
      window.removeEventListener("scroll", scrollfunc);
    };
  },[])
  const navigate = useNavigate()
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    if (leavestatuspage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (leavestatuspage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }else if ((leavestatuspage_slice.status !== '200' && leavestatuspage_slice.status !=="") || leavestatuspage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserrorlvstatus())
      
      navigate("/error")
    }

  },[leavestatuspage_slice.error,leavestatuspage_slice.status])
  useEffect(() => {
    console.log("resss");

    if (resized === true) {
      console.log("res");
      moreload();
    }
  }, [resized]);

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && leavestatuspage_slice.resultsdata === null) {
      callleavestatus_get_api("null") }
  },[loginpage_slice.data['access']])


  useEffect(() => {
    if (
      leavestatuspage_slice.resultsdata !== null &&
      (window.innerHeight >= document.documentElement.scrollHeight ||
        window.innerHeight >= document.documentElement.scrollHeight)
    ) {
      moreload();
    } else {
      setResized(false);
    }
  }, [leavestatuspage_slice.resultsdata]);


  const callleavestatus_get_api = (nextlinkpass)=> {
    dispatch(callleavestatus_service([`${loginpage_slice.data['access']}`,nextlinkpass === "null" ? "null" : leavestatuspage_slice.next]))
  
  }
  

  const moreload = () => {
    console.log(leavestatuspage_slice.next);

    if (
      leavestatuspage_slice.loading === false &&
      leavestatuspage_slice.resultsdata.length < leavestatuspage_slice.count
    ) {
      console.log("1234567");

      callleavestatus_get_api("notnull");

      console.log(document.documentElement.scrollHeight);
      console.log(window.innerHeight);
    } else {
      setResized(false);
      console.log("dd");
    }
  };














  
  
  
  return (
    <div className='admemplvmaincontainer'>
       {(leavestatuspage_slice.loading || loginpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
       { leavestatuspage_slice.resultsdata !== null &&
        <div className='admemplv'>
        <h3 style={{marginBottom:"30px"}}>Leave status </h3>
        
          <div className="admlvrevcontainer">
          <h3 style={{textAlign:"center",background:"rgba(58, 160, 255, 0.799)",color:"white",padding:"5px",borderRadius:"0px"}}>{leavestatuspage_slice.count} applications</h3>
          {leavestatuspage_slice.resultsdata.map((q,i) => (
            <Leavedetailscontainer leavestatus={q}  i={i} tabrev={"reviewed"}/>
          ))}
        
          </div>
      </div>
       }
    </div>
    
  )
}

export default Leavestatus