import React, { useEffect, useState } from 'react'
import "./Myemplvapp.css"

import Leavedetailscontainer from '../../../Elements/Leavedetailscontainer/Leavedetailscontainer'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../../Redux/Slice/Loginslice';
import { callmyemplvapps_service, changeupdatepressvaluemyemplv, handlesetstatuserrormyemplvapp } from '../../../Redux/Slice/Myemployeesslices/Myemplvappslice';
import { callmyemppanel_service } from '../../../Redux/Slice/Myemployeesslices/Myemppanelslice';
import { Loader_rotatelines } from '../../../App';
import swal from "sweetalert";
const Myemplvapp = () => {
  const navigate = useNavigate();
  
  const [empid,setEmpid] = useState("");
  const [resized, setResized] = useState(false);
  const [tabchange,setTabchange] = useState(false);


  const loginpage_slice = useSelector((state) => state.login);
  const myempppanelpage_slice = useSelector((state) => state.myemppanel);
  
  

  const myemplvappspage_slice = useSelector((state) => state.myemplvapps);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserrormyemplvapp())
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (myemplvappspage_slice.resultsdata !== null) {
        callmyemplvapps_get_api("null")
      }
     // 
    }
    
    const resizefunc = () => {
      console.log(resized);
      if (window.innerHeight >= document.documentElement.scrollHeight) {
        if (resized === false) {
          setResized((prev) => !prev);
        }
      }
    };
    const scrollfunc = () => {
      console.log(resized);

      if (
        window.innerHeight + document.documentElement.scrollTop + 200 >=
        document.documentElement.scrollHeight
      ) {
        if (resized === false) {
          setResized((prev) => !prev);
          console.log("oks");
        } else {
          console.log("ss");
        }
      }
    };
    window.addEventListener("resize", resizefunc);
    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("resize", resizefunc);
      window.removeEventListener("scroll", scrollfunc);
    };
  },[])
  
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])


  useEffect(()=> {
    if (loginpage_slice.data['access'] !== '' && tabchange === true) {
      callmyemplvapps_get_api("null")
      if (tabchange === true) {
        setTabchange(q=>!q)
      }
    
    }
  
  },[tabchange])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (myemplvappspage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (myemplvappspage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }  else if (myemplvappspage_slice.error === "noemp") {
      console.log("ss")
      dispatch(handlesetstatuserrormyemplvapp())
      

    }  else if ((myemplvappspage_slice.status !== '200' && myemplvappspage_slice.status !=="") || myemplvappspage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserrormyemplvapp())
      
      navigate("/error")
    }

  },[myemplvappspage_slice.error,myemplvappspage_slice.status])
  useEffect(() => {
    console.log("resss");

    if (resized === true) {
      console.log("res");
      moreload();
    }
  }, [resized]);
  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && myemplvappspage_slice.resultsdata === null) {
      callmyemplvapps_get_api("null") }
  },[loginpage_slice.data['access']])


  
/* 
  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (myempppanelpage_slice.data != null) {


      if (myempppanelpage_slice.data !== "done") {
        navigate("/dashboard")
      } else {
        if (loginpage_slice.data['access'] !== '') {
          console.log("change")
          callmyemplvapps_get_api("null") }
      }
    }
  },[myempppanelpage_slice.data]) */
  useEffect(() => {
    if (
      myemplvappspage_slice.resultsdata !== null &&
      (window.innerHeight >= document.documentElement.scrollHeight ||
        window.innerHeight >= document.documentElement.scrollHeight)
    ) {
      moreload();
    } else {
      setResized(false);
    }
  }, [myemplvappspage_slice.resultsdata]);

























  const callmyemplvapps_get_api = (nextlinkpass)=> {
    console.log(nextlinkpass)
    if (loginpage_slice.data['access'] !== '') {
    dispatch(callmyemplvapps_service([`${loginpage_slice.data['access']}`,empid === "" ? "null" : empid,tabreview,nextlinkpass === "null" ? "null" : myemplvappspage_slice.next]))
  }
  
  }
  const callmyemppanel_get_api = ()=> {
    dispatch(callmyemppanel_service(`${loginpage_slice.data['access']}`))
  
  }



  const moreload = () => {
    console.log(myemplvappspage_slice.next);

    if (
      myemplvappspage_slice.loading === false &&
      myemplvappspage_slice.resultsdata.length < myemplvappspage_slice.count
    ) {
      console.log("1234567");

      callmyemplvapps_get_api("notnull");

      console.log(document.documentElement.scrollHeight);
      console.log(window.innerHeight);
    } else {
      setResized(false);
      console.log("dd");
    }
  };





  //const [updatepressed,setUpdatepressed] = useState(false)
  const [lvpostmsg,setLvpostmsg] = useState('')


  useEffect(()=> {
    console.log(myemplvappspage_slice.myempupdatepostresponsebody)
    console.log(myemplvappspage_slice.updatepressed)
    if (myemplvappspage_slice.myempupdatepostresponsebody != ''  && myemplvappspage_slice.updatepressed === true) {
      
      console.log("sss")
      if (myemplvappspage_slice.myempupdatepostresponsebody === "accept done") {
        console.log("sss")
        console.log("sss")
        
        swal("Leave application is now waiting for Admin's approval!", {
          buttons: false,
          icon:"success",
          title:"Accepted!"
        });
      } else if (myemplvappspage_slice.myempupdatepostresponsebody === "reject done") {
        console.log("sss")
        console.log("sss")
        
        swal("Leave application rejected!", {
          buttons: false,
          icon:"success",
          title:"Rejected!"
        });
      } else  {
          swal("Your leave approval failed!", {
            buttons: false,
            icon:"error",
            title:"Failed!"
          });
        
      }
      
      
      dispatch(changeupdatepressvaluemyemplv(false));

    }
    
  },[myemplvappspage_slice.myempupdatepostresponsebody])
  



























  const [tabreview,setTabreview] =useState("Unreviewed")

  const handleidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setEmpid("")
    } else {
      setEmpid(event.target.value)
    }
    
  }
  useEffect(()=> {
    console.log("change")
    callmyemplvapps_get_api("null");
  }, [tabreview])
  
  
  return (
    <div className='myemplvouter'>
      {(myemplvappspage_slice.loading || myemplvappspage_slice.updateloading || loginpage_slice.loading || myempppanelpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
       
       <div className='myemplv'>
      <h3>All employees leave status </h3>
      <div className="myemplvrevtabscontainer">
          <span>Application status:</span>
          <div className="myemplvreviewtabs">
            <button disabled={myemplvappspage_slice.loading} className={`tabbuttonsmyemplvrev ${tabreview === "Unreviewed" ? "active":""}`} onClick={()=>{
              setTabreview("Unreviewed")
              if (tabchange === false) {
                setTabchange(q=>!q)
              }
              }}>Unreviewed</button>
            <button  disabled={myemplvappspage_slice.loading} className={`tabbuttonsmyemplvrev ${tabreview === "Reviewed" ? "active":""}`} onClick={()=>{
              setTabreview("Reviewed")
              if (tabchange === false) {
                setTabchange(q=>!q)
              }
              }}>Reviewed</button>
          </div>
        </div>
        <div className="inputinmyemplvappscontainer">
            <input value={empid} onChange={handleidchange} maxLength={6} type="text" placeholder='Employee ID'/>
            <button disabled={myemplvappspage_slice.loading}  className='srchbtnmyemplv' onClick={()=>callmyemplvapps_get_api("null")}>Search</button>
        </div>
        {(myemplvappspage_slice.resultsdata === null || tabreview !== myemplvappspage_slice.currenttab) ? (myemplvappspage_slice.loading === false && <div className='nonefoundmyemplv'>
          <h3>No employee found!</h3>
        </div>) :
        
        <div className="myemplvrevcontainer">
        <h3 style={{textAlign:"center",background:"rgba(58, 160, 255, 0.799)",color:"white",padding:"5px",borderRadius:"0px"}}>{myemplvappspage_slice.count} applications</h3>
        {myemplvappspage_slice.resultsdata.map((q,i) => (
            
            <Leavedetailscontainer leavestatus={q} i={i} tabrev={tabreview} frompage={"myemp"} page_slice={myemplvappspage_slice} loginaccessvalue={loginpage_slice.data['access']}/>
          ))}
      
        </div>}
    </div>
    </div>
  )
}

export default Myemplvapp