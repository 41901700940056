



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../App";


export const calluserinfo_service = createAsyncThunk("calluserinfo", async (data) => {
    console.log(data)
    
    const response = await fetch(`${myport}emp_get/`,{headers: {
        'Authorization': `Bearer ${data}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})



export const callupdate_self_service = createAsyncThunk("callupdateself", async (data) => {
    console.log(`${myport}userlogin_get/`)
    const response = await fetch(`${myport}userselfinfoupdate/`,{method:'PUT',body: JSON.stringify(data[1]),headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include"
      ,});
      console.log(response.status)
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[1]];
})

export const userinfoSlice = createSlice({
    name: "userinfo",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,

      },
      reducers: {
        handlesetstatuserroruserinfo(state,action) {
            state.data = null;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calluserinfo_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calluserinfo_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                console.log("ssss")
            state.data = action.payload[0];
            state.error = null;

           } else {
            state.error = null;

           }
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(calluserinfo_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });








        builder.addCase(callupdate_self_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callupdate_self_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                if (action.payload[0] === "done") {
                    if ("email" in action.payload[2]) {
                        console.log(action.payload[2]["email"])
                        state.data["email"] = action.payload[2]["email"]
                    }
                    if ("phone" in action.payload[2]) {
                        console.log(action.payload[2]["phone"])
                        state.data["phone"] = action.payload[2]["phone"]
                    } 
                    if ("addr1" in action.payload[2]) {
                        console.log(action.payload[2]["addr1"])
                        state.data["addr1"] = action.payload[2]["addr1"]
                    } 
                    if ("password" in action.payload[2]) {
                        console.log(action.payload[2]["password"])
                        state.data["password"] = action.payload[2]["password"]
                    } 
                
               
                state.error = null;
                } else {
                    
                    state.error = action.payload[0]
                    console.log(state.error)
                }

           } else {
            state.error = null;

           }
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(callupdate_self_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserroruserinfo } = userinfoSlice.actions
export default userinfoSlice.reducer