



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getdateformatvalue, myport } from "../../../App";



export const calladmcalendar_service = createAsyncThunk("calladmcalendar", async (data) => {
    console.log(data[0])
    
    const response = await fetch(`${myport}everydayget/${data[1]}/${data[2]}/`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})



export const calladmcalendareupdate_service = createAsyncThunk("calladmcalendareupdate", async (data) => {
    console.log(`${myport}userlogin_get/`)
    const response = await fetch(`${myport}everydayholidayupdate/${getdateformatvalue(data[2])}/`,{method:'PUT',body: JSON.stringify(data[1]),headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include"
      ,});
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status,data[1],data[2]];
})

export const admcalendarSlice = createSlice({
    name: "admcalendar",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,

      },
      reducers: {
        handlesetstatuserroradmcalen(state,action) {
            state.data = null;
            console.log(state.status)
            console.log(state.error)
            if (state.status === "401") {
                state.status = ""
            }
            if (state.error === "alreadyloggedout") {
                state.error = null;
            }
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calladmcalendar_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladmcalendar_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"
    
            } else if (action.payload[1] === 200) {
                state.error = null;
            
            
            state.data = action.payload[0];
        } else {
            state.error = null;
        }
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.status);
            
        });
        builder.addCase(calladmcalendar_service.rejected, (state,action) => {
            
            state.loading = false;
            state.error = action.error.message;
        });















        builder.addCase(calladmcalendareupdate_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladmcalendareupdate_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                if (action.payload[0] === "done") {
                    console.log(action.payload[3])
                    state.data.map((q,i) => {
                        if (q.today === action.payload[3]) {
                            console.log(state.data[i])
                            state.data[i].worh = action.payload[2].worh
                            console.log(state.data[i])
                        }
                    })
                    
               
                state.error = null;
                } else {
                    state.error = action.payload[0]
                }

           } else {
            state.error = null;

           }
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(calladmcalendareupdate_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserroradmcalen } = admcalendarSlice.actions
export default admcalendarSlice.reducer