import React, { useEffect } from 'react'
import "./Modifyemp.css"
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import Dtsheet from '../../../Elements/Dtsheet/Dtsheet';
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../../Redux/Slice/Loginslice';
import { calladmmodemp_service, callupdateemplv_adm_service, handlesetstatuserroradmmodifyemp } from '../../../Redux/Slice/Adminpanelslices/Modifyempslice';
import { calladminpanel_service } from '../../../Redux/Slice/Adminpanelslices/Adminpanelslice';
import { Loader_rotatelines, getdateformatvalue } from '../../../App';
import { useNavigate } from 'react-router-dom';
import Leavesheet from '../../../Elements/Leavesheet/Leavesheet';
const Modifyemp = () => {
  const navigate = useNavigate()

  const loginpage_slice = useSelector((state) => state.login);
  const adminpanelpage_slice = useSelector((state) => state.adminpanel);
  
  const [isdtsheetopen,setIsdtsheetopen] = useState(false);
  

  const admmodemppage_slice = useSelector((state) => state.admmodemp);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserroradmmodifyemp());
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (admmodemppage_slice.data !== null) {
        calladmmodemp_get_api()
      }
      //calladmmodemp_get_api() 
    }
  },[])
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (admmodemppage_slice.error === "alreadyloggedout") {
      dispatch(callunauth_logout_service())
      
    } else if (admmodemppage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }  else if (admmodemppage_slice.error === "noemp") {
      console.log("ss")
      dispatch(handlesetstatuserroradmmodifyemp())
      

    } else if ((admmodemppage_slice.status !== '200' && admmodemppage_slice.status !=="") || admmodemppage_slice.error !== null) {
      dispatch(handlesetstatuserroradmmodifyemp())
      console.log(admmodemppage_slice.error,admmodemppage_slice.status)
      
      navigate("/error")
    }


  },[admmodemppage_slice.error,admmodemppage_slice.status])
  


  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '') {
      setEmpid(loginpage_slice.data["mysno"])
      if (admmodemppage_slice.data === null) {
        calladmmodemp_get_api()
      }
       }
  },[loginpage_slice.data['access']])


  

  const [imgisLoading, setImgisLoading] = useState(true);
  const [imgval, setImgval] = useState("");
  useEffect(() => {
    if (isdtsheetopen === true) {
      setIsdtsheetopen(q=>!q)
    }
    if (isleavessheetopen === true) {
      setIsleavessheetopen(q=>!q)
    }
    if (admmodemppage_slice.data !== null) {
      console.log(admmodemppage_slice.data.photo === null,"myyyy")
      if (admmodemppage_slice.data.photo === null) {
        if (imgisLoading === true) {
          setImgisLoading(q=>!q)
        }
      }
      if (imgval !== admmodemppage_slice.data.photo) {
        setImgval(admmodemppage_slice.data.photo)
        if (imgisLoading === false && admmodemppage_slice.data.photo !== null) {
          setImgisLoading(q=>!q)
        }
      }
    }
    
  },[admmodemppage_slice.data])
 
  






  const calladmmodemp_get_api = ()=> {
    dispatch(calladmmodemp_service([`${loginpage_slice.data['access']}`,empid === '' ? loginpage_slice.data.mysno : empid]))


  
  }


  const calladminpanel_get_api = ()=> {
    dispatch(calladminpanel_service(`${loginpage_slice.data['access']}`))
  
  }


























    const [isleavessheetopen,setIsleavessheetopen] = useState(false);
  
  const [empid,setEmpid] = useState('');

  const handleImageChange = ()=> {}
  const handleidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setEmpid("")
    } else {
      setEmpid(event.target.value)
    }
    
  }





  
  return (
    <div className='modifyempmodempouter'>
      {(admmodemppage_slice.loading || loginpage_slice.loading || adminpanelpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
      <div className='modifyempmodemp'>
        
        {admmodemppage_slice.data !== null && <Dtsheet isdtsheetopen={isdtsheetopen} setIsdtsheetopen={setIsdtsheetopen} admmodemppage_slice={admmodemppage_slice} />}
        {admmodemppage_slice.data !== null &&<Leavesheet isleavessheetopen={isleavessheetopen} setIsleavessheetopen={setIsleavessheetopen} admmodemppage_slice={admmodemppage_slice}/>}
        <h3 className='headmodemp'>Modify Employee Details</h3>
        <div className="searchempmodifyempmodemp">
            <input disabled={admmodemppage_slice.loading} onKeyDown={(event)=> {
            if (event.key === "Enter") {
              if (empid !== "") {
                calladmmodemp_get_api()
              }
            }
          }} value={empid} onChange={handleidchange} max={6} type="number" onWheel={(e) => e.target.blur()} placeholder='Employee ID' />
            <button onClick={()=> {calladmmodemp_get_api()}} disabled={empid === '' ? true : false || admmodemppage_slice.loading} className='srchbtn'>Search</button>
        </div>

        {admmodemppage_slice.data === null ? (admmodemppage_slice.loading === false && <div className='nonefoundadmmod'>
          <h3>No employee found!</h3>
        </div>) : <div className="userinfodetailsmodemp">
      
      <div className="userinfoeditmodemp">
      <div className="imgholderuserinfomodemp">
      {imgisLoading && <div className='loadimg'><Loader_rotatelines/></div>}
        
      <img className={`userimguserinfomodemp ${admmodemppage_slice.data.sex}`} src={admmodemppage_slice.data.photo} alt=""  onLoad={()=>{if (imgisLoading === true && admmodemppage_slice.data.photo !== null) {
        setImgisLoading(q=>!q)
      }}} onError={()=>{if (imgisLoading === true && admmodemppage_slice.data.photo !== null) {
        setImgisLoading(q=>!q)
      }}}/>
      </div>
      {/* <button className='usereditbuttonmodemp' onClick={handleImageChange()}>Edit Image</button> */}
      </div>
      
      <hr />
      
      <div className="editandleavesdiv">
      <div className='leaveinfocardmodemp'>
                <p className='remainleaveheadmodemp'>Remaining leaves :</p>
                <p><span className='userinfogridtitlemodemp'>Casual:</span> {admmodemppage_slice.data.casualleave} days</p>
                <p><span className='userinfogridtitlemodemp'>Sick:</span> {admmodemppage_slice.data.sickleave} days</p>
                <p><span className='userinfogridtitlemodemp'>Earned:</span> {admmodemppage_slice.data.earnedleave} days</p>
                <p><span className='userinfogridtitlemodemp'>Maternal/Paternal:</span> {admmodemppage_slice.data.maternalleave} days</p>

              </div>
              <div className="infoeditbuttonsmodemp">
                <button disabled={admmodemppage_slice.loading} className='usereditbuttonmodemp' onClick={()=>setIsleavessheetopen(q=>!q)}>Edit leaves</button>
                <button disabled={admmodemppage_slice.loading} className='usereditbuttonmodemp' onClick={()=>setIsdtsheetopen(q=>!q)}>Edit details</button>
                
                
              </div>
              
      </div>
              <div className="userinfogridmodemp">
                <p><span className='userinfogridtitlemodemp'>ID:</span> {admmodemppage_slice.data.areg_sno}</p>
                <p><span className='userinfogridtitlemodemp'>Name:</span> {admmodemppage_slice.data.ename}</p>
                <p><span className='userinfogridtitlemodemp'>Department:</span> {admmodemppage_slice.data.dept}</p>
                <p><span className='userinfogridtitlemodemp'>Designation:</span> {admmodemppage_slice.data.designation}</p>
                
                <p><span className='userinfogridtitlemodemp'>Designation sequence:</span> {admmodemppage_slice.data.desig_seq}</p>
                <p><span className='userinfogridtitlemodemp'>Qualification:</span> {admmodemppage_slice.data.qualification}</p>
                <p><span className='userinfogridtitlemodemp'>Phone:</span> {admmodemppage_slice.data.phone}</p>
                <p><span className='userinfogridtitlemodemp'>E-mail:</span> {admmodemppage_slice.data.email}</p>
                <p><span className='userinfogridtitlemodemp'>Blood group:</span> {admmodemppage_slice.data.bloodgroup}</p>
                <p><span className='userinfogridtitlemodemp'>Father's name:</span> {admmodemppage_slice.data.fhgname}</p>
                <p><span className='userinfogridtitlemodemp'>Address:</span> {admmodemppage_slice.data.addr1}</p>
                <p><span className='userinfogridtitlemodemp'>Gender:</span> {admmodemppage_slice.data.sex === "M" ? "Male" : admmodemppage_slice.data.sex === 'F' ? "Female" : "O"}</p>
                {admmodemppage_slice.data.teamleadername !== "" && <p><span className='userinfogridtitle'>Teamleader:</span> {admmodemppage_slice.data.teamleadername}</p>}
                <p style={{color:admmodemppage_slice.data.empstatus === 'W' ? "green" : "red"}}><span className='userinfogridtitlemodemp' style={{color:"black"}}>Employee status:</span> {admmodemppage_slice.data.empstatus === "W" ? "Active" : "Inactive"}</p>
                {admmodemppage_slice.data.empstatus === "L" && <p ><span className='userinfogridtitle'>Left reason:</span> {admmodemppage_slice.data.reason}</p>}
                {admmodemppage_slice.data.empstatus === "L" && <p><span className='userinfogridtitle'>Left date:</span> {getdateformatvalue(admmodemppage_slice.data.dol)}</p>}
                <p><span className='userinfogridtitlemodemp'>Date of joining:</span> {getdateformatvalue(admmodemppage_slice.data.doj)}</p>
                <p><span className='userinfogridtitlemodemp'>Date of birth:</span> {getdateformatvalue(admmodemppage_slice.data.dob)}</p>
              </div>

          
          
    </div>}
    </div>
    </div>
  )
}






export default Modifyemp





