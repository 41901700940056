import React from 'react'

class Printmonthsum extends React.Component {
  render() {
    return (
        <div className='printdetails'>
            <div className='printheaddiv'>
              <p className='printheads'>SL NO</p>
              <p className='printheads'>Name</p>
              <p className='printheads'>Designation</p>
              <p className='printheads'>Late Date</p>
              <p className='printheads'>Late Time</p>
              <p className='printheads'>Absent Date</p>
              <p className='printheads'>Leave Date</p>
              <p className='printheads'>Remarks</p>
            </div>
            {this.props.admempmonthsumpage_slice.printdata.map((q,i) => (
              <div className='printdatadiv' key={i}>
                <p className='printdata'>{i + 1}</p>
                <p className='printdata'>{q.username}</p>
                <p className='printdata'>{q.desig}</p>
                <p className='printdata'>{q.latedays.replaceAll(",",", ")}</p>
                <p className='printdata'>{q.latetime.replaceAll(",",", ")}</p>
                <p className='printdata'>{q.absentdays.replaceAll(",",", ")}</p>
                <p className='printdata'>{q.leavedays.replaceAll(",",", ")}</p>
                <p className='printdata'></p>
              </div>
            ))}
          </div>
      )
  }
}

export default Printmonthsum