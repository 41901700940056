import React, { useEffect, useState } from 'react'

import "./Leaveapplication.css"
import { FaCalendarAlt } from "react-icons/fa";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";// theme css file
import { format } from "date-fns";
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../Redux/Slice/Loginslice';
import { calllvapp_service, calllvapppost_service, handlesetstatuserrorlvapp } from '../../Redux/Slice/Leaveapplicationslice';
import { Loader_rotatelines } from '../../App';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@mui/material';

import swal from 'sweetalert';

const Leaveapplication = () => {
  const loginpage_slice = useSelector((state) => state.login);
  
  

  const lvapppage_slice = useSelector((state) => state.lvapp);
  const dispatch = useDispatch();




  const [openDate, setOpendate] = useState(false);
  const [submitpressed, setSubmitpressed] = useState(false);
  const today = new Date()
  const [lvreason,setLvreason] = useState('')
  const [lvaddr,setLvaddr] = useState('')
  useEffect(() => {
    dispatch(handlesetstatuserrorlvapp())
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (lvapppage_slice.data !== null) {
        calllvapp_api()
      }
      //
    }
    
  },[])
  const navigate = useNavigate()
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (lvapppage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (lvapppage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }else if ((lvapppage_slice.status !== '200' && lvapppage_slice.status !=="") || lvapppage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserrorlvapp())
      
      navigate("/error")
    }

  },[lvapppage_slice.error,lvapppage_slice.status])

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && lvapppage_slice.data === null) {
      calllvapp_api() }
  },[loginpage_slice.data['access']])


  const calllvapp_api = ()=> {
    dispatch(calllvapp_service(`${loginpage_slice.data['access']}`))
    console.log(lvapppage_slice.status)
    
    
    
   
    
  }

  const calllvpost_api = ()=> {
    setLvpostmsg('')
    if (submitpressed === false) {
      setSubmitpressed(q=>!q);
    }
    var lvtype = "0";
    if (lvtypeselected === "Casual") {
      lvtype = "0"
    } else if (lvtypeselected === "Sick") {
      lvtype = "2"
    } else if (lvtypeselected === "Earned") {
      lvtype = "1"
    } else if (lvtypeselected === "Maternal/Paternal") {
      lvtype = "3"
    }
    dispatch(calllvapppost_service([`${loginpage_slice.data['access']}`,{ltype:lvtype,fdate:`${format(date[0].startDate, "yyyy-MM-dd")} 00:00:00`,tdate:`${format(date[0].endDate, "yyyy-MM-dd")} 00:00:00`,reason:lvreason,leave_adate:`${format(new Date(), "yyyy-MM-dd")} 00:00:00`,addrleave:lvaddr}]))
  }
  const [lvpostmsg,setLvpostmsg] = useState('')


  useEffect(()=> {
    if (lvapppage_slice.lvpostresponsebody != '' && submitpressed === true) {
      
      setLvpostmsg(lvapppage_slice.lvpostresponsebody)
      console.log("sss")
      setSubmitpressed(q=>!q)
      
      

    }
  },[lvapppage_slice.lvpostresponsebody])
  useEffect(()=> {
    console.log(lvpostmsg)
    if (lvpostmsg !== '') {
      
      if (lvpostmsg === "No Casual Left") {
        swal("You have no casual leave balance!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (lvpostmsg === "No Sick Left") {
        swal("You have no sick leave balance!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (lvpostmsg === "No Maternal Left") {
        swal("You have no maternal/paternal leave balance!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (lvpostmsg === "No Earned Left") {
        swal("You have no earned leave balance!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (lvpostmsg === "leave exists") {
        swal("Your leave on this date is already either pending or accepted.Please check your leave status list!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (lvpostmsg === "attendance exists") {
        swal("Your attendance on this date already exists!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (lvpostmsg === "canttakeleaveonthisday") {
        swal("You can not take leave on your applied date!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (lvpostmsg === "true") {
        
        swal("Your leave application has been submitted!", {
          buttons: false,
          icon:"success",
          title:"Success!"
        });
      }
    }}
  ,[lvpostmsg])


























  const [lvtypeselected,setLvtypeselected] = useState("Casual");
  
  const newdeptselected = (event)=> {
    setLvtypeselected(event.target.value);
  }

  
  
  const handlelvreasonchange = (event) => {
    setLvreason(event.target.value)
  }
  const handlelvaddrchange = (event) => {
    setLvaddr(event.target.value)
  }
  const [date, setDate] = useState([
    {
      startDate: new Date(today.setDate(today.getDate())),
      endDate: new Date(today.setDate(today.getDate())),
      key: "selection",
    },
  ]);
  const handledaterangechange = (item)=> {
    setDate([item.selection]);
  }
  const handleopendate = ()=> {
    setOpendate((x) => !x)
  }
  
  return (
    <div className='lvappouter'>
      
      
      
      {(lvapppage_slice.loading || loginpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
      {lvapppage_slice.data !== null && <div className='lvapp'>
      <h3 style={{textAlign:"center"}}>Leave application</h3>
      <div className="leftlvdayscontainer">
        <h3>Leaves remaining:</h3>
        <p>Casual leave: {lvapppage_slice.data.clbal} days</p>
        <p>Sick leave: {lvapppage_slice.data.slbal} days</p>
        <p>Earned leave: {lvapppage_slice.data.elbal} days</p>
        <p>Maternal/Paternal leave: {lvapppage_slice.data.mlbal} days</p>
      </div>
      <div className="lvformcontainer">
      <div className="lvformdetelem">
          <span>Leave type: </span>
          <select className="" value={lvtypeselected} onChange={newdeptselected}>
                  <option >Casual</option>
                  <option > Sick</option>
                  <option > Earned</option>
                  <option > Maternal/Paternal</option>
              </select>
        </div>
        <div className="lvformdetelem">
            <span>Leave days: </span>
            <p
              onClick={() => handleopendate()}
              className={`datetext ${openDate ? "active" : ""}`}
            ><span><FaCalendarAlt /></span> {`${format(date[0].startDate, "MM/dd/yyyy")}`} -  {`${format(
              date[0].endDate,
              "MM/dd/yyyy"
            )}`}</p>
            {openDate && (
              <div className='date'>
                <button onClick={handleopendate} className={`selectdateinsidebtn`}>Select dates</button>
                <DateRange
              
              rangeColors={["#3527d6"]}
                editableDateInputs={true}
                
                
                onChange={(item) => {handledaterangechange(item)}}
                moveRangeOnFirstSelection={false}
                ranges={date}
                minDate={new Date(today.setDate(today.getDate() - 30))}
                maxDate={new Date(today.setDate(today.getDate() + 60))}
                className="datepicker"
              />
              
              </div>
            )}
          </div>
          <div className="lvformdetelem">
          <span>Leave reason: </span>
          <input value={lvreason} onChange={handlelvreasonchange} maxLength={100} type="text" placeholder='reason' />
        </div>
        
          <div className="lvformdetelem">
          <span>Address during leave: </span>
          <input value={lvaddr} onChange={handlelvaddrchange} maxLength={60} type="text" placeholder='e.g., Dhaka,Khulna,Rajshahi'></input>
        </div>
        <button disabled={(lvreason === '' || lvaddr === '') || lvapppage_slice.loading} onClick={()=>{calllvpost_api()}} className='submitlvself'>Submit</button>
      </div>
      
    </div>}
    </div>
  )
}

export default Leaveapplication