



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";
import axios from "axios";

export const calladmnewemp_service = createAsyncThunk("calladmnewemp", async (data) => {
    console.log(data)
    
    const response = await fetch(`${myport}latest_sno_get/`,{headers: {
        'Authorization': `Bearer ${data}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})
export const callnewemppost_service = createAsyncThunk("callnewemppost", async (data) => {
    console.log(data[1])
    const response = await axios.post(`${myport}createemppost/`,data[1],{withCredentials:true, headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${data[0]}`,
      }});
      console.log(response.status)
      
      return [response.data,response.status];
})

export const admnewempSlice = createSlice({
    name: "admnewemp",
    initialState: {
        newid : null,
        loading: false,
        status: "",
        error: null,
        createsuccess: "",

      },
      reducers: {
        handlesetstatuserroradmnewemp(state,action) {
            state.newid = null;
            state.createsuccess = "";
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calladmnewemp_service.pending, (state)=> {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(calladmnewemp_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                state.error = null;
                
            
            state.newid = action.payload[0];
            
            console.log(state.newid);
        } else {
            state.error = null;
        }
        state.status = `${action.payload[1]}`;
            state.loading = false;
        });
        builder.addCase(calladmnewemp_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });








        builder.addCase(callnewemppost_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
            state.createsuccess = "";
            console.log("trying")
        });
        builder.addCase(callnewemppost_service.fulfilled, (state,action) => {
            
            
            console.log(action.payload[0])
                console.log(action.payload[1])
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"
                state.createsuccess = "fail";

            } else if (action.payload[1] === 200) {
                console.log("done")
                
                state.error = null;
                
            
            
            
            console.log(state.newid);
            state.createsuccess = "done";
        } else {
           

            state.error = null;
            state.createsuccess = "fail";
        }
        state.status = `${action.payload[1]}`;
            state.loading = false;
        });
        builder.addCase(callnewemppost_service.rejected, (state,action) => {
            state.loading = false;
            console.log("failed")
            state.error = action.error.message;
            state.createsuccess = "fail";
        });
      },
    
});

export const { handlesetstatuserroradmnewemp } = admnewempSlice.actions
export default admnewempSlice.reducer