






import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";


export const calladmupdatesingleatnget_service = createAsyncThunk("admupdatesingleatnget", async (data) => {
    console.log("Ssdgds")
    
    
    const response = await fetch(`${myport}daywisesingleempattendance_get/${data[1]}/${data[2]}/`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)

      
      return [jsondata,response.status];
})

export const calladmdaywiseatn_service = createAsyncThunk("calladmdaywiseatn", async (data) => {
    console.log(data[3])
    
    const response = await fetch(data[3] !== "null" ? data[3] : `${myport}daywiseallempattendance_get/${data[1]}/${data[2]}/`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[3]];
})


export const callupdate_atn_service = createAsyncThunk("callupdateatn", async (data) => {
    console.log(`${myport}userlogin_get/`)
    const response = await fetch(`${myport}daywiseattendanceupdateadmin/${data[2]}/${data[3]}/`,{method:'PUT',body: JSON.stringify(data[1]),headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include"
      ,});
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status,data[1]];
})

export const admdaywiseatnSlice = createSlice({
    name: "admdaywiseatn",
    initialState: {
        count:0,
        next: "",
        loading: false,
        status: "",
        updatestatus: "",
        error: null,
        updateerror: null,
        resultsdata:null,
        updateresultsdata:null,
        updategetloading:false,
        admupdateatnpostresponsebody: ''

      },
      reducers: {
        handlesetstatuserroradmdaywise(state,action) {
            state.resultsdata = null;
            state.updateresultsdata = null;
            state.updategetloading = false;
            state.loading = false;
            state.next = "";
            state.count = 0;
            state.admupdateatnpostresponsebody = '';
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.updateerror = null;
            state.status = ""
            state.updatestatus = ""
          },
        handlesetstatuserroradmdaywisesingle(state,action) {
            state.updateresultsdata = null;
            state.updategetloading = false;
            state.admupdateatnpostresponsebody = '';
            state.updateerror = null;
            state.updatestatus = ""
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calladmdaywiseatn_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
            
        });
        builder.addCase(calladmdaywiseatn_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"
    
            } else if (action.payload[1] === 200) {
                state.error = null;
            
            console.log(action.payload[2])
            
            console.log(action.payload[0].next)
            
            state.next = action.payload[0].next === null ? "null" : action.payload[0].next;
            state.count = action.payload[0].count;
            if (action.payload[2] === "null") {
                state.resultsdata = action.payload[0].results;
           
            } else {
                console.log(state.resultsdata.length)
                state.resultsdata = [...state.resultsdata, ...action.payload[0].results]
                console.log(state.resultsdata.length)
           
            }
        } else {
            state.error = null;
        }
             console.log("ok123")
            state.status = `${action.payload[1]}`;
            console.log(state.next);
            state.loading = false;
        });
        builder.addCase(calladmdaywiseatn_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });





        builder.addCase(calladmupdatesingleatnget_service.pending, (state)=> {
            state.updategetloading = true;
            state.updateresultsdata = null;
            state.updateerror = null;
        });
        builder.addCase(calladmupdatesingleatnget_service.fulfilled, (state,action) => {
            console.log("Ssdgds")
            if (action.payload[0] === "alreadyloggedout") {
                state.updateerror = "alreadyloggedout"
    
            } else if (action.payload[0] === "noemp") {
                state.updateerror = "noemp"
                console.log("ss")
                state.updateresultsdata = null;
      
            } else if (action.payload[0] === "noatn") {
                state.updateerror = "noatn"
                console.log("ss")
                state.updateresultsdata = null;
      
            } else if (action.payload[1] === 200) {
                console.log(action.payload[0])
                state.updateerror = null;
            
            
            state.updateresultsdata = action.payload[0].results[0];
            console.log(state.updateresultsdata["ename"])
            
        }else {
            state.updateerror = null;
        }
        state.updatestatus = `${action.payload[1]}`;
        state.updategetloading = false;
           
        });
        builder.addCase(calladmupdatesingleatnget_service.rejected, (state,action) => {
            state.updategetloading = false;
            state.updateerror = action.error.message;
        });










        builder.addCase(callupdate_atn_service.pending, (state)=> {
            state.admupdateatnpostresponsebody = '';
            state.updategetloading = true;
            state.updateerror = null;
        });
        builder.addCase(callupdate_atn_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.updateerror = "alreadyloggedout"

            } else if (action.payload[0] === "noemp") {
                state.updateerror = "noemp"
                console.log("ss")
                state.updateresultsdata = null;
      
            } else if (action.payload[0] === "noatn") {
                state.updateerror = "noatn"
                console.log("ss")
                state.updateresultsdata = null;
      
            } else if (action.payload[1] === 200) {
                console.log(action.payload[2]["time_in"])
                    state.updateresultsdata["time_in"] = action.payload[2]["time_in"]
                
                console.log(action.payload[2]["time_out"])
                    state.updateresultsdata["time_out"] = action.payload[2]["time_out"]
                
                console.log(action.payload[2]["late"])
                    state.updateresultsdata["late"] = action.payload[2]["late"]
                
               console.log(action.payload[2]["status"])
                    state.updateresultsdata["status"] = action.payload[2]["status"]
                    
                
            
           
            state.updateerror = null;

           } else {
            state.updateerror = null;

           }
           state.admupdateatnpostresponsebody = `${action.payload[0]}`
            
            
            state.updatestatus = `${action.payload[1]}`;
            state.updategetloading = false;
            console.log(state.data);
        });
        builder.addCase(callupdate_atn_service.rejected, (state,action) => {
            state.updategetloading = false;
            state.updateerror = action.error.message;
        });
      },
    
});

export const { handlesetstatuserroradmdaywise,handlesetstatuserroradmdaywisesingle } = admdaywiseatnSlice.actions
export default admdaywiseatnSlice.reducer