import React, { useEffect, useState } from 'react'
import "./Emptl.css"
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { calladminpanel_service } from '../../../Redux/Slice/Adminpanelslices/Adminpanelslice';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../../Redux/Slice/Loginslice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loader_rotatelines } from '../../../App';
import { calladmemptl_service, callupdate_emptl_service, handlesetstatuserroradmemptl, handlesetstatuserroradmemptlupdate } from '../../../Redux/Slice/Adminpanelslices/Admemptlslice';
import swal from "sweetalert";

const Emptl = () => {
  const navigate = useNavigate()

  const loginpage_slice = useSelector((state) => state.login);
  const adminpanelpage_slice = useSelector((state) => state.adminpanel);
  const [resized, setResized] = useState(false);
  
  

  const admemptlpage_slice = useSelector((state) => state.admemptl);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserroradmemptl());
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (admemptlpage_slice.resultsdata !== null) {
        calladmemptl_get_api("null")

      }
      //
    }

    const resizefunc = () => {
      console.log(resized);
      if (window.innerHeight >= document.documentElement.scrollHeight) {
        if (resized === false) {
          setResized((prev) => !prev);
        }
      }
    };
    const scrollfunc = () => {
      console.log(resized);

      if (
        window.innerHeight + document.documentElement.scrollTop + 200 >=
        document.documentElement.scrollHeight
      ) {
        if (resized === false) {
          setResized((prev) => !prev);
          console.log("oks");
        } else {
          console.log("ss");
        }
      }
    };
    window.addEventListener("resize", resizefunc);
    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("resize", resizefunc);
      window.removeEventListener("scroll", scrollfunc);
    };
  },[])
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  const [err,setErr] = useState(false)
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (admemptlpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (admemptlpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }   else if (admemptlpage_slice.error === "noemp" || admemptlpage_slice.error === "notl" || admemptlpage_slice.error === "noemportl") {
      console.log("ss")
      setErr(admemptlpage_slice.error)
      dispatch(handlesetstatuserroradmemptl());
      

    }  else if ((admemptlpage_slice.status !== '200' && admemptlpage_slice.status !=="") || admemptlpage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserroradmemptl());
      
      navigate("/error")
    }

  },[admemptlpage_slice.error,admemptlpage_slice.status])
  const [upderr,setUpderr] = useState(false)
  useEffect(()=> {
    
    if (admemptlpage_slice.updateerror === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (admemptlpage_slice.updatestatus==="401") {
      
      dispatch(handlenoactcookie())
    }   else if (admemptlpage_slice.updateerror === "noemp" || admemptlpage_slice.updateerror === "notl" || admemptlpage_slice.updateerror === "noemportl") {
      console.log("ss")
      setErr(admemptlpage_slice.updateerror)
      dispatch(handlesetstatuserroradmemptlupdate());
      

    }  else if ((admemptlpage_slice.updatestatus !== '200' && admemptlpage_slice.updatestatus !=="") || admemptlpage_slice.updateerror !== null) {
      console.log("error")
      dispatch(handlesetstatuserroradmemptlupdate());
      
      navigate("/error")
    }

  },[admemptlpage_slice.updateerror,admemptlpage_slice.updatestatus])


  useEffect(() => {
    console.log("resss");

    if (resized === true) {
      console.log("res");
      moreload();
    }
  }, [resized]);
  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && admemptlpage_slice.resultsdata === null) {
      calladmemptl_get_api("null") }
  },[loginpage_slice.data['access']])


  

  useEffect(() => {
    if (
      admemptlpage_slice.resultsdata !== null &&
      (window.innerHeight >= document.documentElement.scrollHeight ||
        window.innerHeight >= document.documentElement.scrollHeight)
    ) {
      moreload();
    } else {
      setResized(false);
    }
  }, [admemptlpage_slice.resultsdata]);
  const [updatepressed,setUpdatepressed] = useState(false)
  const [tlpostmsg,setTlpostmsg] = useState('')


  useEffect(()=> {
    if (admemptlpage_slice.admupdateemptlpostresponsebody != '' && updatepressed === true) {
      
      setTlpostmsg(admemptlpage_slice.admupdateemptlpostresponsebody)
      console.log("sss")
      setUpdatepressed(q=>!q)
      
      

    }
  },[admemptlpage_slice.admupdateemptlpostresponsebody])
  useEffect(()=> {
    console.log(tlpostmsg)
    if (tlpostmsg !== '') {
      
      if (tlpostmsg === "done") {
        
        swal("Teamleader updated!", {
          buttons: false,
          icon:"success",
          title:"Success!"
        });
      } else  {
          swal("Teamleader update failed!", {
            buttons: false,
            icon:"error",
            title:"Failed!"
          });
        
      }
    }}
  ,[tlpostmsg])





  const calladmemptl_get_api = (nextlinkpass)=> {
    if (loginpage_slice.data['access'] !== '') {
    dispatch(calladmemptl_service([`${loginpage_slice.data['access']}`,empid === "" ? "null" : empid,tlid === "" ? "null" : tlid,nextlinkpass === "null" ? "null" : admemptlpage_slice.next,]))
  }
  
  }
  const calladminpanel_get_api = ()=> {
    dispatch(calladminpanel_service(`${loginpage_slice.data['access']}`))
  
  }



  const moreload = () => {
    console.log(admemptlpage_slice.next);

    if (
      admemptlpage_slice.loading === false &&
      admemptlpage_slice.resultsdata.length < admemptlpage_slice.count
    ) {
      console.log("1234567");

      calladmemptl_get_api("notnull");

      console.log(document.documentElement.scrollHeight);
      console.log(window.innerHeight);
    } else {
      setResized(false);
      console.log("dd");
    }
  };




















  const [showaccorupdate,setShowaccorupdate] = useState(false);
  const [empid,setEmpid] = useState('');
  const [empidupdate,setEmpidupdate] = useState('');
  const [tlid,setTlid] = useState('');
  const [tlidupdate,setTlidupdate] = useState('');


  const handleidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setEmpid("")
    } else {
      setEmpid(event.target.value)
    }
    
  }
  const handletlidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setTlid("")
    } else {
      setTlid(event.target.value)
    }
    
  }
  const handleempupdateidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setEmpidupdate("")
    } else {
      setEmpidupdate(event.target.value.trim())
    }
    
  }
  const handletlupdateidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setTlidupdate("")
    } else {
      
      setTlidupdate(event.target.value.trim())
    }
    
  }
  const handlenewcall = ()=> {
    calladmemptl_get_api("null")
  }
  const handleupdateemptl_put_api = ()=> {
    if (updatepressed === false) {
      setUpdatepressed(q=>!q)
    }
    setTlpostmsg("")
    dispatch(callupdate_emptl_service([
      `${loginpage_slice.data["access"]}`,
      empidupdate,
      tlidupdate
    ]))
  }

  return (
    <div>
      {(admemptlpage_slice.loading || admemptlpage_slice.updateloading || loginpage_slice.loading || adminpanelpage_slice.loading) && <div className="loader_login"><Loader_rotatelines /></div>}
      <div className='emptl'>
      <div className="updatetldiv">
        <button onClick={()=>setShowaccorupdate(q=>!q)} className='updatetogglebtn'> {showaccorupdate ? <IoIosArrowDropupCircle size={20}/> : <IoIosArrowDropdownCircle size={20}/>} Update teamleader</button>
        {showaccorupdate && <div className="updatetlaccor">
        <div className="tlaccorelementinput">
          <span>Emplyee ID <span id='asteriskspan'>*</span></span>
          <input  value={empidupdate} onChange={handleempupdateidchange} type="number" onWheel={(e) => e.target.blur()}
                   placeholder='Employee ID' autoFocus={showaccorupdate} />
        </div>
        <div className="tlaccorelementinput">
          <span>New Teamleader ID <span id='asteriskspan'>*</span></span>
          <input value={tlidupdate} onChange={handletlupdateidchange} type="number" onWheel={(e) => e.target.blur()}  placeholder='Teamleader ID' />
        </div>
        <button disabled={((empidupdate !== '' && tlidupdate !== '' && empidupdate !== tlidupdate) ? false : true) || admemptlpage_slice.loading} onClick={()=>handleupdateemptl_put_api()} className='tlempupdatebtn'>Update</button>
      
      </div>
      }

      
      </div>
      <div className='tlcheckdiv'>
        <h3 style={{textAlign:"center"}}>Employees & Teamleaders</h3>
        <div className="searchemptl">
        <div className="searchemptlelementinput">
          <span>Emplyee ID</span>
          <input disabled={(admemptlpage_slice.loading) ? true : false} value={empid} onChange={handleidchange} type="number" onWheel={(e) => e.target.blur()} onKeyDown={(event)=> {
                    if (event.key === "Enter") {
                      handlenewcall()
                    }
                  }} placeholder='Employee ID' autoFocus/>
        </div>
        <div className="searchemptlelementinput">
          <span>Teamleader ID</span>
          <input disabled={(admemptlpage_slice.loading) ? true : false} value={tlid} onChange={handletlidchange} onKeyDown={(event)=> {
                    if (event.key === "Enter") {
                      handlenewcall()
                    }
                  }} type="number" onWheel={(e) => e.target.blur()} placeholder='Teamleader ID' />
        </div>
        <button disabled={(admemptlpage_slice.loading) ? true : false} onClick={()=> {handlenewcall()}} className='tlempsearbtn'>Search</button>

      </div>
      </div>
      {admemptlpage_slice.resultsdata === null ? err && (<div className='nonefoundadmtl'>
          <h3>{err === "noemp" ? "No employee found!" : err === "notl" ? "No TL found!" : "No employee or TL found!"}</h3>
        </div>) : <div>
        <div className="emptlhead">
                <p className='emptlheaditem'>Name</p>
                <p className='emptlheaditem'>ID</p>
                <p className='emptlheaditem'>Department</p>
                <p className='emptlheaditem'>TL name</p>
                <p className='emptlheaditem'>TL ID</p>
            </div>
           {admemptlpage_slice.resultsdata.map((q,i) => (
              <div key={i} className="emptldetails" >
              <p className='emptldetailsitem'><span>{i + 1}.  </span> {q.ename}</p>
              <p className='emptldetailsitem'>{q.areg_sno}</p>
              <p className='emptldetailsitem'>{q.dept}</p>
              <p className='emptldetailsitem'>{q.teamleadername}</p>
              <p className='emptldetailsitem'>{q.teamleadersno}</p>
          </div>
          ))}
        </div>}
      
            
  
      
      
    </div>
    </div>
  )
}

export default Emptl