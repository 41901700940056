



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../App";

export const callreftoken_service = createAsyncThunk("callreftoken", async (data) => {
    
    const response = await fetch(`${myport}reftoken/`,{credentials:"include"} );
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status];
})

export const reftokenSlice = createSlice({
    name: "reftoken",
    initialState: {
        data: {"access":""},
        loading: false,
        status: "",
        error: null,

      },
      reducers: {


      },
      
      extraReducers: (builder) => {
        builder.addCase(callreftoken_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callreftoken_service.fulfilled, (state,action) => {
            state.loading = false;
            
            state.data["access"] = action.payload[0].access;
            state.status = `${action.payload[1]}`;
            console.log(action.payload[0].access)

            console.log(state.data);
        });
        builder.addCase(callreftoken_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});


export default reftokenSlice.reducer