import React, { useEffect } from 'react'
import "./Calendar.css"

import Datepicker from "react-datepicker"
import format from 'date-fns/format';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';
import { FaCalendar } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../../Redux/Slice/Loginslice';
import { admcalendarSlice, calladmcalendar_service, calladmcalendareupdate_service, handlesetstatuserroradmcalen } from '../../../Redux/Slice/Adminpanelslices/Admcalendarslice';
import { calladminpanel_service, handlesetstatuserroradmpanel } from '../../../Redux/Slice/Adminpanelslices/Adminpanelslice';
import { Loader_rotatelines, getdateformatvalue } from '../../../App';
import swal from 'sweetalert';
const Calendar = () => {
  const navigate = useNavigate();
  const loginpage_slice = useSelector((state) => state.login);
  const adminpanelpage_slice = useSelector((state) => state.adminpanel);
  
  

  const admcalendarpage_slice = useSelector((state) => state.admcalendar);
  //const [initialeffect,setInitialeffect] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserroradmcalen())

    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (admcalendarpage_slice.data !== null) {
        calladmcalendar_get_api()

      }
      
    }
    //setInitialeffect(q=>!q)
  },[])
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (admcalendarpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (admcalendarpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    } else if ((admcalendarpage_slice.status !== '200' && admcalendarpage_slice.status !=="") || admcalendarpage_slice.error !== null) {
      console.log(admcalendarpage_slice.status,admcalendarpage_slice.error)
      dispatch(handlesetstatuserroradmcalen());
      
      navigate("/error")
    }

  },[admcalendarpage_slice.error,admcalendarpage_slice.status])


  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && admcalendarpage_slice.data === null) {
      console.log("s")
      calladmcalendar_get_api() }
  },[loginpage_slice.data['access']])


  

 


  /* useEffect(()=> {
    console.log("tabpressed")
    if (tabpressed === true) {
      swal("Calendar day updated!", {
        buttons: false,
        icon:"success",
        title:"Success!"
      });
      setTabpressed(q=>!q)
    }
  },[admcalendarpage_slice.data]) */






  const calladmcalendar_get_api = ()=> {
    if (admcalendarpage_slice.loading ===false) {
      dispatch(calladmcalendar_service([`${loginpage_slice.data['access']}`,selectedMonth.getMonth() + 1,selectedMonth.getFullYear()]))

    }

  
  }


  /* const calladminpanel_get_api = ()=> {
    if (adminpanelpage_slice.loading === false) {
      dispatch(calladminpanel_service(`${loginpage_slice.data['access']}`))
  
    }
  } */



































  const [data,setData] = useState([
    {month:"1",worh:"holiday"},
    {month:"1",worh:"workday"},
    {month:"1",worh:"workday"},
    {month:"1",worh:"workday"},
    {month:"1",worh:"workday"},
    {month:"1",worh:"workday"},
    {month:"1",worh:"holiday"},
  ])
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [monthchanged,setMonthchanged] = useState(false);
  const [tabwork, setTabwork] = useState("workday");
  const [tabpressed,setTabpressed] = useState(false);
  const handletabclick = (status,date)=> {
    if (tabpressed === false) {
      setTabpressed(q=>!q)
    }
    var data = {worh : status}

    callworhupdate_put_api(data,date)
    
  }

  const callworhupdate_put_api = (data,date)=> {
    dispatch(calladmcalendareupdate_service([loginpage_slice.data['access'],data,date]))
  }
   const getNextMonthLastDate = ()=> {
    
    const currentDate = new Date();
    const nextMonth = new Date(currentDate.getMonth === 11 ? currentDate.getFullYear() + 1 : currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
    console.log(currentDate.getMonth() === 0) // Set to the 0th day (last day) of the following month
    return nextMonth;
  }
  const handlemonthchange = (date)=> {
    setSelectedMonth(date);
    console.log("change")
    if (monthchanged === false) {
      setMonthchanged(q=>!q)
    }
    
  }
  useEffect(()=> {
    console.log(monthchanged)
    if (loginpage_slice.data['access'] !== '' && monthchanged === true) {
      calladmcalendar_get_api() 
      if (monthchanged === true) {
        setMonthchanged(q=>!q)
      }
    
    }
  
  },[monthchanged])
   
  return (
    
    <div className='calendarouter'>
      {(admcalendarpage_slice.loading || loginpage_slice.loading || adminpanelpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }

      <div className='calendar'>
      <h3>Monthly work/holidays</h3>
        <div className="monthpickdiv">
        <Datepicker
        disabled={admcalendarpage_slice.loading}
      selected={selectedMonth}
      onChange={date => handlemonthchange(date)}
      maxDate={getNextMonthLastDate()}
      minDate={new Date('2022-01-01T11:43:36.000Z')}
      closeOnScroll
      showMonthYearPicker
      displayFormat="MMMM YYYY"
      dateFormat= "yyyy, MMMM"
      customInput={<button className='monthpicker'><div className='monthpickerdetails'> <FaCalendar /></div> {format(selectedMonth,"MMMM - yyyy")}</button>}
    />
        </div>
    {(admcalendarpage_slice.data !== null && admcalendarpage_slice.loading === false) && <div className="calendardetails">
      
    <div className="calendarhead">
                <p className='calendarheaditem'>Date</p>
                <p className='calendarheaditem'>work/holiday</p>
                
            </div>
            {admcalendarpage_slice.data !== "404" && admcalendarpage_slice.data.map((q,i) => (
                <div key={i} className="calendarbody" >
                <p className='calendarbodyitem'>{getdateformatvalue(q.today)}</p>
                <div className='calendarbodyitem'>
                {new Date(q.today).getTime() < new Date().getTime() ? <p style={{color:q.worh === "W" ? "green" : "red"}}>{q.worh === "W" ? "Workday" : "Holiday"}</p> : <div className="calendaractiontab" key={i}>
            <button className={`tabbuttonscalendar ${q.worh === "W" ? "workday":""}`} onClick={()=>handletabclick("W",q.today)}>Workday</button>
            <button className={`tabbuttonscalendar ${q.worh === "H" ? "holiday":""}`} onClick={()=>handletabclick("H",q.today)}>Holiday</button>
          </div>}
          </div>
                
            </div>
            ))}
    </div>}
      
    </div>
    </div>
    
  )
}

export default Calendar