import React, { useEffect, useState } from 'react'
import Atndncdetailselement from '../../Elements/Atndncdetailselement/Atndncdetailselement'
import "./Todayattendance.css"
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie, handlesetloginerrorloggedout, handlesetloginstatusloggedout } from '../../Redux/Slice/Loginslice';
import { calltodayatndnc_service, handlesetloginstatuserrorloggedout, handlesetstatuserrortoday } from '../../Redux/Slice/Todayatndncslice';
import { Loader_rotatelines, getdateformatvalue } from '../../App';
import { useNavigate } from 'react-router-dom';
const Todayattendance = () => {
  const currentdate = new Date();
  const loginpage_slice = useSelector((state) => state.login);
  
  

  const todayatnpage_slice = useSelector((state) => state.todayatndnc);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(handlesetstatuserrortoday())
    
    console.log(loginpage_slice.data["loggedin"])
    
    
      if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
        console.log(1)
        dispatch(callreftoken_service());
        
      } else {
        if (todayatnpage_slice.data !== null) {
          calltodayatn_get_api()

        }
        
      }
    
  },[])
 
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    console.log(todayatnpage_slice.error)
    if (todayatnpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (todayatnpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    } else if ((todayatnpage_slice.status !== '200' && todayatnpage_slice.status !=="") || todayatnpage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserrortoday())
      
      navigate("/error")
    }

  },[todayatnpage_slice.error,todayatnpage_slice.status])
  
  

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    
    if (loginpage_slice.data['access'] !== '' && todayatnpage_slice.data === null) {
      calltodayatn_get_api() }
      
  },[loginpage_slice.data['access']])


  const calltodayatn_get_api = ()=> {
    dispatch(calltodayatndnc_service([`${loginpage_slice.data['access']}`,`${getdateformatvalue(currentdate)}`]))
  }
    
    

  return (
    <div className='todayatndnc'>

{(todayatnpage_slice.loading || loginpage_slice.loading) &&  <div className="loader_login"><Loader_rotatelines  /></div> }
{todayatnpage_slice.data !== null && <div className='todayatndncinner'>
      <h3 className='todayhead'>{getdateformatvalue(currentdate)}</h3>
      <div className='topdetailstoday'>

            <div className='userdetailstoday'>
            <div className="imgholdertoday">
                <img className={`userimgtoday ${todayatnpage_slice.data.results[0].sex}`} src={todayatnpage_slice.data.results[0].photo} alt="" />

            </div>
            <div className='userinfotoday'>
                <h3>{todayatnpage_slice.data.results[0].ename}</h3>
                <h4>ID: {todayatnpage_slice.data.results[0].areg_sno}</h4>
                <h4>Designation: {todayatnpage_slice.data.results[0].designation}</h4>
                <h4>Department: {todayatnpage_slice.data.results[0].dept}</h4>
            </div>
            </div>
            
        </div>
        <Atndncdetailselement data={todayatnpage_slice.data.results[0].attendance} />
        <hr />

    </div>}
    </div>
  )
}

export default Todayattendance