
import "./Userinfo.css"
import Sheet from 'react-modal-sheet'; 
import { useEffect, useRef, useState } from 'react';
import Dialog from "@mui/material/Dialog"
import { useDispatch, useSelector } from "react-redux";
import { callupdate_self_service, calluserinfo_service, handlesetstatuserroruserinfo, userinfoSlice } from "../../Redux/Slice/Userinfoslice";
import { Loader_rotatelines, getdateformatvalue } from "../../App";
import { callreftoken_service, callunauth_logout_service, handlenoactcookie, handlepasswordchange } from "../../Redux/Slice/Loginslice";
import { useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const Userinfo = ({login}) => {
  const loginpage_slice = useSelector((state) => state.login);
  
  
  

  const userinfopage_slice = useSelector((state) => state.userinfo);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserroruserinfo())
    
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if(userinfopage_slice.data !== null) {
        calluserinfo_get_api()
      }
      console.log("o")
      
    }
  },[])
  const navigate = useNavigate()
  
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    console.log(userinfopage_slice.error)
    if (userinfopage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (userinfopage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    } else if (userinfopage_slice.error === "oldpassnomatch")  {
      setOldpassnotmatch(true);
    } else if ((userinfopage_slice.status !== '200' && userinfopage_slice.status !=="") || userinfopage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserroruserinfo())
      
      navigate("/error")
    }

  },[userinfopage_slice.error,userinfopage_slice.status])

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && userinfopage_slice.data === null) {
      console.log("1")
      calluserinfo_get_api() }
  },[loginpage_slice.data['access']])

  useEffect(()=> {
    console.log(userinfopage_slice.data)
    if (userinfopage_slice.data !== null) {
      setMailvalue(userinfopage_slice.data["email"])
      setPhonevalue(userinfopage_slice.data["phone"])
      setAddressvalue(userinfopage_slice.data["addr1"])
    }

    if (isdtsheetopen === true) {
      setIsdtsheetopen(q=>!q)
    }
    console.log(ispwsheetopen)
    if (ispwsheetopen === true) {
      if (userinfopage_slice.data !== null) {
        if (passvalnew === userinfopage_slice.data["password"]) {
          dispatch(handlepasswordchange())
        }
      }
      
      /* setPassvalnew('')
      setPassvalold('')
      if (oldpassnotmatch === true) {
        setOldpassnotmatch(q=>!q)
      }
      if (oldpassshow === true) {
        setOldpassshow(q=>!q)
      }
      if (newpassshow === true) {
        setNewpassshow(q=>!q)
      }
      setIspwsheetopen(q=>!q) */
    }
  },[userinfopage_slice.data])


  const calluserinfo_get_api = ()=> {
    console.log("sasva")
    dispatch(calluserinfo_service(`${loginpage_slice.data['access']}`))
  
  }
























  
  const [ispwsheetopen,setIspwsheetopen] = useState(false);
  const [isdtsheetopen,setIsdtsheetopen] = useState(false);



  const [mailvalue,setMailvalue] = useState("");
  const [phonevalue,setPhonevalue] = useState("");
  const [addressvalue,setAddressvalue] = useState("");
  const handlemailchange = (event)=> {
    setMailvalue(event.target.value.trim())
  }
  const handlephonechange = (event)=> {
    setPhonevalue(event.target.value.trim())
  }
  const handleaddresschange = (event)=> {
    setAddressvalue(event.target.value)
  }
  const callupdateemp_put_api = ()=> {
    if (passvalnew !== '') {
      
      dispatch(callupdate_self_service([`${loginpage_slice.data['access']}`,{password: passvalnew,oldpass:passvalold},]));



    } else {
      
    console.log(mailvalue)
    console.log(userinfopage_slice.data["phone"] === "")
    if (mailvalue === "" || mailvalue === null ? mailvalue : mailvalue.trim() === userinfopage_slice.data["email"] && phonevalue === "" || phonevalue === null ? phonevalue : phonevalue.trim() === userinfopage_slice.data["phone"] && addressvalue === "" || addressvalue === null ? addressvalue : addressvalue.trim() === userinfopage_slice.data["addr1"]) {
      console.log(mailvalue)
    console.log(userinfopage_slice.data["email"])
      setIsdtsheetopen(q=>!q)
    } else {
      var data = {}
      if (mailvalue !== "") {
        data = {...data,...{email: mailvalue.trim()}}
      }
      
      if (phonevalue !== "") {
        data = {...data,...{phone: phonevalue.trim()}}
      }
      
      if (addressvalue !== "") {
        data = {...data,...{addr1: addressvalue.trim()}}
      }
      
      dispatch(callupdate_self_service([`${loginpage_slice.data['access']}`,data,]));

    }
    }
    
  }

const [passvalold,setPassvalold] = useState('')
const [passvalnew,setPassvalnew] = useState('')
const [oldpassnotmatch,setOldpassnotmatch] = useState(false)
const [oldpassshow,setOldpassshow] = useState(false)
const [newpassshow,setNewpassshow] = useState(false)
const handlepwupdatecall = ()=> {
  if (passvalnew.length >= 5) {
    callupdateemp_put_api()

  }

  

}
const passoldchange = (event)=> {
  if (oldpassnotmatch === true) {
    setOldpassnotmatch(q=>!q)
    
  }
  setPassvalold(event.target.value)
}
const passnewchange = (event)=> {
  setPassvalnew(event.target.value)
}

  const dtsheet = <Dialog sx={{
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "600px",  // Set your width here
      },
    },
  }} open={isdtsheetopen} onClose={()=>setIsdtsheetopen(q=>!q)}>
  
  <div className='editdetailsdivoverlay'>
  {(userinfopage_slice.loading || loginpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
  
  {userinfopage_slice.data !== null && <div className='editdetailsdiv'>
  <div className="sheethead">
    <button className='cancelbuttonsheet' onClick={()=>setIsdtsheetopen
    (q=>!q)}>Cancel</button>
    <h3>Edit details</h3>
  </div>

  
  <div className="sheetbody">
  <div className='sheetinput'>
  <input value={mailvalue} onChange={handlemailchange} maxLength={50} type="email" placeholder='abc@example.com'/>
  <span>Email</span>
  </div>
  <div className='sheetinput'>
  <input value={phonevalue} onChange={handlephonechange} maxLength={11} type="text" placeholder='01XXXXXXXXX'/>
  <span>Phone</span>
  </div>
  <div className='sheetinput'>
  <input value={addressvalue} onChange={handleaddresschange} type="text" placeholder='Mazar Road, Dhaka'/>
  <span>Address</span>
  </div>
  <button onClick={()=>callupdateemp_put_api()} disabled={mailvalue === '' && phonevalue === '' && addressvalue === ''} className="submitbtnsheet">Update</button>
</div></div>} </div>
  
</Dialog>
  const pwsheet = <Dialog sx={{
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "500px",  // Set your width here
      },
    },
  }} open={ispwsheetopen} onClose={()=>setIspwsheetopen(q=>!q)}>
   <div>
   {(userinfopage_slice.loading || loginpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
  {userinfopage_slice.data !== null && <div className='editdetailsdivoverlay'>
  
  <div className='editdetailsdiv'>
  <div className="sheethead">
    <button className='cancelbuttonsheet' onClick={()=>setIspwsheetopen
    (q=>!q)}>Cancel</button>
    <h3>Update password</h3>
  </div>

  
  <div className="sheetbody">
  <div className='sheetinput'>
  <div className="passcontainerinuserinfo">
  <input maxLength={12} value={passvalold} onChange={passoldchange} autocomplete="new-password" type={oldpassshow ? "text" : "password"} placeholder=''/>
  <button className="passwordvisibilitydiv" onClick={()=>setOldpassshow(q=>!q)}>{oldpassshow ? <MdVisibility size={18}/> : <MdVisibilityOff size={18}/>}</button>
  </div>
  <span>Old password</span>
  {oldpassnotmatch && <p className="oldpasserrorshow">Old password is not correct!</p>}
  </div>
  <div className='sheetinput'>
    <div className="passcontainerinuserinfo">
    <input maxLength={12} value={passvalnew} onChange={passnewchange} autocomplete="new-password" type={newpassshow ? "text" : "password"} placeholder=''/>
  <button className="passwordvisibilitydiv" onClick={()=>setNewpassshow(q=>!q)}>{newpassshow ? <MdVisibility size={18}/> : <MdVisibilityOff size={18}/>}</button>
          
    </div>
  
  <span>New password</span>
  </div>
  
  
  <button disabled={oldpassnotmatch === true || passvalold === '' || passvalnew === '' || passvalold.length < 5 || passvalnew.length < 5} onClick={()=>handlepwupdatecall()} className="submitbtnsheet">Update</button>
  </div>
</div> </div>}
   </div>
  
</Dialog>
  return (
    <div>
      {(userinfopage_slice.loading || loginpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
      {userinfopage_slice.data !== null && <div className='userinfo'>
      {dtsheet}
      {pwsheet}
      
      <h3 className='userinfohead'>Employee Details</h3>
      <div className="userinfodetails">
      
        <div className="userinfoedit">
        <div className="imgholderuserinfo">
        <img className={`userimguserinfo ${userinfopage_slice.data.sex}`} src={userinfopage_slice.data.photo} alt="" />
        </div>
                <div className="infoeditbuttons">
                  <button className='usereditbutton' onClick={()=>setIsdtsheetopen(q=>!q)}>Edit details</button>
                  <button className='usereditbutton' onClick={()=>{
                    setPassvalnew('')
                    setPassvalold('')
                    if (oldpassnotmatch === true) {
                      setOldpassnotmatch(q=>!q)
                    }
                    if (oldpassshow === true) {
                      setOldpassshow(q=>!q)
                    }
                    if (newpassshow === true) {
                      setNewpassshow(q=>!q)
                    }
                    setIspwsheetopen(q=>!q)
                  }}>Update password</button>
                  
                </div>
                
        </div>
        
        <hr />
        
        <div className='leaveinfocard'>
                  <p className='remainleavehead'>Remaining leaves :</p>
                  <p><span className='userinfogridtitle'>Casual:</span> {userinfopage_slice.data.casualleave} days</p>
                  <p><span className='userinfogridtitle'>Sick:</span> {userinfopage_slice.data.sickleave} days</p>
                  <p><span className='userinfogridtitle'>Earned:</span> {userinfopage_slice.data.earnedleave} days</p>
                  <p><span className='userinfogridtitle'>Maternal/Paternal:</span> {userinfopage_slice.data.maternalleave} days</p>

                </div>
                <div className="userinfogrid">
                  <p><span className='userinfogridtitle'>ID:</span> {userinfopage_slice.data.areg_sno}</p>
                  <p><span className='userinfogridtitle'>Name:</span> {userinfopage_slice.data.ename}</p>
                  <p><span className='userinfogridtitle'>Department:</span> {userinfopage_slice.data.dept}</p>
                  <p><span className='userinfogridtitle'>Designation:</span> {userinfopage_slice.data.designation}</p>
                  <p><span className='userinfogridtitle'>Qualification:</span> {userinfopage_slice.data.qualification}</p>
                  <p><span className='userinfogridtitle'>Phone:</span> {userinfopage_slice.data.phone}</p>
                  <p><span className='userinfogridtitle'>E-mail:</span> {userinfopage_slice.data.email}</p>
                  <p><span className='userinfogridtitle'>Blood group:</span> {userinfopage_slice.data.bloodgroup}</p>
                  <p><span className='userinfogridtitle'>Father's name:</span> {userinfopage_slice.data.fhgname}</p>
                  <p><span className='userinfogridtitle'>Address:</span> {userinfopage_slice.data.addr1}</p>
                  
                  <p><span className='userinfogridtitle'>Gender:</span> {userinfopage_slice.data.sex === "M" ? "Male" : userinfopage_slice.data.sex === "F" ? "Female" : "O"}</p>
                  {userinfopage_slice.data.teamleadername !== "" && <p><span className='userinfogridtitle'>Teamleader:</span> {userinfopage_slice.data.teamleadername}</p>}
                  <p><span className='userinfogridtitle'>Date of joining:</span> {getdateformatvalue(userinfopage_slice.data.doj)}</p>
                  <p><span className='userinfogridtitle'>Date of birth:</span> {getdateformatvalue(userinfopage_slice.data.dob)}</p>
                </div>

            
            
      </div>
      

    </div>}
    </div>
  )
}

export default Userinfo