import React, { useEffect, useState } from 'react'
import "./Leavedetailscontainer.css"
import { getdateformatvalue } from '../../App'
import { useDispatch } from 'react-redux'
import { callupdate_myemplv_service, changeupdatepressvaluemyemplv } from '../../Redux/Slice/Myemployeesslices/Myemplvappslice'
import swal from 'sweetalert'
import { callupdate_admlv_service, changeupdatepressvalueadmlv } from '../../Redux/Slice/Adminpanelslices/Admemplvappsslice'
const Leavedetailscontainer = ({leavestatus,i,tabrev,frompage,loginaccessvalue}) => {
  const dispatch = useDispatch();  
  
  
  
  
  const handleacceptrej = (status)=> {
    


      var newstatus = "";
      if (frompage === "myemp") {
        dispatch(changeupdatepressvaluemyemplv(true));

      } else {
        dispatch(changeupdatepressvalueadmlv(true));
      }
          
          if (leavestatus.status === "waiting for Teamleader's approval") {
            if (status === "accept") {
              newstatus = "teamleader"
            } else {
              newstatus = "Rejected by Teamleader"
            }
          } else if (leavestatus.status === "waiting for Dept Head's approval") {
            if (status === "accept") {
              newstatus = "waiting for Admin's approval"
            } else {
              newstatus = "Rejected by Dept Head"
            }
          } else if (leavestatus.status === "waiting for Admin's approval") {
            if (status === "accept") {
              newstatus = "Leave Granted"
            } else {
              newstatus = "Rejected by Admin"
            }
          } else {
            if (status === "accept") {
            newstatus = "assistant"} else {
              newstatus = "Rejected by Dept Assistant Head"
            }
          }
          console.log(leavestatus.status === newstatus,12345)
          if (frompage === "myemp") {
            dispatch(callupdate_myemplv_service([loginaccessvalue,{status:newstatus},leavestatus.lvref,leavestatus.status,status]))
        
          } else {
            dispatch(callupdate_admlv_service([loginaccessvalue,{status:newstatus},leavestatus.lvref,leavestatus.status,status]))
        
          }
      

  }


  const getleavestatustype = (status) => {
    switch(status) {
      case '0':
        return 'Casual';
      case '1':
        return 'Earned';
      case '2':
        return 'Sick';
      case '3':
        return 'Maternal/Paternal';
      case '4':
      default:
        return 'Casual';
    }
  }



    
const getStatusColor = (leavestatus)=> {
  if (leavestatus =="waiting for Dept Head's approval") {
    return "orange";
  } else if (leavestatus =="waiting for Dept Assistant Head's approval") {
    return "orange";
  } else if (leavestatus == "waiting for Admin's approval") {
    return "purple";

  }else if (leavestatus == "waiting for Teamleader's approval") {
    return "orange";

  } else if (leavestatus == "Rejected by Dept Head") {
    return "red";
  } else if (leavestatus == "Rejected by Dept Assistant Head") {
    return "red";
  } else if (leavestatus == "Rejected by Admin") {
    return "red";
  } else {
    //print"statusother$leavestatus");
    return "green";
  }
}

  return (
    <div className='lvreddetails' key={i}>
      
          <div className='lvreddetailsitem'>{i + 1}. {leavestatus.lvref}</div>
          <div className='lvreddetailsitem'>
            <div className="lvreddetailsitemcontent">
              <p><span>Name:</span> {leavestatus.enodetails.ename}</p>
              <p><span>ID:</span> {leavestatus.enodetails.areg_sno}</p>
              <p><span>Desig:</span> {leavestatus.enodetails.designation}</p>
              <p><span>Dept:</span> {leavestatus.enodetails.dept}</p>
            </div>
          </div>
          <div className='lvreddetailsitem'>
          <div className="lvreddetailsitemcontent">
              <p><span>From:</span> {getdateformatvalue(leavestatus.fdate)}</p>
              <p><span>To:</span> {getdateformatvalue(leavestatus.ldate)}</p>
              <p><span>Leave days:</span> {leavestatus.no_of_days}</p>
              <p><span>Applied on:</span> {getdateformatvalue(leavestatus.leave_adate)}</p>
            </div>
          </div>
          <div className='lvreddetailsitem'>
          <div className="lvreddetailsitemcontent">
              <p><span>Leave type:</span> {getleavestatustype(leavestatus.ltype)}</p>
              <p><span>Address during leave:</span> {leavestatus.addrleave}</p>
              <p><span>Reason:</span> {leavestatus.reason}</p>
            </div>
          </div>
          <div className='lvreddetailsitem'>
          {tabrev === "Unreviewed" ? (<div className="lvacceptrejtabs">
            <button className="tabbuttonslvrevacceptrej accept" onClick={()=>handleacceptrej("accept")}>Accept</button>
            <button className="tabbuttonslvrevacceptrej reject" onClick={()=>handleacceptrej("reject")}>Reject</button>
          </div>) : (<p> <span className='lvreviewedstatusclr' style={{background:getStatusColor(leavestatus.status)}}></span>  {leavestatus.status}</p>)}
          </div>
        </div>
  )
}

export default Leavedetailscontainer