import React, { useEffect } from 'react'
import "./Monthlyatndnc.css"
import Datepicker from "react-datepicker"
import format from 'date-fns/format';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';
import { FaCalendar } from "react-icons/fa";
import Atndncdetailselement from '../../Elements/Atndncdetailselement/Atndncdetailselement';
import { useDispatch, useSelector } from 'react-redux';
import { callmonthlyatn_service, handlesetstatuserrormonthlyatn } from '../../Redux/Slice/Monthlyatnslice';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../Redux/Slice/Loginslice';
import { Loader_rotatelines } from '../../App';
import { useNavigate } from 'react-router-dom';
const Monthlyatndnc = () => {
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [monthchanged,setMonthchanged] = useState(false);

















    const loginpage_slice = useSelector((state) => state.login);
  
  

  const monthlyatnpage_slice = useSelector((state) => state.monthlyatn);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserrormonthlyatn())
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (monthlyatnpage_slice.data !== null) {
        callmonthlyatn_get_api() 
      }
      //
    }
    
  },[])
  const navigate = useNavigate()
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    if (monthlyatnpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (monthlyatnpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    } else if ((monthlyatnpage_slice.status !== '200' && monthlyatnpage_slice.status !=="") || monthlyatnpage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserrormonthlyatn())
      
      navigate("/error")
    }

  },[monthlyatnpage_slice.error,monthlyatnpage_slice.status])

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && monthlyatnpage_slice.data === null) {
        callmonthlyatn_get_api() }
  },[loginpage_slice.data['access']])
  useEffect(()=> {
    console.log(monthchanged)
    if (loginpage_slice.data['access'] !== '' && monthchanged === true) {
      callmonthlyatn_get_api()
      if (monthchanged === true) {
        setMonthchanged(q=>!q)
      }
    
    }
  
  },[monthchanged])


  const callmonthlyatn_get_api = ()=> {
    dispatch(callmonthlyatn_service([`${loginpage_slice.data['access']}`,`${selectedMonth.getMonth() + 1}`,`${selectedMonth.getFullYear()}`]))
  }
    









  return (
    <div className='monthlyatndnccontain'>
    {(monthlyatnpage_slice.loading || loginpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
    {
      <div className='monthlyatndnc'>
        
    <div className="monthdatepicker">
    <Datepicker
  selected={selectedMonth}
  onChange={date => {
    setSelectedMonth(date)
    if (monthchanged === false) {
      setMonthchanged(q=>!q)
    }}}
  maxDate={new Date()}
  closeOnScroll
  showMonthYearPicker
  displayFormat="MMMM YYYY"
  dateFormat= "yyyy, MMMM"
  customInput={<button className='monthpicker'><div className='monthpickerdetails'> <FaCalendar /></div> {format(selectedMonth,"MMMM - yyyy")}</button>}
/>
    </div>
    {(monthlyatnpage_slice.data !== null && monthlyatnpage_slice.loading === false) &&<div className='monthtopcon'>
    <div className='topdetailsmonthly'>

<div className='userdetailsmonthly'>
<div className="imgholdermonthly">
<img className={`userimgmonthly ${monthlyatnpage_slice.data.results[0].sex}`} src={monthlyatnpage_slice.data.results[0].photo} alt="" />

</div>
<div className='userinfomonthly'>
<h3>{monthlyatnpage_slice.data.results[0].ename}</h3>
<h4>ID: {monthlyatnpage_slice.data.results[0].areg_sno}</h4>
<h4>Designation: {monthlyatnpage_slice.data.results[0].designation}</h4>
<h4>Department: {monthlyatnpage_slice.data.results[0].dept}</h4>
</div>
</div>
<div className='atndncinfomonthly'>
<div className='atndncinfochildmonthly'>
    
    <p><span className='atndncinfochildhighlightmonthly' style={{background:"green"}}></span> Present: {monthlyatnpage_slice.data.results[0].presentcount} days</p>
    <p><span className='atndncinfochildhighlightmonthly' style={{background:"orange"}}></span> Late: {monthlyatnpage_slice.data.results[0].latecount} days</p>
    <p><span className='atndncinfochildhighlightmonthly' style={{background:"red"}}></span> Absent: {monthlyatnpage_slice.data.results[0].absentcount} days</p>
    <p><span className='atndncinfochildhighlightmonthly' style={{background:"purple"}}></span> Leave taken: {monthlyatnpage_slice.data.results[0].leavecount} days</p>
    <p><span className='atndncinfochildhighlightmonthly' style={{background:"darkcyan"}}></span> Remote: {monthlyatnpage_slice.data.results[0].remotecount} days</p>
</div>
</div>
</div>
<div className='atnlistcontainer'>
<Atndncdetailselement data={monthlyatnpage_slice.data.results[0].attendance}/>

</div>
    </div>}
    

</div>}</div>
  )
}

export default Monthlyatndnc