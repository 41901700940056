



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";


export const calladmempdashboard_service = createAsyncThunk("calladmempdashboard", async (data) => {
    console.log(data)
    
    const response = await fetch(`${myport}adminemployeedashboard_get/${data[1]}/`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})

export const admempdashboardSlice = createSlice({
    name: "admempdashboard",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,

      },
      reducers: {
        getdatafromdashboard(state, action) {
            console.log(state.error);
            state.data = action.payload;
            console.log(action.payload);
          },
          handlesetstatuserroradmdash(state,action) {
            state.data = null;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },

      },
      
      extraReducers: (builder) => {
        builder.addCase(calladmempdashboard_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladmempdashboard_service.fulfilled, (state,action) => {
          if (action.payload[0] === "alreadyloggedout") {
            state.error = "alreadyloggedout"

        } else if (action.payload[0] === "noemp") {
          state.error = "noemp"
          console.log("ss")
          state.data = null;

      } else if (action.payload[1] === 200) {
            state.error = null;
            
            
            state.data = action.payload[0];
            
            console.log(state.data);
          } else {
            state.error = null
          }
          state.status = `${action.payload[1]}`;
            state.loading = false;
        });
        builder.addCase(calladmempdashboard_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { getdatafromdashboard,handlesetstatuserroradmdash } = admempdashboardSlice.actions
export default admempdashboardSlice.reducer