






import React, { useEffect, useState } from 'react'
import "./SIdebar.css"
import { NavLink, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { calllogout_service, callunauth_logout_service, handlenoactcookie } from '../../Redux/Slice/Loginslice';

const Sidebar = ({showsidebar,setShowsidebar}) => {
    const loginpage_slice = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logoutpressed,setLogoutpressed] = useState(false);
    
    useEffect(()=> {
        if (logoutpressed === true) {
            console.log(loginpage_slice.data["loggedin"])
        if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        setLogoutpressed(false);
        
    }
        }

    },[loginpage_slice.data["loggedin"]])
    useEffect(()=> {
        console.log(loginpage_slice.error)
        if (loginpage_slice.error === "alreadyloggedout") {
          console.log("s")
          
          dispatch(callunauth_logout_service("fromlogout"))
        } else if (loginpage_slice.status === "401") {
          
          dispatch(handlenoactcookie("fromlogout"))
        }
    
      },[loginpage_slice.error,loginpage_slice.status])
    const calllogout_get_api = ()=> {
        if (loginpage_slice.loading === false) {
            dispatch(calllogout_service(loginpage_slice.data['access']))

        }
    }
    const handlelogout = ()=> {
        if (logoutpressed === false) {
            console.log("ds")
            setLogoutpressed(prev=>!prev)
        }
        setShowsidebar(q=>!q)
        calllogout_get_api()
    }
  return (
    <div>
        { loginpage_slice.data["loggedin"] &&
            <div className={`sidebar ${showsidebar ? "showsidebar" : "hidesidebar"}`}>
    
            <NavLink contextMenu onClick={() => setShowsidebar(q=>!q)} className={`sidebarlist ${({isActive})=> isActive ? "active" : "inactive"}`} to="/dashboard">
                    Dashboard
                </NavLink>
            <NavLink contextMenu onClick={() => setShowsidebar(q=>!q)} className={`sidebarlist ${({isActive})=> isActive ? "active" : "inactive"}`} to="/user">
                    User info.
                </NavLink>
            <NavLink contextMenu onClick={() => setShowsidebar(q=>!q)} className={`sidebarlist ${({isActive})=> isActive ? "active" : "inactive"}`} to="/todayattendance">
                    Today Attendance
                </NavLink>
            <NavLink contextMenu onClick={() => setShowsidebar(q=>!q)} className={`sidebarlist ${({isActive})=> isActive ? "active" : "inactive"}`} to="/monthlyattendance">
                    Monthly Attendance
                </NavLink>
            <NavLink contextMenu onClick={() => setShowsidebar(q=>!q)} className={`sidebarlist ${({isActive})=> isActive ? "active" : "inactive"}`} to="/leaveapplication">
                    Leave Application
                </NavLink>
            <NavLink contextMenu onClick={() => setShowsidebar(q=>!q)} className={`sidebarlist ${({isActive})=> isActive ? "active" : "inactive"}`} to="/leavestatus">
                    Leave Status
                </NavLink>
            {(loginpage_slice.data['tl'] !== 0 || loginpage_slice.data['hd'] !== 0 || loginpage_slice.data['assthd'] !== 0) && <NavLink contextMenu onClick={() => setShowsidebar(q=>!q)} className={`sidebarlist admtm ${({isActive})=> isActive ? "active" : "inactive"}`} to="/myemployees">
                    My Employees
                </NavLink>}
            {(loginpage_slice.data['adm'] !== 0) && <NavLink contextMenu onClick={() => setShowsidebar(q=>!q)} className={`sidebarlist admtm ${({isActive})=> isActive ? "active" : "inactive"}`} to="/admin">
                    Admin Panel
                </NavLink>}
            <div onClick={() => handlelogout()} className='sidebarlist logout'>
                    Log Out
                </div>
            
            </div>
        }
    </div>
    
        
    
  )
}

export default Sidebar
















