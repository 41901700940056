import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route,NavLink, useLocation, Navigate } from "react-router-dom"
import { FaBars } from "react-icons/fa";

import Dashboard from './components/Dashboard/Dashboard';
import Monthlyatndnc from './components/Monthlyatndnc/Monthlyatndnc';
import Sidebar from './components/Sidebar/Sidebar';
import React, { useState,useRef } from 'react';
import { useEffect } from 'react';
import Userinfo from './components/Userinfo/Userinfo';
import Todayattendance from './components/Todayattendance/Todayattendance';
import Leaveapplication from './components/Leaveapplication/Leaveapplication';
import Leavestatus from './components/Leavestatus/Leavestatus';
import Adminpanel from './components/Adminpanel/Adminpanel';
import Calendar from './components/Adminpanel/Calendar/Calendar';
import Emplvapp from './components/Adminpanel/Emplvapp/Emplvapp';
import Empmonthsum from './components/Adminpanel/Empmonthsum/Empmonthsum';
import Emplateabsent from './components/Adminpanel/Emplateabsent/Emplateabsent';
import Empdashboard from './components/Adminpanel/Empdashboard/Empdashboard';
import Newemp from './components/Adminpanel/Newemp/Newemp';
import Modifyemp from './components/Adminpanel/Modifyemp/Modifyemp';
import Emptl from './components/Adminpanel/Emptl/Emptl';
import Allemp from './components/Adminpanel/Allemp/Allemp';
import Myemployees from './components/Myemployees/Myemployees';
import Myemplvapp from './components/Myemployees/Myemplvapp/Myemplvapp';
import Myemployeesmonthsum from './components/Myemployees/Myemployeesmonthsum/Myemployeesmonthsum';
import Loginpage from './components/Loginpage/Loginpage';
import { FadeLoader } from "react-spinners"
import { useDispatch, useSelector } from 'react-redux';
import { format } from "date-fns"
import Errorpage from './components/Errorpage/Errorpage';
import { callreftoken_interval_service, callreftoken_service } from './Redux/Slice/Loginslice';
export const getstatusformat = (status)=> {
  switch(status) {
    case 'I':
      return 'In';
    case 'P':
      return 'Present';
    case 'A':
      return 'Absent';
    case 'A':
      return 'Absent';
    case 'L':
      return 'Leave';
    case 'H':
      return 'Holiday';
    default:
      return 'Remote';
  }}

export const getstatuscolorformat = (status)=> {
  switch(status) {
    case 'I':
      return 'green';
    case 'P':
      return 'green';
    case 'A':
      return 'red';
    case 'L':
      return 'purple';
    case 'H':
      return 'black';
    default:
      return 'darkcyan';
  }


}

export const myport = "https://www.hr.erp-emsl.com/hrmainbackendservererp-emsl/react_";
export const getdateformatvalue = (date)=> {
    return date === null ? "" : format(date, "yyyy-MM-dd");
  }
export const getdateformatvaluenullstr = (date)=> {
    return date === null ? null : format(date, "yyyy-MM-dd");
  }

export const Loader_rotatelines = ()=> {

  return <FadeLoader color="grey" height={10} width={10} margin={0} radius={50}/>
  ;
}
function App() {
  
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch()
  useEffect(()=>{
    let interval
    if (login.data["loggedin"]) {
      interval = setInterval(()=>{
        console.log("sss")
        if (login.data['refresh'] !== "" && login.problemhappened === false) {
          console.log(1)
          dispatch(callreftoken_interval_service());
          
        }
  
      },6600000)//every 1 hr 50 minutes.access life is 2 hrs in backend
      return ()=> clearInterval(interval)
    } else {
      console.log("dss")
      return ()=> clearInterval(interval)
    }
  },[login.data["refresh"],login.data["loggedin"]])

  
  const [showsidebar,setShowsidebar] = useState(false);
  const [iscurrentpagelogin,setIscurrentpagelogin] = useState(false);
  
  const sidebarRef = useRef(null);
  const menuref = useRef(null);
  useEffect(() => {
    console.log("hi")
   
    const handleClickOutside = (event) => {
      
      if ((sidebarRef.current && !sidebarRef.current.contains(event.target))&&(menuref.current && !menuref.current.contains(event.target))) {
        // Close the sidebar logic
        
        setShowsidebar(false)
        
        
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    
  
   
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);
const Mynav = ()=> {
  return (<div  className='navbar'>
  <div className='navbarmenu' ref={menuref}><FaBars size={25}  color='white' onClick={()=>setShowsidebar(q=>!q)} /></div>
  

</div>);
}
  return (
    
    
    <BrowserRouter>
      
    <div ref={sidebarRef}><Sidebar showsidebar={showsidebar} setShowsidebar={setShowsidebar} /></div>
    
    <Routes>

      <Route path='/' element={<Loginpage login={login}/>}/>
      <Route path='/error' element={<Errorpage />}/>
      <Route path='/dashboard' element={<><Mynav /><Dashboard/></>}/>
      <Route path='/user' element={<><Mynav /><Userinfo login={login}/></>}/>
      <Route path='/todayattendance' element={<><Mynav /><Todayattendance/></>}/>
      
      <Route path='/monthlyattendance' element={<><Mynav /><Monthlyatndnc/></>}/>
      <Route path='/leaveapplication' element={<><Mynav /><Leaveapplication/></>}/>
      <Route path='/leavestatus' element={<><Mynav /><Leavestatus/></>}/>
      <Route path='/myemployees' element={<><Mynav /><Myemployees/></>}/>
      <Route path='/myemployees/empleaveapplications' element={<><Mynav /><Myemplvapp/></>}/>
      <Route path='/myemployees/empmonthsummary' element={<><Mynav /><Myemployeesmonthsum/></>}/>
      <Route path='/admin' element={<><Mynav /><Adminpanel/></>}/>
      <Route path='/admin/empleaveapplications' element={<><Mynav /><Emplvapp/></>}/>
      <Route path='/admin/empmonthsummary' element={<><Mynav /><Empmonthsum/></>}/>
      <Route path='/admin/emplateabsent' element={<><Mynav /><Emplateabsent/></>}/>
      <Route path='/admin/empdashboard' element={<><Mynav /><Empdashboard/></>}/>
      <Route path='/admin/newemp' element={<><Mynav /><Newemp/></>}/>
      <Route path='/admin/modifyemp' element={<><Mynav /><Modifyemp/></>}/>
      <Route path='/admin/emptl' element={<><Mynav /><Emptl/></>}/>
      <Route path='/admin/allemp' element={<><Mynav /><Allemp/></>}/>
      <Route path='/admin/calendar' element={<><Mynav /><Calendar/></>}/>
      {/* <Route path="*" element={<Dashboard />} /> */}
      <Route path="*" element={<Navigate to='/dashboard' />} />
    </Routes>
    
    </BrowserRouter>
   
  );
}

export default App;
