import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "./Slice/Loginslice";
import dashboardReducer from "./Slice/Dashboardslice";
import ReftokenReducer from "./Slice/Reftokenslice";
import userinfoReducer from "./Slice/Userinfoslice";
import todayatndncReducer from "./Slice/Todayatndncslice";
import monthlyatnReducer from "./Slice/Monthlyatnslice";
import leavestatusReducer from "./Slice/Leavestatusslice";
import adminpanelReducer from "./Slice/Adminpanelslices/Adminpanelslice";
import admemplvappsReducer from "./Slice/Adminpanelslices/Admemplvappsslice";
import admempmonthsumReducer from "./Slice/Adminpanelslices/Admempmonthsumslice";
import admdaywiseatnReducer from "./Slice/Adminpanelslices/Admdaywiseatnslice";
import admempdashboardReducer from "./Slice/Adminpanelslices/Admempdashboardslice";
import admnewempReducer from "./Slice/Adminpanelslices/Admnewempslice";
import admmodempReducer from "./Slice/Adminpanelslices/Modifyempslice";
import admemptlReducer from "./Slice/Adminpanelslices/Admemptlslice";
import admallempReducer from "./Slice/Adminpanelslices/Admallempslice";
import admcalendarReducer from "./Slice/Adminpanelslices/Admcalendarslice";
import myemppanelReducer from "./Slice/Myemployeesslices/Myemppanelslice";
import myemplvappsReducer from "./Slice/Myemployeesslices/Myemplvappslice";
import myempmonthsumReducer from "./Slice/Myemployeesslices/Myempmonthsumslice";
import lvappReducer from "./Slice/Leaveapplicationslice";


const rootReducer = combineReducers({
    login: loginReducer,
    dashboard: dashboardReducer,
    reftoken: ReftokenReducer,
    userinfo: userinfoReducer,
    todayatndnc: todayatndncReducer,
    monthlyatn: monthlyatnReducer,
    lvapp: lvappReducer,
    leavestatus: leavestatusReducer,
    adminpanel: adminpanelReducer,
    admemplvapps: admemplvappsReducer,
    admempmonthsum:admempmonthsumReducer,
    admdaywiseatn: admdaywiseatnReducer,
    admempdashboard: admempdashboardReducer,
    admnewemp: admnewempReducer,
    admmodemp:admmodempReducer,
    admemptl: admemptlReducer,
    admallemp: admallempReducer,
    admcalendar:admcalendarReducer,
    myemppanel: myemppanelReducer,
    myemplvapps: myemplvappsReducer,
    myempmonthsum: myempmonthsumReducer

})

export const store = configureStore({
    reducer: rootReducer
})