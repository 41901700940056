import "./Leavesheet.css"; 
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { callupdateemp_adm_service, callupdateemplv_adm_service } from "../../Redux/Slice/Adminpanelslices/Modifyempslice";
import { Loader_rotatelines, getdateformatvaluenullstr } from "../../App";
import swal from "sweetalert";
const Leavesheet = ({ isleavessheetopen, setIsleavessheetopen, admmodemppage_slice }) => {
    const loginpage_slice = useSelector((state) => state.login);
    const dispatch = useDispatch();
    console.log("okkkcal",admmodemppage_slice.data["casualleave"])
    const [clbal,setClbal] = useState(admmodemppage_slice.data["casualleave"])
    const [slbal,setSlbal] = useState(admmodemppage_slice.data["sickleave"])
    const [mlbal,setMlbal] = useState(admmodemppage_slice.data["earnedleave"])
    const [elbal,setElbal] = useState(admmodemppage_slice.data["maternalleave"])
    const handleclchange = (event) => {
        setClbal(event.target.value);
  
    }
    const handleslchange = (event) => {
        setSlbal(event.target.value);
      };
    const handleelchange = (event) => {
        setElbal(event.target.value);
      };
    const handlemlchange = (event) => {
        setMlbal(event.target.value);
      };
    useEffect(()=>{
        console.log("s")
        if (lvupdatepress === true) {
          if (admmodemppage_slice.error === "failed") {
            console.log("ss")
            swal("Update failed. Please insert valid inputs to update!", {
              buttons: false,
              icon:"error",
              title:"Failed!"
            });
          } else if (admmodemppage_slice.error === "lessthan0") {
            console.log("ss")
            swal("Leave days can not be less than 0!", {
              buttons: false,
              icon:"error",
              title:"Failed!"
            });
          }
        }
        if (lvupdatepress === true) {
          setLvupdatepress(q=>!q)
         }
         
      },[admmodemppage_slice.error])

    useEffect(() => {
        
          setClbal(admmodemppage_slice.data.casualleave);
          setElbal(admmodemppage_slice.data.earnedleave);
          setSlbal(admmodemppage_slice.data.sickleave);
          setMlbal(admmodemppage_slice.data.maternalleave);
          
        
      }, [isleavessheetopen]);
    const [lvupdatepress,setLvupdatepress] = useState(false);
    const callupdateleaveapi = ()=> {
      if (lvupdatepress === false) {
        setLvupdatepress(q=>!q)
       }
       var data = {};
       if (clbal !== admmodemppage_slice.data["casualleave"]) {
         data = { ...data, ...{ cl: clbal } };
       }
       if (slbal !== admmodemppage_slice.data["sickleave"]) {
        data = { ...data, ...{ sl: slbal } };
      }
       if (elbal !== admmodemppage_slice.data["earnedleave"]) {
        data = { ...data, ...{ el: elbal } };
      }
       if (mlbal !== admmodemppage_slice.data["maternalleave"]) {
        data = { ...data, ...{ ml: mlbal } };
      }
      console.log(Object.keys(data).length === 0);
      if (Object.keys(data).length === 0) {
        if (isleavessheetopen === true) {
            setIsleavessheetopen(q=>!q)
        }

      } else {
        console.log(admmodemppage_slice.data.areg_sno)
        dispatch(callupdateemplv_adm_service([loginpage_slice.data['access'],data,admmodemppage_slice.data.areg_sno]))
      }
    //  
  
    }
  
    
    
  
    return <Dialog sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "600px",  // Set your width here
        },
      },
    }} open={isleavessheetopen}>
      
    
    <div className='editdetailsdivoverlaymodemp'>
    {admmodemppage_slice.loading && (
              <div className="loader_login">
                <Loader_rotatelines />
              </div>
            )}
    
    <div className='editdetailsdivmodemp'>
    <div className="sheetheadmodemp">
      <button className='cancelbuttonsheetmodemp' onClick={()=>setIsleavessheetopen
      (q=>!q)}>Cancel</button>
      <h3>Edit employee leaves</h3>
    </div>
  
  
    
    <div className="sheetbody">
    <div className='sheetinputmodemp'>
    <input value={clbal} onChange={handleclchange} maxLength={50} type="number" onWheel={(e) => e.target.blur()} placeholder='0' min={0}/>
    <span>Casual</span>
    </div>
    <div className='sheetinputmodemp'>
    <input value={slbal} onChange={handleslchange} maxLength={50} type="number" onWheel={(e) => e.target.blur()} placeholder='0' min={0}/>
    <span>Sick</span>
    </div>
    <div className='sheetinputmodemp'>
    <input value={elbal} onChange={handleelchange} type="number" onWheel={(e) => e.target.blur()} placeholder='0' min={0}/>
    <span>Earned</span>
    </div>
    <div className='sheetinputmodemp'>
    <input value={mlbal} onChange={handlemlchange} type="number" onWheel={(e) => e.target.blur()} placeholder='0' min={0}/>
    <span>Maternal/Paternal</span>
    </div>
    <button onClick={()=>callupdateleaveapi()} className="submitbtnsheetmodemp">Update</button>
    </div>
  </div> </div> </Dialog>
  }
  
  
  export default Leavesheet