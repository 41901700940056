



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../App";


export const calldashboard_service = createAsyncThunk("calldashboard", async (data) => {
    console.log(data)
    
    const response = await fetch(`${myport}dashboard_get/`,{headers: {
        'Authorization': `Bearer ${data}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,

      },
      reducers: {
        handlesetstatuserrordashboard(state,action) {
            state.data = null;
            console.log(state.status)
            console.log(state.error)
            if (state.status === "401") {
                state.status = ""
            }
            if (state.error === "alreadyloggedout") {
                state.error = null;
            }
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calldashboard_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calldashboard_service.fulfilled, (state,action) => {
            if (action.payload === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
            state.data = action.payload[0];
            state.error = null;

           } else {
            state.error = null;

           }
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(calldashboard_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserrordashboard } = dashboardSlice.actions
export default dashboardSlice.reducer