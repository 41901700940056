import React, { useEffect } from 'react'
import "./Adminpanel.css"
import { NavLink, useNavigate } from "react-router-dom"
import { calladminpanel_service, handlesetstatuserroradmpanel } from '../../Redux/Slice/Adminpanelslices/Adminpanelslice';
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../Redux/Slice/Loginslice';
import { Loader_rotatelines } from '../../App';
import { handlesetstatuserroradmcalen } from '../../Redux/Slice/Adminpanelslices/Admcalendarslice';
const Adminpanel = () => {
  const navigate = useNavigate();

  const loginpage_slice = useSelector((state) => state.login);
  
  

  const adminpanelpage_slice = useSelector((state) => state.adminpanel);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserroradmpanel())
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (adminpanelpage_slice.data !== null) {
        calladminpanel_get_api()
      }
      //
    }
    
    
  },[])
  
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (adminpanelpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (adminpanelpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }

  },[adminpanelpage_slice.error,adminpanelpage_slice.status])

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && adminpanelpage_slice.data === null) {
      calladminpanel_get_api() }
  },[loginpage_slice.data['access']])


  const calladminpanel_get_api = ()=> {
    dispatch(calladminpanel_service(`${loginpage_slice.data['access']}`))
  
  }

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (adminpanelpage_slice.data != null) {

      if (adminpanelpage_slice.data !== "done") {
        navigate("/dashboard")
      }
    }
  },[adminpanelpage_slice.data])



























  
  
  
  return (
    <div className='admpanelouter'>
      {
        (adminpanelpage_slice.loading || loginpage_slice.loading === true) && <div className="loader_login"><Loader_rotatelines  /></div> 
      }
      {(adminpanelpage_slice.loading === false && loginpage_slice.loading === false) && <div className='adminpanels'>
      <NavLink className={"admnav"} contextMenu  to="/admin/empleaveapplications">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/empleaveapplications")}>
        <div className="panelcircle" style={{background:"red"}}></div>
        <div className="paneltitle">Employee Leave Applications</div>
      </div>
      </NavLink>
      <NavLink className={"admnav"} contextMenu  to="/admin/empmonthsummary">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/empmonthsummary")}>
        <div className="panelcircle" style={{background:"green"}}></div>
        <div className="paneltitle">Employee Monthly Attendance Summary</div>
      </div>
      </NavLink>



      <NavLink className={"admnav"} contextMenu  to="/admin/emplateabsent">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/emplateabsent")}>
        <div className="panelcircle" style={{background:"purple"}}></div>
        <div className="paneltitle">Employee Late & Absent Check</div>
      </div>
      </NavLink>


      <NavLink className={"admnav"} contextMenu  to="/admin/empdashboard">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/empdashboard")}>
        <div className="panelcircle" style={{background:"brown"}}></div>
        <div className="paneltitle">Employee Dashboard</div>
      </div>
      </NavLink>


      <NavLink className={"admnav"} contextMenu  to="/admin/newemp">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/newemp")}>
        <div className="panelcircle" style={{background:"orange"}}></div>
        <div className="paneltitle">New Employee</div>
      </div>
      </NavLink>


      <NavLink className={"admnav"} contextMenu  to="/admin/modifyemp">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/modifyemp")}>
        <div className="panelcircle" style={{background:"blue"}}></div>
        <div className="paneltitle">Modify Employee</div>
      </div>
      </NavLink>


      <NavLink className={"admnav"} contextMenu  to="/admin/emptl">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/emptl")}>
        <div className="panelcircle" style={{background:"yellow"}}></div>
        <div className="paneltitle">Employees & Teamleaders</div>
      </div>
      </NavLink>


      <NavLink className={"admnav"} contextMenu  to="/admin/allemp">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/allemp")}>
        <div className="panelcircle" style={{background:"rgb(255, 0, 187)"}}></div>
        <div className="paneltitle">All Employee</div>
      </div>
      </NavLink>


      <NavLink className={"admnav"} contextMenu  to="/admin/calendar">
      <div className="adminpanelelement" onClick={()=>navigate("/admin/calendar")}>
        <div className="panelcircle" style={{background:"rgb(34, 255, 0)"}}></div>
        <div className="paneltitle">Calendar</div>
      </div>
      </NavLink>
      

    </div>}
    </div>
  )
}

export default Adminpanel