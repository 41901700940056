import React, { useEffect, useState } from 'react'
import "./Dashboard.css"
import { BarChart, Bar, XAxis, YAxis,Tooltip, Cell } from "recharts"
import Atndncdetailselement from '../../Elements/Atndncdetailselement/Atndncdetailselement';
import { useDispatch, useSelector } from 'react-redux';
import { calldashboard_service, handlesetstatuserrordashboard } from '../../Redux/Slice/Dashboardslice';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../Redux/Slice/Loginslice';
import { Loader_rotatelines } from '../../App';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({login}) => {
  const navigate = useNavigate();
  const currentdate = new Date();
  const loginpage_slice = useSelector((state) => state.login);
  
  

  const dashboardpage_slice = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserrordashboard());
    
    console.log("dashboard")
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if ( dashboardpage_slice.data !== null) {
        calldashboard_api()

      }
      //
    }
  },[])
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (dashboardpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (dashboardpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }  else if ((dashboardpage_slice.status !== '200' && dashboardpage_slice.status !=="") || dashboardpage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserrordashboard())
      
      navigate("/error")
    }

  },[dashboardpage_slice.error,dashboardpage_slice.status])

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && dashboardpage_slice.data === null) {
      console.log("call")
      calldashboard_api() }
  },[loginpage_slice.data['access']])


  const calldashboard_api = ()=> {
    dispatch(calldashboard_service(`${loginpage_slice.data['access']}`))
    console.log(dashboardpage_slice.status)
    
    
    
   
    
  }
  useEffect(()=> {
    if (dashboardpage_slice.data !== null) {
      setChartdata(
        [
          {name: 'Present', uv: dashboardpage_slice.data.presentcount,},
          {name: 'Late', uv: dashboardpage_slice.data.latecount,},
          {name: 'Absent', uv: dashboardpage_slice.data.absentcount,},
          {name: 'Leave', uv: dashboardpage_slice.data.leavecount,},
          {name: 'Remote', uv: dashboardpage_slice.data.remotecount}
        ]
      )
      
    }
    
  },[dashboardpage_slice.data])
  

    
    const [chartdata,setChartdata] = useState(
      [
      ]
    );
      const renderCustomBarLabel = ({ x, y, width, value }) => {
        return <text style={{fontWeight:"bold",color:"black"}} x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{`${value} days`}</text>;
      };
      const renderCustomAxisTick = ({ x, y, payload }) => {
        let path = '';
      
        switch (payload.value) {
          case 'Page A':
            path = 'M899.072 99.328q9.216 13.312 17.92 48.128t16.384 81.92 13.824 100.352 11.264 102.912 9.216 90.112 6.144 60.928q4.096 30.72 7.168 70.656t5.632 79.872 4.096 75.264 2.56 56.832q-13.312 16.384-30.208 25.6t-34.304 11.264-34.304-2.56-30.208-16.896q-1.024-10.24-3.584-33.28t-6.144-53.76-8.192-66.56-8.704-71.68q-11.264-83.968-23.552-184.32-7.168 37.888-11.264 74.752-4.096 31.744-6.656 66.56t-0.512 62.464q1.024 18.432 3.072 29.184t4.608 19.968 5.12 21.504 5.12 34.304 5.12 56.832 4.608 90.112q-11.264 24.576-50.688 42.496t-88.576 29.696-97.28 16.896-74.752 5.12q-18.432 0-46.08-2.56t-60.416-7.168-66.048-12.288-61.952-17.92-49.664-24.064-28.16-30.208q2.048-55.296 5.12-90.112t5.632-56.832 5.12-34.304 5.12-21.504 4.096-19.968 3.584-29.184q2.048-27.648-0.512-62.464t-6.656-66.56q-4.096-36.864-11.264-74.752-13.312 100.352-24.576 184.32-5.12 35.84-9.216 71.68t-8.192 66.56-6.656 53.76-2.56 33.28q-13.312 12.288-30.208 16.896t-34.304 2.56-33.792-11.264-29.696-25.6q0-21.504 2.048-56.832t4.096-75.264 5.632-79.872 6.656-70.656q2.048-20.48 6.144-60.928t9.728-90.112 11.776-102.912 13.824-100.352 16.384-81.92 17.92-48.128q20.48-12.288 56.32-25.6t73.216-26.624 71.168-25.088 50.176-22.016q10.24 13.312 16.896 61.44t13.312 115.712 15.36 146.432 23.04 153.6l38.912-334.848-29.696-25.6 43.008-54.272 15.36 2.048 15.36-2.048 43.008 54.272-29.696 25.6 38.912 334.848q14.336-74.752 23.04-153.6t15.36-146.432 13.312-115.712 16.896-61.44q16.384 10.24 50.176 22.016t71.168 25.088 73.216 26.624 56.32 25.6';
            break;
          case 'Page B':
            path = 'M662.528 451.584q10.24 5.12 30.208 16.384t46.08 31.744 57.856 52.736 65.024 80.896 67.072 115.2 64.512 154.624q-15.36 9.216-31.232 21.504t-31.232 22.016-31.744 15.36-32.768 2.56q-44.032-9.216-78.336-8.192t-62.976 7.68-53.248 16.896-47.616 19.968-46.08 16.384-49.664 6.656q-57.344-1.024-110.592-16.896t-101.376-32.256-89.6-25.088-75.264 4.608q-20.48 8.192-41.984 1.024t-38.912-18.432q-20.48-13.312-39.936-33.792 37.888-116.736 86.016-199.68t92.672-136.704 78.848-81.408 43.52-33.792q9.216-5.12 10.24-25.088t-1.024-40.448q-3.072-24.576-9.216-54.272l-150.528-302.08 180.224-29.696q27.648 52.224 53.76 79.36t50.176 36.864 45.568 5.12 39.936-17.92q43.008-30.72 80.896-103.424l181.248 29.696q-20.48 48.128-45.056 99.328-20.48 44.032-47.616 97.28t-57.856 105.472q-12.288 34.816-13.824 57.344t1.536 36.864q4.096 16.384 12.288 25.6z';
            break;
            
          default:
            path = '';
        }
      
        return (
          <svg x={x - 12} y={y + 4} width={24} height={24} viewBox="0 0 1024 1024" fill="#666">
            <path d={path} />
          </svg>
        );
      };
  return (
    <div className='dashboard'>
      {(loginpage_slice.loading || dashboardpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
    {(dashboardpage_slice.data !== null && loginpage_slice.data["loggedin"] === true) && <div className='dashboardin'>
      
      <div className='topdetails'>

          <div className='userdetails'>
          <div className="imgholder">
              <img className={`userimg ${dashboardpage_slice.data.sex}`} src={dashboardpage_slice.data.photo} alt="" />

          </div>
          <div className='userinfodashboard'>
              <h3>{`${dashboardpage_slice.data.ename}`}</h3>
              <h4>ID:{`${dashboardpage_slice.data.areg_sno}`}</h4>
              <h4>Designation: {`${dashboardpage_slice.data.designation}`}</h4>
              <h4>Department: {`${dashboardpage_slice.data.dept}`}</h4>
          </div>
          </div>
          <div className='atndncinfo'>
              <div className='atndncinfochild'>
                  
                  <p><span className='atndncinfochildhighlight' style={{background:"green"}}></span> Present: {`${dashboardpage_slice.data.presentcount}`} days</p>
                  <p><span className='atndncinfochildhighlight' style={{background:"orange"}}></span> Late: {`${dashboardpage_slice.data.latecount}`} days</p>
                  <p><span className='atndncinfochildhighlight' style={{background:"red"}}></span> Absent: {`${dashboardpage_slice.data.absentcount}`} days</p>
                  <p><span className='atndncinfochildhighlight' style={{background:"purple"}}></span> Leave taken: {`${dashboardpage_slice.data.leavecount}`} days</p>
                  <p><span className='atndncinfochildhighlight' style={{background:"indigo"}}></span> Leave left: {`${dashboardpage_slice.data.leaveleftcount}`} days</p>
                  <p><span className='atndncinfochildhighlight' style={{background:"darkcyan"}}></span> Remote: {`${dashboardpage_slice.data.remotecount}`} days</p>
              </div>
          </div>
      </div>
      <div className="summarystat">
          <p>Attendance Summary ({currentdate.getFullYear()})</p>
          <BarChart className='barchart' width={600} height={300} data={chartdata}>
  <XAxis dataKey="name" />
  <YAxis domain={[0,Math.max(...chartdata.map(item => item.uv))+4]}  tickFormatter={value=>Math.floor(value)}/>
  
  <Bar  dataKey="uv" barSize={100} fill="black"
    label={renderCustomBarLabel}> 
    {chartdata.map((entry, index) => (
          <Cell fill={entry.name === "Present" ? 'green' : entry.name === "Late" ? "orange" : entry.name === "Absent" ? "Red" : entry.name === "Leave" ? "purple" : "darkcyan" }/>
      ))}</Bar>
</BarChart>
      </div>
      <hr />
      <p className='lastHeading'>Last {dashboardpage_slice.data.attendancefivedays.length} days attendance</p>
      <Atndncdetailselement data={dashboardpage_slice.data.attendancefivedays} />
      <hr />
      <div style={{height:"20px"}}></div>

      

  </div>}
    </div>
  )
}

export default Dashboard