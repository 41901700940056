



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";
import { adminpanelSlice } from "./Adminpanelslice";


export const calladmemplvapps_service = createAsyncThunk("calladmemplvapps", async (data) => {
    console.log(data)
    
    const response = await fetch(data[3] !== "null" ? data[3] : `${myport}${data[2] === "Unreviewed" ? "leavetransadminget" : "leavetransadminReviewedget"}/${data[1]}/?page=1`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[3],data[2]];
})

export const callupdate_admlv_service = createAsyncThunk("callupdateadmlv", async (data) => {
   
    const response = await fetch(`${myport}levtransupdateadmin/${data[2]}/`,{method:'PUT',body: JSON.stringify(data[1]),headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include"
      ,});
      console.log(response.status,data[4])
      const jsondata = await response.json();
      
      return [jsondata,response.status,data[2],data[3],data[4]];
})

export const admemplvappsSlice = createSlice({
    name: "admemplvapps",
    initialState: {
      count:0,
      currenttab: "Unreviewed",
      next: "",
      loading: false,
      status: "",
      error: null,
      resultsdata:null,
      admupdatepostresponsebody: '',
      updateloading: false,
      updatepressed: false,

      },
      reducers: {
        cleardatabeforetabchange(state, action) {
            console.log(state.error);
            state.data = null;
            console.log(action.payload);
          },
          changeupdatepressvalueadmlv(state,action) {
            console.log(action.payload === true);
            state.updatepressed = action.payload
          },
          handlesetstatuserroradmlvapp(state,action) {
            state.resultsdata = null;
            state.currenttab = "Unreviewed";
            state.next = "";
            state.count = 0;
            state.updateloading = false;
            state.updatepressed = false;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },
      },
      
      extraReducers: (builder) => {
        builder.addCase(calladmemplvapps_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladmemplvapps_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[0] === "noemp") {
                state.error = "noemp"
                console.log("ss")
                state.resultsdata = null;
      
            } else if (action.payload[1] === 200) {
                state.error = null;
          state.next = action.payload[0].next === null ? "null" : action.payload[0].next;
          state.count = action.payload[0].count;
          if (action.payload[3] === "Unreviewed") {
              state.currenttab = "Unreviewed"
          } else {
              state.currenttab = "Reviewed"
          }

          if (action.payload[2] === "null") {
              state.resultsdata = action.payload[0].results;
         
          } else {
              console.log(state.resultsdata.length)
              state.resultsdata = [...state.resultsdata, ...action.payload[0].results]
              console.log(state.resultsdata.length)
         
          }
        } else {
            state.error = null;
        }
          
          state.status = `${action.payload[1]}`;
          state.loading = false;
        });
        builder.addCase(calladmemplvapps_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });









        builder.addCase(callupdate_admlv_service.pending, (state)=> {
            state.admupdatepostresponsebody = '';
            state.updateloading = true;
            state.error = null;
        });
        builder.addCase(callupdate_admlv_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                console.log(""+ action.payload[3].replace(/'/g, "\\'"))
                
                
                console.log(state.resultsdata.length)
                
                
                    
                const result = state.resultsdata.filter((lv)=> lv.lvref !== `${action.payload[2]}` && `${lv.status}`.replace(/'/g, "\\'") !== action.payload[3])
                        
                state.resultsdata = result
                console.log(state.resultsdata.length)
                state.count = state.count - 1
                    
                    

                        //state.resultsdata = []
                
            
           
            state.error = null;

           } else {
            state.error = null;

           }
           state.admupdatepostresponsebody = `${action.payload[4]} ${action.payload[0]}`
           console.log(state.admupdatepostresponsebody)
           
            
            
            state.status = `${action.payload[1]}`;
            state.updateloading = false;
        });
        builder.addCase(callupdate_admlv_service.rejected, (state,action) => {
            state.updateloading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { cleardatabeforetabchange,handlesetstatuserroradmlvapp,changeupdatepressvalueadmlv } = admemplvappsSlice.actions;
export default admemplvappsSlice.reducer