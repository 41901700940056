



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";



export const callmyemppanel_service = createAsyncThunk("callmyemppanel", async (data) => {
    console.log(data)
    
    const response = await fetch(`${myport}tlhdcheck/`,{headers: {
        'Authorization': `Bearer ${data}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})

export const myemppanelSlice = createSlice({
    name: "myemppanel",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,

      },
      reducers: {
        handlesetstatuserrormyemp(state,action) {
            state.data = null;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
            /* if (state.status === "401") {
                state.status = ""
            }
            if (state.error === "alreadyloggedout") {
                state.error = null;
            } */
          },
      },
      extraReducers: (builder) => {
        builder.addCase(callmyemppanel_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callmyemppanel_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                state.error = null
            
            
            state.data = action.payload[0];
            
            console.log(state.data);
            
        } else {
            state.error = null

        }
        state.loading = false;
        state.status = `${action.payload[1]}`;
        });
        builder.addCase(callmyemppanel_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserrormyemp } = myemppanelSlice.actions
export default myemppanelSlice.reducer