import React from 'react'
import "./Errorpage.css"
import { useNavigate } from 'react-router-dom'
import { IoArrowBackCircle } from "react-icons/io5";

const Errorpage = () => {
    const navigate = useNavigate()
  return (
    <div className='errpagediv'>
        <h2>Something went wrong!</h2>
        <span className='errpagegoback'><IoArrowBackCircle /><p  onClick={()=>navigate("/dashboard")}>Go back</p>
</span>
        
    </div>
  )
}

export default Errorpage