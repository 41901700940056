import React, { useEffect, useRef } from "react";

import "./Empmonthsum.css";

import Datepicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { FaCalendar } from "react-icons/fa";
import Atndncdetailselement from "../../../Elements/Atndncdetailselement/Atndncdetailselement";
import { useDispatch, useSelector } from "react-redux";
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from "../../../Redux/Slice/Loginslice";
import {
  calladmempmonthsum_service,
  calladmempsumprint_service,
  handlesetstatuserroradmmonthsum,
} from "../../../Redux/Slice/Adminpanelslices/Admempmonthsumslice";
import { Loader_rotatelines, getdateformatvalue } from "../../../App";
import { calladminpanel_service } from "../../../Redux/Slice/Adminpanelslices/Adminpanelslice";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Printmonthsum from "../Printmonthsum";

const Empmonthsum = () => {
  const navigate = useNavigate();
  const pdfref = useRef();

  const [resized, setResized] = useState(false);

  const loginpage_slice = useSelector((state) => state.login);
  const adminpanelpage_slice = useSelector((state) => state.adminpanel);

  const admempmonthsumpage_slice = useSelector((state) => state.admempmonthsum);
  const dispatch = useDispatch();
  const [cleardata,setCleardata] = useState(false);
  useEffect(() => {
    dispatch(handlesetstatuserroradmmonthsum());
    if (cleardata === false) {
      setCleardata(q=>!q)
    }
    if (loginpage_slice.data["access"] === "" && loginpage_slice.problemhappened === false) {
      console.log(1);
      dispatch(callreftoken_service());
    } else {
      if (admempmonthsumpage_slice.resultsdata !== null) {
        calladmempmonthsum_get_api("null")

      }
      //
    }

    const resizefunc = () => {
      console.log(resized);
      if (window.innerHeight >= document.documentElement.scrollHeight) {
        if (resized === false) {
          setResized((prev) => !prev);
        }
      }
    };
    const scrollfunc = () => {
      console.log(resized);

      if (
        window.innerHeight + document.documentElement.scrollTop + 200 >=
        document.documentElement.scrollHeight
      ) {
        if (resized === false) {
          setResized((prev) => !prev);
          console.log("oks");
        } else {
          console.log("ss");
        }
      }
    };
    window.addEventListener("resize", resizefunc);
    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("resize", resizefunc);
      window.removeEventListener("scroll", scrollfunc);
    };
  }, []);
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  const [err,setErr] = useState("")
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (admempmonthsumpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (admempmonthsumpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }  else if (admempmonthsumpage_slice.error === "noemp") {
      console.log("ss")
      setErr(admempmonthsumpage_slice.error)
      dispatch(handlesetstatuserroradmmonthsum());
      

    }  else if ((admempmonthsumpage_slice.status !== '200' && admempmonthsumpage_slice.status !=="") || admempmonthsumpage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserroradmmonthsum());
      
      navigate("/error")
    }

  },[admempmonthsumpage_slice.error,admempmonthsumpage_slice.status])
  useEffect(() => {
    console.log("resss");

    if (resized === true) {
      console.log("res");
      moreload();
    }
  }, [resized]);
  

  useEffect(() => {
    console.log(loginpage_slice.data["access"]);
    console.log("ss")
    if (loginpage_slice.data["access"] !== "" && admempmonthsumpage_slice.resultsdata === null) {
      console.log("s")
      calladmempmonthsum_get_api("null");
    }
  }, [loginpage_slice.data["access"]]);


  useEffect(() => {
    console.log("ss")
    if (admempmonthsumpage_slice.resultsdata !== null) {
      if (cleardata) {
        console.log(admempmonthsumpage_slice.resultsdata)
      calladmempmonthsum_print_api();
      }
    }

    
  }, [admempmonthsumpage_slice.resultsdata]);
  useEffect(() => {
    if (
      admempmonthsumpage_slice.resultsdata !== null &&
      (window.innerHeight >= document.documentElement.scrollHeight ||
        window.innerHeight >= document.documentElement.scrollHeight)
    ) {
      moreload();
    } else {
      setResized(false);
    }
  }, [admempmonthsumpage_slice.printdata]);

  const calladmempmonthsum_get_api = (nextlink) => {
    console.log("as")
    console.log(deptselected)
    dispatch(
      calladmempmonthsum_service([
        `${loginpage_slice.data["access"]}`,
        empid === "" ? "null" : empid,
        selectedMonth.getMonth() + 1,
        selectedMonth.getFullYear(),
        deptselected,
        atnstatusselected,
        nextlink === "null" ? "null"  :admempmonthsumpage_slice.next
      ])
    );
  };

  const calladmempmonthsum_print_api = () => {
    setPadding(40);
    dispatch(
      calladmempsumprint_service([
        `${loginpage_slice.data["access"]}`,
        selectedMonth.getMonth() + 1,
        selectedMonth.getFullYear(),
        deptselected,
      ])
    );
  };
  const calladminpanel_get_api = () => {
    dispatch(calladminpanel_service(`${loginpage_slice.data["access"]}`));
  };
  const moreload = () => {

    if (
      admempmonthsumpage_slice.loading === false &&
      admempmonthsumpage_slice.resultsdata.length < admempmonthsumpage_slice.count
    ) {
      console.log("1234567");

      calladmempmonthsum_get_api("notnull");

      console.log(document.documentElement.scrollHeight);
      console.log(window.innerHeight);
    } else {
      setResized(false);
      console.log("dd");
    }
  };
  

  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [deptselected, setDeptselected] = useState("HR & Admin");
  const [atnstatusselected, setAtnstatusselected] = useState("All");
  const [empid, setEmpid] = useState("");

  const newdeptselected = (event) => {
    console.log("dd")
    setEmpid("");
    setDeptselected(event.target.value);
  };
  const newatnstatusselected = (event) => {
    setAtnstatusselected(event.target.value);
  };

  const handleidchange = (event) => {
    if (event.target.value.trim() === "") {
      console.log(event.target.value);
      setEmpid("");
    } else {
      setEmpid(event.target.value);
    }
  };
  const handlesearchclick = () => {
    calladmempmonthsum_get_api("null");
  };

  const downloadpdf = useReactToPrint({
    content: () => pdfref.current,

    documentTitle: `Attendance_${deptselected}_${format(
      selectedMonth,
      "MMMM_yyyy"
    )}`,
  });
  
  const [padding, setPadding] = useState(40);
  const [checkone, setCheckone] = useState("");
  const [checktwo, setChecktwo] = useState("");
  const [checkthree, setCheckthree] = useState("");
  const [certify, setCertify] = useState("");
  const [approve, setApprove] = useState("");

  const [departments,setDepartments] = useState([]);
  useEffect(()=>{
    console.log("ss")
    if (loginpage_slice.data === null) {
      setDepartments([]);

    } else {
      console.log("ss")
      setDepartments(loginpage_slice.data.departments)
    }
  },[loginpage_slice.data])
 

  useEffect(() => {
    if (departments.length !== 0) {
      for (const q of departments) {
        if (q.department === deptselected) {
          setCheckone(q.checkbyone);
          setChecktwo(q.checkbytwo);
          setCheckthree(q.checkbythree);
          setCertify(q.certifyby);
          setApprove(q.approveby);
          break;
        }
        console.log("22");
        console.log(approve);
      }
    }
  }, [admempmonthsumpage_slice.resultsdata]);
  const [showPreview, setShowPreview] = useState(false);

  return (
    <div className="empmonthsumcontainer">
      {(admempmonthsumpage_slice.loading ||
        loginpage_slice.loading ||
        adminpanelpage_slice.loading) && (
        <div className="loader_login">
          <Loader_rotatelines />
        </div>
      )}

      <div className="empmnthsum">
          <div className="empmnthfilter">
            <div className="empsearchmonthsum">
              <span>Month: </span>
              <Datepicker
                selected={selectedMonth}
                onChange={(date) => setSelectedMonth(date)}
                maxDate={new Date()}
                closeOnScroll
                showMonthYearPicker
                displayFormat="MMMM YYYY"
                dateFormat="yyyy, MMMM"
                customInput={
                  <button className="monthpickerempmnth">
                    <div className="monthpickerdetailsempmnth">
                      {" "}
                      <FaCalendar />
                    </div>{" "}
                    {format(selectedMonth, "MMMM - yyyy")}
                  </button>
                }
              />
            </div>
            <div className="empsearchmonthsum">
              <span>Attendance status: </span>
              <select
                className="selectdeptmonthsum"
                value={atnstatusselected}
                onChange={newatnstatusselected}
              >
                <option>All</option>
                <option> Present</option>
                <option> In</option>
                <option> Late</option>
                <option> Leave</option>
                <option> Absent</option>
                <option> Remote</option>
                <option> Holiday</option>
              </select>
            </div>
            {departments !== null && <div className="empsearchmonthsum">
              <span>Department: </span>
              <select
                className="selectdeptmonthsum"
                value={deptselected}
                onChange={newdeptselected}
              >
                {departments.map((q, i) => (
                  <option key={i}> {q.department}</option>
                ))}
              </select>
            </div>}
            <div className="empsearchmonthsum">
              <span>Employee ID:</span>
              <input
              disabled={(admempmonthsumpage_slice.loading) ? true : false}
                value={empid}
                onChange={handleidchange}
                type="number"
                onWheel={(e) => e.target.blur()}
                onKeyDown={(event)=> {
                  if (event.key === "Enter") {
                    handlesearchclick()
                  }
                }}
                placeholder="Employee ID"
              />
            </div>
            <button
            disabled={(admempmonthsumpage_slice.loading) ? true : false}
              onClick={() => handlesearchclick()}
              className="searchbtnempmonthsum"
            >
              Search
            </button>
          </div>
          {(admempmonthsumpage_slice.resultsdata === null || admempmonthsumpage_slice.resultsdata.length === 0) ? (((err || (admempmonthsumpage_slice.resultsdata === null ? true : admempmonthsumpage_slice.resultsdata.length === 0)) && admempmonthsumpage_slice.loading === false) && <div className='nonefoundadmmonth'>
          <h3>{"No employee found!"}</h3>
        </div>) : (<div className="monthsumdetails">
            {admempmonthsumpage_slice.printloading === false ? (
              <button className="pdfbtnallemp" onClick={() => downloadpdf()}>
                Download PDF ({admempmonthsumpage_slice.resultsdata[0].dept})
              </button>
            ) : (
              <div className="pdfloader_login">
                <Loader_rotatelines />
              </div>
            )}
            {admempmonthsumpage_slice.printloading === false && <button
              className="searchprevallemp"
              onClick={() => setShowPreview((prev) => !prev)}
            >
              {showPreview ? "Hide" : "Show"} PDF Preview
            </button>}

            {admempmonthsumpage_slice.printdata !== null && (
              <div
                className="printwholediv"
                style={{ display: showPreview ? "flex" : "none" }}
              >
                <div className="printdetails" ref={pdfref} id="printpdfid">
                  <div className="printtop">
                    <div className="topline"></div>

                    <p className="printmonthp">
                      Month of{" "}
                      {format(getdateformatvalue(selectedMonth), "MMMM - yyyy")}
                    </p>
                    <p className="printdeptp">
                      Department of{" "}
                      <span className="printdeptspan">
                        {admempmonthsumpage_slice.printdata[0].emp_dept}
                      </span>
                    </p>

                    <table id="printtableid">
                      <tr className="monthsumtr">
                        <th className="monthsumth">SL NO</th>
                        <th className="monthsumth">Name</th>
                        <th className="monthsumth">Designation</th>
                        <th className="monthsumth">Late Date</th>
                        <th className="monthsumth">Late Time</th>
                        <th className="monthsumth">Absent Date</th>
                        <th className="monthsumth">Leave Date</th>
                        <th className="monthsumth">Remarks</th>
                      </tr>
                      {admempmonthsumpage_slice.printdata.map((q, i) => (
                        <tr className="monthsumtr" key={i}>
                          <td className="monthsumth">{i + 1}.</td>
                          <td className="monthsumth">{q.username}</td>
                          <td className="monthsumth">{q.desig}</td>
                          <td className="monthsumth">
                            {q.latedays === null
                              ? "No Late"
                              : q.latedays.replaceAll(",", ", ")}
                          </td>
                          <td className="monthsumth">
                            {q.latetime === null
                              ? "No Late"
                              : q.latetime.replaceAll(",", ", ")}
                          </td>
                          <td className="monthsumth">
                            {q.absentdays === null
                              ? "No absent"
                              : q.absentdays.replaceAll(",", ", ")}
                          </td>
                          <td className="monthsumth">
                            {q.leavedays === null
                              ? "No Leave"
                              : q.leavedays.replaceAll(",", ", ")}
                          </td>
                          <td className="monthsumth"></td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  <div
                    className="bottomprintdivs"
                    style={{ paddingTop: `${padding}px` }}
                  >
                    <div className="printbottom">
                      {checktwo !== "" && (
                        <div className="checkby3con">
                          <div className="checkby3"></div>
                          <p>{checktwo}</p>
                        </div>
                      )}
                      {checkthree !== "" && (
                        <div className="checkby3con ">
                          <div className="checkby3"></div>
                          <p>{checkthree}</p>
                        </div>
                      )}
                    </div>
                    <div className="printbottom">
                      {checkone !== "" && (
                        <div className="checkby3con check">
                          <div className="checkby3"></div>
                          <p>{checkone}</p>
                        </div>
                      )}

                      {certify !== "" && (
                        <div className="checkby3con cerity">
                          <div className="checkby3"></div>
                          <p>{certify}</p>
                        </div>
                      )}
                      {approve !== "" && (
                        <div className="checkby3con approve">
                          <div className="checkby3"></div>
                          <p>{approve}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="paddingbtndivs">
                  <button
                    className="paddingincallempadd"
                    onClick={() => setPadding((prev) => prev + 40)}
                  >
                    (+) padding
                  </button>
                  <button
                    className="paddingincallempminus"
                    onClick={() => setPadding((prev) => prev - 40)}
                  >
                    (-) padding
                  </button>
                </div>{" "}
              </div>
            )}

            {admempmonthsumpage_slice.resultsdata.map((q, i) => (
              <div className="monthsumempsinglecontainer">
                <div className="monthsumempsinglecontainerhead">
                  <p>
                    Employee {i + 1} of {admempmonthsumpage_slice.count}
                  </p>
                  <p>
                    {format(
                      getdateformatvalue(
                        q.attendance.length !== 0
                          ? q.attendance[0].wdate
                          : selectedMonth
                      ),
                      "MMMM - yyyy"
                    )}
                  </p>
                </div>
                <div className="topdetailsmonthsum">
                  <div className="userdetailsmonthsum">
                    <div className="imgholdermonthsum">
                      <img
                        className={`userimgmonthsum ${q.sex}`}
                        src={q.photo}
                        alt=""
                      />
                    </div>
                    <div className="userinfomonthsum">
                      <h3>{q.ename}</h3>
                      <h4>ID: {q.areg_sno}</h4>
                      <h4>Designation: {q.designation}</h4>
                      <h4>Department: {q.dept}</h4>
                      <h4>
                        Status:{" "}
                        <span
                          style={{
                            color: `${q.empstatus === "W" ? "green" : "red"}`,
                          }}
                        >
                          {q.empstatus === "W" ? "Active" : "Inactive"}
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div className="atndncinfomonthsum">
                    <div className="atndncinfochildmonthsum">
                      <p>
                        <span
                          className="atndncinfochildhighlightmonthsum"
                          style={{ background: "green" }}
                        ></span>{" "}
                        Present: {q.presentcount} days
                      </p>
                      <p>
                        <span
                          className="atndncinfochildhighlightmonthsum"
                          style={{ background: "orange" }}
                        ></span>{" "}
                        Late: {q.latecount} days
                      </p>
                      <p>
                        <span
                          className="atndncinfochildhighlightmonthsum"
                          style={{ background: "red" }}
                        ></span>{" "}
                        Absent: {q.absentcount} days
                      </p>
                      <p>
                        <span
                          className="atndncinfochildhighlightmonthsum"
                          style={{ background: "purple" }}
                        ></span>{" "}
                        Leave taken: {q.leavecount} days
                      </p>
                      <p>
                        <span
                          className="atndncinfochildhighlightmonthsum"
                          style={{ background: "darkcyan" }}
                        ></span>{" "}
                        Remote: {q.remotecount} days
                      </p>
                    </div>
                  </div>
                </div>

                <Atndncdetailselement data={q.attendance} />
              </div>
            ))}
            
          </div>)}
        </div>
      
    </div>
  );
};

export default Empmonthsum;
