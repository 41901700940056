import React, { useEffect, useRef, useState } from 'react'
import "./Allemp.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../../Redux/Slice/Loginslice';
import { calladmallemp_working_service, handlesetstatuserroradmallemp } from '../../../Redux/Slice/Adminpanelslices/Admallempslice';
import { calladminpanel_service } from '../../../Redux/Slice/Adminpanelslices/Adminpanelslice';
import { Loader_rotatelines, getdateformatvalue } from '../../../App';
const Allemp = () => {
  const navigate = useNavigate();
  const loginpage_slice = useSelector((state) => state.login);
  const adminpanelpage_slice = useSelector((state) => state.adminpanel);
  const scrollallempdivref = useRef();
  const [deptselected,setDeptselected] = useState("All");
  const [empid,setEmpid] = useState("");
  const [tabchange,setTabchange] = useState(false);
  
  const [resized, setResized] = useState(false);
  
  

  const admallemppage_slice = useSelector((state) => state.admallemp);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserroradmallemp())
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (admallemppage_slice.resultsdata !== null) {
        calladmallemp_get_api("null")

      }
      
    }
    
    
  },[])
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(admallemppage_slice.error,admallemppage_slice.status)
    
    if (admallemppage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (admallemppage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    } else if (admallemppage_slice.error === "noemp") {
      console.log("ss")
      dispatch(handlesetstatuserroradmallemp())
      

    }  else if ((admallemppage_slice.status !== '200' && admallemppage_slice.status !=="") || admallemppage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserroradmallemp())
      
      navigate("/error")
    }

  },[admallemppage_slice.error,admallemppage_slice.status])
  const [inner,setInner] = useState(0);
  const [inner2,setInner2] = useState(0);
  const [inner3,setInner3] = useState(0);
  const handlescrollallemp = (event)=> {
    setInner(event.currentTarget.offsetHeight);
    setInner2(event.currentTarget.scrollTop);
    setInner3(event.currentTarget.scrollHeight);

    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop + 40 >=
      event.currentTarget.scrollHeight
    ) {
      if (resized === false) {
        setResized((prev) => !prev);
        console.log("oks");
      } else {
        console.log("ss");
      }
    }
    console.log(event.currentTarget.scrollTop)
  }
  useEffect(() => {
    console.log("resss");

    if (resized === true) {
      console.log("res");
      moreload();
    }
  }, [resized]);
  const [departmentslist,setDepartmentslist] = useState(["All",])
  const [departments,setDepartments] = useState([]);
  useEffect(()=>{
    if (loginpage_slice.data === null) {
      setDepartments([]);
      setDepartmentslist(["All"])

    } else {
      setDepartments(loginpage_slice.data.departments)
      
      
    }
    
  },[loginpage_slice.data])
  useEffect(()=>{
    console.log(departments)
    if (departments !== null) {
      const c = departments.map((q,i)=>q.department)
      
      setDepartmentslist([...departmentslist,...Object.values(c)])
    }
  },[departments])

  
  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && admallemppage_slice.resultsdata === null) {
      
      
      
      
        
        
      

      calladmallemp_get_api("null")  }
  },[loginpage_slice.data['access']])

  



  const [tabwork,setTabwork] = useState("working");
  
  useEffect(()=> {
    if (loginpage_slice.data['access'] !== '' && tabchange === true) {
      calladmallemp_get_api("null")
      if (tabchange === true) {
        setTabchange(q=>!q)
      }
    
    }
  
  },[tabchange])
  useEffect(() => {
    setResized(false);
  }, [admallemppage_slice.resultsdata]);





  const calladmallemp_get_api = (nextlinkpass)=> {
    if (loginpage_slice.data['access'] !== '' && admallemppage_slice.loading === false) {
    if (tabwork === "working") {
      dispatch(calladmallemp_working_service([`${loginpage_slice.data['access']}`,empid === "" ? "null" : empid,deptselected,"usergetempworking",nextlinkpass === "null" ? "null" : admallemppage_slice.next,]))
    } else {
      dispatch(calladmallemp_working_service([`${loginpage_slice.data['access']}`,empid === "" ? "null" : empid,deptselected,"usergetempleft",nextlinkpass === "null" ? "null" : admallemppage_slice.next,]))

    }
  }
  
  }
  /* const calladminpanel_get_api = ()=> {
    dispatch(calladminpanel_service(`${loginpage_slice.data['access']}`))
  
  } */
  const moreload = () => {
    console.log(admallemppage_slice.next);

    if (
      admallemppage_slice.loading === false &&
      admallemppage_slice.resultsdata.length < admallemppage_slice.count
    ) {
      console.log("1234567");

      calladmallemp_get_api("notnull");

      console.log(document.documentElement.scrollHeight);
      console.log(window.innerHeight);
    } else {
      setResized(false);
      console.log("dd");
    }
  };







































  
  
  const handletabclick = (status)=> {
    
    if (status === "working") {
      setTabwork("working")
    } else {
      setTabwork("left")
    }
    if (tabchange === false) {
      setTabchange(q=>!q)
    }
  }
  const newdeptselected = (event)=> {
    setDeptselected(event.target.value);
  }
  const handleidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setEmpid("")
    } else {
      setEmpid(event.target.value)
    }
    
  }
  
  return (
    <div>
      {(admallemppage_slice.loading || loginpage_slice.loading || adminpanelpage_slice.loading) && <div className="loader_login"><Loader_rotatelines /></div>}
      <div className='allemp'>
      <h3 style={{textAlign:"center"}}>All Employee Details</h3>
      <div className="filterdivallemp">
        <div className="empsearchallemp">
          <span>Employee status:</span>
          <div className="empstatustabs">
            <button disabled={admallemppage_slice.loading} className={`tabbuttonsallemp ${tabwork === "working" ? "active":""}`} onClick={()=>handletabclick("working")}>working</button>
            <button disabled={admallemppage_slice.loading} className={`tabbuttonsallemp ${tabwork === "left" ? "active":""}`} onClick={()=>handletabclick("left")}>left</button>
          </div>
        </div>
        <div className="empsearchallemp">
          <span>Department: </span>
          <select className="selectdeptallemp" value={deptselected} onChange={newdeptselected}>
          {departmentslist.map((q,i) => (
                    <option key={i}> {q}</option>
                  ))}
              </select>
        </div>
        <div className="empsearchallemp">
          <span>Employee ID:</span>
          <input value={empid} onChange={handleidchange} type="number" onWheel={(e) => e.target.blur()} placeholder='Employee ID' />
        </div>
        <button disabled={admallemppage_slice.loading} onClick={()=>calladmallemp_get_api("null")} className="searchbtnallemp">Search</button>

      </div>
      
      
      
      
      
      {(admallemppage_slice.resultsdata === null || tabwork !== admallemppage_slice.currenttab) ? (admallemppage_slice.loading === false && <div className='nonefoundallemp'>
          <h3>No employee found!</h3>
        </div>) :
        
        <div>
          <h3 style={{textAlign:"center",background:"rgba(58, 160, 255, 0.799)",color:"white",padding:"5px",borderRadius:"0px"}}>{admallemppage_slice.count} {tabwork === "working" ? "working" : "left"} employees</h3>
          
          <div className="allempdetails" ref={scrollallempdivref} onScroll={handlescrollallemp}>
      <div className="allemphead">
                <p className='allempheaditem'>SL</p>
                <p className='allempheaditem'>Employee ID</p>
                <p className='allempheaditem'>Name</p>
                <p className='allempheaditem'>Department</p>
                <p className='allempheaditem'>Designation</p>
                <p className='allempheaditem'>Designation seq.</p>
                <p className='allempheaditem'>Joining date</p>
                <p className='allempheaditem'>Teamleader</p>
                <p className='allempheaditem'>Qualification</p>
                <p className='allempheaditem'>Phone</p>
                <p className='allempheaditem'>Email</p>
                <p className='allempheaditem'>Address</p>
                <p className='allempheaditem'>Birthdate</p>
                <p className='allempheaditem'>Gender</p>
                <p className='allempheaditem'>Blood group</p>
                <p className='allempheaditem'>Father's name</p>
                <p className='allempheaditem'>Casual leave bl.</p>
                <p className='allempheaditem'>Sick leave bl.</p>
                <p className='allempheaditem'>Mat/Pat leave bl.</p>
                <p className='allempheaditem'>Earned leave bl.</p>
                <p className='allempheaditem'>Employee status</p>
                {tabwork !== "working" && <p className='allempheaditem'>Leaving reason</p>}
                {tabwork !== "working" && <p className='allempheaditem'>Leaving date</p>}
            </div>
            {admallemppage_slice.resultsdata.map((q,i) => (
                <div key={i} className="allempdetailsinside" >
                <p className='allempdetailsitem'>{i + 1}</p>
                <p className='allempdetailsitem'>{q.areg_sno}</p>
                <p className='allempdetailsitem'>{q.ename}</p>
                <p className='allempdetailsitem'>{q.dept}</p>
                <p className='allempdetailsitem'>{q.designation}</p>
                <p className='allempdetailsitem'>{q.desig_seq}</p>
                <p className='allempdetailsitem'>{getdateformatvalue(q.doj)}</p>
                <p className='allempdetailsitem'>{q.teamleadername}</p>
                <p className='allempdetailsitem'>{q.qualification}</p>
                <p className='allempdetailsitem'>{q.phone}</p>
                <p className='allempdetailsitem'>{q.email}</p>
                <p className='allempdetailsitem'>{q.addr1}</p>
                <p className='allempdetailsitem'>{getdateformatvalue(q.dob)}</p>
                <p className='allempdetailsitem'>{q.sex}</p>
                <p className='allempdetailsitem'>{q.bloodgroup}</p>
                <p className='allempdetailsitem'>{q.fhgname}</p>
                <p className='allempdetailsitem'>{q.casualleave}</p>
                <p className='allempdetailsitem'>{q.sickleave}</p>
                <p className='allempdetailsitem'>{q.maternalleave}</p>
                <p className='allempdetailsitem'>{q.earnedleave}</p>
                <p className='allempdetailsitem'>{q.empstatus === "W" ? "Working" : "Left"}</p>
                {tabwork !== "working" && <p className='allempdetailsitem'>{q.reason}</p>}
                {tabwork !== "working" && <p className='allempdetailsitem'>{getdateformatvalue(q.dol)}</p>}
            </div>
            ))} </div>
            </div>}
            
        
        
    </div>
      
    </div>
  )
}

export default Allemp

