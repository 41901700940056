



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";


export const calladmemptl_service = createAsyncThunk("calladmemptl", async (data) => {
    console.log(data)
    
    const response = await fetch(data[3] !== "null" ? data[3] : `${myport}teamleadersget/${data[1]}/${data[2]}/`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[3]];
})




export const callupdate_emptl_service = createAsyncThunk("callupdateemptl", async (data) => {
    console.log(`${myport}userlogin_get/`)
    const response = await fetch(`${myport}updateteamleader/${data[1]}/${data[2]}/`,{method:'PUT',headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include"
      ,});
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status,data[1],data[2]];
})

export const admemptlSlice = createSlice({
    name: "admemptl",
    initialState: {
        count:0,
        
        next: "",
        loading: false,
        status: "",
        updatestatus: "",
        error: null,
        resultsdata:null,
        admupdateemptlpostresponsebody: '',
        updateloading: false,
        updateerror: null,
        

      },
      reducers: {
        handlesetstatuserroradmemptl(state,action) {
            state.resultsdata = null;
            state.next = "";
            state.count = 0;
            state.updateloading = false;
            console.log(state.status)
            console.log(state.error)
            state.admupdateemptlpostresponsebody = '';
            
            state.error = null;
            state.updateerror = null;
            state.status = ""
            state.updatestatus = ""
          },
        handlesetstatuserroradmemptlupdate(state,action) {
           
            state.updateloading = false;
            state.admupdateemptlpostresponsebody = '';
            state.updateerror = null;
            state.updatestatus = ""
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calladmemptl_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladmemptl_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            }  else if (action.payload[0] === "noemp" || action.payload[0] === "notl" || action.payload[0] === "noemportl") {
                state.error = action.payload[0]
                console.log("ss")
                state.resultsdata = null;
      
            }else if (action.payload[1] === 200) {
                state.error = null;
            
            state.next = action.payload[0].next === null ? "null" : action.payload[0].next;
            state.count = action.payload[0].count;

            if (action.payload[2] === "null") {
                state.resultsdata = action.payload[0].results;
           
            } else {
                console.log(state.resultsdata.length)
                state.resultsdata = [...state.resultsdata, ...action.payload[0].results]
                console.log(state.resultsdata.length)
           
            }
        } else {
            state.error = null;
        }
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
        });
        builder.addCase(calladmemptl_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });














        builder.addCase(callupdate_emptl_service.pending, (state)=> {
            state.admupdateemptlpostresponsebody = '';
            state.updateloading = true;
            state.updateerror = null;
        });
        builder.addCase(callupdate_emptl_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.updateerror = "alreadyloggedout"

            }  else if (action.payload[0] === "noemp" || action.payload[0] === "notl" || action.payload[0] === "noemportl") {
                state.updateerror = action.payload[0]
                console.log("ss")
                state.admupdateemptlpostresponsebody = '';
      
            } else if (action.payload[1] === 200) {
                
                    /* if (state.resultsdata.length > 0) {
                        state.resultsdata.map((i,q) => {
                            console.log(i["eno"])
                            console.log(`${action.payload[2]}`)
                            if (`${i["areg_sno"]}` === `${action.payload[2]}`) {
                                
                                console.log(state.resultsdata[q]["areg_sno"])
                                state.resultsdata[q]["teamleadersno"] = `${action.payload[3]}`
                                console.log(state.resultsdata[q]["areg_sno"])
                            }
                        })
                    } */
                    
                
            
           
            state.updateerror = null;

           } else {
            state.updateerror = null;

           }
           state.admupdateemptlpostresponsebody = `${action.payload[0]}`
           
            
            
            state.updatestatus = `${action.payload[1]}`;
            state.updateloading = false;
        });
        builder.addCase(callupdate_emptl_service.rejected, (state,action) => {
            state.updateloading = false;
            state.updateerror = action.error.message;
        });
      },
    
});

export const { handlesetstatuserroradmemptl,handlesetstatuserroradmemptlupdate } = admemptlSlice.actions
export default admemptlSlice.reducer