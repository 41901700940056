







import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";


export const calladmempsumprint_service = createAsyncThunk("calladmempsumprint", async (data) => {
   
    const dept = encodeURIComponent(data[3])
    
    const response = await fetch(`${myport}monthly_atndnc_summary_get/${data[1]}/${data[2]}/?dept=${dept}`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})
//monthly_atndnc_summary_get
export const calladmempmonthsum_service = createAsyncThunk("calladmempmonthsum", async (data) => {
    console.log(encodeURIComponent(data[4]))
    const dept = encodeURIComponent(data[4])
    console.log(data[6])
    
    const response = await fetch(data[6] !== "null" ? data[6] : `${myport}adminallmyemployees_attendance_get/${data[1]}/${data[2]}/${data[3]}/${data[5]}/?dept=${dept}`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[6]];
})

export const admempmonthsumSlice = createSlice({
    name: "admempmonthsum",
    initialState: {
        count:0,
        next: "",
        loading: false,
        status: "",
        error: null,
        resultsdata:null,
        printloading: false,
        printdata:null,

      },
      reducers: {
        handlesetstatuserroradmmonthsum(state,action) {
            state.resultsdata = null;
            
            state.next = "";
            state.count = 0;
            state.printdata = null;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calladmempmonthsum_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladmempmonthsum_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[0] === "noemp") {
                state.error = "noemp"
                console.log("ss")
                state.resultsdata = null;
      
            } else if (action.payload[1] === 200) {
                state.error = null;
            
            
            
            state.next = action.payload[0].next === null ? "null" : action.payload[0].next;
            state.count = action.payload[0].count;

            if (action.payload[2] === "null") {
                state.resultsdata = action.payload[0].results;
                console.log(state.resultsdata)
           
            }  else {
                console.log(state.resultsdata)
                state.resultsdata = [...state.resultsdata, ...action.payload[0].results]
                console.log(state.resultsdata.length)
           
            }
        } else {
            state.error = null;
        }
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            
        });
        builder.addCase(calladmempmonthsum_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });




        builder.addCase(calladmempsumprint_service.pending, (state)=> {
            state.printloading = true;
            state.error = null;
        });
        builder.addCase(calladmempsumprint_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                state.error = null;
            state.printdata = action.payload[0];
        } else {
            state.error = null;
            
        }
        state.status = `${action.payload[1]}`;
        state.printloading = false;
        });
        builder.addCase(calladmempsumprint_service.rejected, (state,action) => {
            state.printloading = false;
            state.error = action.error.message;
            
        });
      },
    
});

export const { handlesetstatuserroradmmonthsum } = admempmonthsumSlice.actions
export default admempmonthsumSlice.reducer