







import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";


//monthly_atndnc_summary_get
export const callmyempmonthsum_service = createAsyncThunk("callmyempmonthsum", async (data) => {
    console.log("33")
    
    
    const response = await fetch(data[5] !== "null" ? data[5] : `${myport}allmyemployees_attendance_get/${data[1]}/${data[2]}/${data[3]}/${data[4]}/?page=1`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[5]];
})

export const myempmonthsumSlice = createSlice({
    name: "myempmonthsum",
    initialState: {
        count:0,
        next: "",
        loading: false,
        status: "",
        error: null,
        resultsdata:null,

      },
      reducers: {
        handlesetstatuserrormyempmonth(state,action) {
            state.resultsdata = null;
            state.next = "";
            state.count = 0;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },
      },
      extraReducers: (builder) => {
        builder.addCase(callmyempmonthsum_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callmyempmonthsum_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            }  else if (action.payload[0] === "noemp") {
                state.error = "noemp"
                console.log("ss")
                state.resultsdata = null;
      
            } else if (action.payload[1] === 200) {
                
                
            state.next = action.payload[0].next === null ? "null" : action.payload[0].next;
            state.count = action.payload[0].count;

            if (action.payload[2] === "null") {
                console.log("data")
                state.resultsdata = action.payload[0].results;
           
            } else {
                console.log(state.resultsdata.length)
                state.resultsdata = [...state.resultsdata, ...action.payload[0].results]
                console.log(state.resultsdata.length)
           
            }
            state.error = null;
        } else {
            state.error = null;
        }
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
        });
        builder.addCase(callmyempmonthsum_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserrormyempmonth } = myempmonthsumSlice.actions
export default myempmonthsumSlice.reducer