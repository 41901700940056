import React, { useEffect, useState } from "react";
import "./Dtsheet.css";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { callupdateemp_adm_service } from "../../Redux/Slice/Adminpanelslices/Modifyempslice";
import { Loader_rotatelines, getdateformatvaluenullstr } from "../../App";
import swal from "sweetalert";
const Dtsheet = ({ isdtsheetopen, setIsdtsheetopen, admmodemppage_slice }) => {
  const loginpage_slice = useSelector((state) => state.login);
  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    if (loginpage_slice.data === null) {
      setDepartments([]);
    }
  }, [loginpage_slice.data]);
  useEffect(() => {
    if (loginpage_slice.data !== null) {
      setDepartments(loginpage_slice.data.departments);
    }
  }, []);

  const [namevalue, setNamevalue] = useState(
    admmodemppage_slice.data.ename
  );
  
  const [fathervalue, setFathervalue] = useState(
    admmodemppage_slice.data.fhgname
  );
  const [mobilevalue, setMobilevalue] = useState(
    admmodemppage_slice.data.phone
  );
  const [mailvalue, setMailvalue] = useState(
    admmodemppage_slice.data.email
  );
  const [adrvalue, setAdrvalue] = useState(admmodemppage_slice.data.addr1);
  const [bloodvalue, setBloodvalue] = useState(
    admmodemppage_slice.data.bloodgroup
  );
  const [qualivalue, setQualivalue] = useState(
    admmodemppage_slice.data.qualification
  );
  const [deptselected, setDeptselected] = useState(
    admmodemppage_slice.data.dept
  );
  const [desigvalue, setDesigvalue] = useState(
    admmodemppage_slice.data.designation
  );
  const [desigseqvalue, setDesigseqvalue] = useState(
    admmodemppage_slice.data.desig_seq
  );
  const [birthvalue, setBirthvalue] = useState(
    getdateformatvaluenullstr(admmodemppage_slice.data.dob)
  );
  const [joinvalue, setJoinvalue] = useState(
    getdateformatvaluenullstr(admmodemppage_slice.data.doj)
  );
  const [genderselected, setGenderselected] = useState(
    admmodemppage_slice.data.sex === "M"
      ? "Male"
      : admmodemppage_slice.data.sex === "F"
      ? "Female"
      : "Others"
  );
  const [empstatus, setEmpstatus] = useState(
    admmodemppage_slice.data.empstatus === "W" ? "Working" : "Left"
  );
  const [empleftrsnvalue, setEmpleftrsnvalue] = useState(
    admmodemppage_slice.data.reason
  );
  const [empleftdatevalue, setEmpleftdatevalue] = useState(
    getdateformatvaluenullstr(admmodemppage_slice.data.dol)
  );
  const [imgvalue, setImgvalue] = useState("");

  const [previewUrl, setPreviewUrl] = useState(
    admmodemppage_slice.data.photo
  );
  const [modpageerror,setModpageerror] = useState(null)
  useEffect(()=>{
    console.log("s")
      if (modpageerror === "inputempty") {
        console.log("ss")
        swal("Some required fields are empty!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (modpageerror === "empworl") {
        console.log("ss")
        swal("Please fill employee left reason and date according to their status!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      }
      setModpageerror(null)
    
    
  },[modpageerror])
  

  useEffect(()=>{
    console.log("s")
    if (updatepressed === true) {
      if (admmodemppage_slice.error === "failed") {
        console.log("ss")
        swal("Update failed. Please insert valid inputs to update!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      }
    }
    if (updatepressed === true) {
      setUpdatepressed(q=>!q)
     }
     
  },[admmodemppage_slice.error])

  useEffect(() => {
    console.log("dtsheet", isdtsheetopen);
    
      setNamevalue(admmodemppage_slice.data.ename);
      setFathervalue(admmodemppage_slice.data.fhgname);
      setMobilevalue(admmodemppage_slice.data.phone);
      setMailvalue(admmodemppage_slice.data.email);
      setAdrvalue(admmodemppage_slice.data.addr1);
      setBloodvalue(admmodemppage_slice.data.bloodgroup);
      setQualivalue(admmodemppage_slice.data.qualification);
      setDeptselected(admmodemppage_slice.data.dept);
      setDesigvalue(admmodemppage_slice.data.designation);
      setDesigseqvalue(admmodemppage_slice.data.desig_seq);
      setBirthvalue(getdateformatvaluenullstr(admmodemppage_slice.data.dob));
      setJoinvalue(getdateformatvaluenullstr(admmodemppage_slice.data.doj));
      setGenderselected(
        admmodemppage_slice.data.sex === "M"
          ? "Male"
          : admmodemppage_slice.data.sex === "F"
          ? "Female"
          : "Others"
      );
      setEmpstatus(
        admmodemppage_slice.data.empstatus === "W" ? "Working" : "Left"
      );
      setEmpleftrsnvalue(admmodemppage_slice.data.reason);
      setEmpleftdatevalue(
        getdateformatvaluenullstr(admmodemppage_slice.data.dol)
      );
      setImgvalue("");

      setPreviewUrl(admmodemppage_slice.data.photo);
    
  }, [isdtsheetopen]);

  /* const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) { // Validate file type
        //setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImgvalue(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        //setSelectedFile(null); // Clear preview if invalid file
        setPreviewUrl(null);
        alert('Please select an image file.');
      }
    }; */

  const namechangehandle = (event) => {
    setNamevalue(event.target.value);
  };
  const fatherchangehandle = (event) => {
    setFathervalue(event.target.value);
  };
  const mobilechangehandle = (event) => {
    setMobilevalue(event.target.value.trim());
  };
  const mailchangehandle = (event) => {
    setMailvalue(event.target.value.trim());
  };
  const adrchangehandle = (event) => {
    setAdrvalue(event.target.value);
  };
  const bloodchangehandle = (event) => {
    setBloodvalue(event.target.value.trim());
  };
  const qualichangehandle = (event) => {
    setQualivalue(event.target.value);
  };
  const newdeptselected = (event) => {
    setDeptselected(event.target.value);
  };
  const desigchangehandle = (event) => {
    setDesigvalue(event.target.value);
  };
  const desigseqchangehandle = (event) => {
    console.log(event.target.value)
    setDesigseqvalue(event.target.value);
  };
  const dobchangehandle = (event) => {
    if (event.target.value === "") {
      setBirthvalue(null)

    } else {
      setBirthvalue(getdateformatvaluenullstr(event.target.value));
    }
  };
  const dojchangehandle = (event) => {
    if (event.target.value === "") {
      setJoinvalue(null)

    } else {
      setJoinvalue(getdateformatvaluenullstr(event.target.value));
    }
    
  };

  const handlegenderselect = (event) => {
    setGenderselected(event.target.value);
  };
  const handleempstatusselect = (event) => {
    setEmpstatus(event.target.value);
  };
  const empleftrsnchangehandle = (event) => {
    setEmpleftrsnvalue(event.target.value);
  };
  const dolchangehandle = (event) => {
    console.log(event.target.value)
    if (event.target.value === "") {
      setEmpleftdatevalue(null)

    } else {
      setEmpleftdatevalue(getdateformatvaluenullstr(event.target.value));
    }
  };
  const photochangehandle = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImgvalue(file); // Validate file type
      //setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      //setSelectedFile(null); // Clear preview if invalid file
      setPreviewUrl(null);
      setImgvalue("");
      alert("Please select an image file.");
    }
  };

  const dispatch = useDispatch();
  const [updatepressed,setUpdatepressed] = useState(false)

  const callupdateemp_put_api = () => {
     console.log(admmodemppage_slice.data["phone"] === "");
     if (updatepressed === false) {
      setUpdatepressed(q=>!q)
     }
     console.log(empstatus,empleftdatevalue,empleftrsnvalue)
     
     if ((namevalue !== null && namevalue !== "") || (joinvalue !== null && joinvalue !== "") || (desigvalue !== null && desigvalue !== "")) {
      
      
      if (
        (namevalue === "" || namevalue === null ? namevalue : namevalue.trim()) === admmodemppage_slice.data["ename"] &&
        (fathervalue === "" || fathervalue === null ? fathervalue : fathervalue.trim()) === admmodemppage_slice.data["fhgname"] &&
        (mobilevalue === "" || mobilevalue === null ? mobilevalue : mobilevalue.trim()) === admmodemppage_slice.data["phone"] &&
        (mailvalue === "" || mailvalue === null ? mailvalue : mailvalue.trim()) === admmodemppage_slice.data["email"] &&
        (adrvalue === "" || adrvalue === null ? adrvalue : adrvalue.trim()) === admmodemppage_slice.data["addr1"] &&
        (bloodvalue === "" || bloodvalue === null ? bloodvalue : bloodvalue.trim()) === admmodemppage_slice.data["bloodgroup"] &&
        (qualivalue === "" || qualivalue === null ? qualivalue : qualivalue.trim()) === admmodemppage_slice.data["qualification"] &&
        deptselected === admmodemppage_slice.data["dept"] &&
        (desigvalue === "" || desigvalue === null ? desigvalue : desigvalue.trim()) === admmodemppage_slice.data["designation"] &&
        (desigseqvalue === "" || desigseqvalue === null ? desigseqvalue : desigseqvalue) === admmodemppage_slice.data["desig_seq"] &&
        getdateformatvaluenullstr(birthvalue) === getdateformatvaluenullstr(admmodemppage_slice.data["dob"]) &&
        getdateformatvaluenullstr(joinvalue) === getdateformatvaluenullstr(admmodemppage_slice.data["doj"]) &&
        `${genderselected === "Male" ? "M" : genderselected === "Female" ? "F" : "O"}` === admmodemppage_slice.data["sex"]  &&
        `${empstatus === "Working" ? "W" : "L"}` === admmodemppage_slice.data["empstatus"] &&
        (empleftrsnvalue === "" || empleftrsnvalue === null ? empleftrsnvalue : empleftrsnvalue.trim()) === admmodemppage_slice.data["reason"] &&
        getdateformatvaluenullstr(empleftdatevalue) === getdateformatvaluenullstr(admmodemppage_slice.data["dol"]) &&
        previewUrl === admmodemppage_slice.data["photo"]
      ) {
        console.log("hola",mailvalue);
        console.log(admmodemppage_slice.data["email"]);
        setIsdtsheetopen((q) => !q);
      } else {
        console.log(bloodvalue === admmodemppage_slice.data["bloodgroup"],"myblood")
        
        var data = {};
        if (namevalue !== admmodemppage_slice.data["ename"]) {
          data = { ...data, ...{ ename: namevalue.trim() } };
        }
        if (fathervalue !== admmodemppage_slice.data["fhgname"]) {
          data = { ...data, ...{ fhgname: fathervalue.trim() } };
        }
        if (mobilevalue !== admmodemppage_slice.data["phone"]) {
          data = { ...data, ...{ phone: mobilevalue.trim() } };
        }
        if (mailvalue !== admmodemppage_slice.data["email"]) {
          data = { ...data, ...{ email: mailvalue.trim() } };
        }
        if (adrvalue !== admmodemppage_slice.data["addr1"]) {
          data = { ...data, ...{ addr1: adrvalue.trim() } };
        }
        
        if (bloodvalue !== admmodemppage_slice.data["bloodgroup"]) {
          data = { ...data, ...{ bloodgroup: bloodvalue.trim() } };
        }
        if (qualivalue !== admmodemppage_slice.data["qualification"]) {
          data = { ...data, ...{ qualification: qualivalue.trim() } };
        }
        if (deptselected !== admmodemppage_slice.data["dept"]) {
          data = { ...data, ...{ dept: deptselected.trim() } };
        }
        if (desigvalue !== admmodemppage_slice.data["designation"]) {
          data = { ...data, ...{ designation: desigvalue.trim() } };
        }
        console.log(desigseqvalue)
        if (desigseqvalue !== admmodemppage_slice.data["desig_seq"]) {
          data = { ...data, ...{ desig_seq: desigseqvalue } };
        }
        if (getdateformatvaluenullstr(birthvalue) !== getdateformatvaluenullstr(admmodemppage_slice.data["dob"])) {
          console.log("saf12",getdateformatvaluenullstr(birthvalue),getdateformatvaluenullstr(admmodemppage_slice.data["dob"]),`${getdateformatvaluenullstr(birthvalue)} 00:00:00`)
          data = { ...data, ...{ dob: birthvalue !== null ? `${getdateformatvaluenullstr(birthvalue)} 00:00:00` : "0000-00-00 00:00:00" }};
          console.log("234ok",data)
        }
        if (getdateformatvaluenullstr(joinvalue) !== getdateformatvaluenullstr(admmodemppage_slice.data["doj"])) {
          console.log("data")
          data = { ...data, ...{ doj: joinvalue !== null ? `${getdateformatvaluenullstr(joinvalue)} 00:00:00` : "0000-00-00 00:00:00" }};
        }
        if (`${genderselected === "Male" ? "M" : genderselected === "Female" ? "F" : "O"}` !== admmodemppage_slice.data["sex"]) {
          console.log("data3")
          data = { ...data, ...{ sex: `${genderselected === "Male" ? "M" : genderselected === "Female" ? "F" : "O"}` } };
        }
        console.log("data4")
        if (`${empstatus.trim() === "Working" ? "W" : "L"}` !== admmodemppage_slice.data["empstatus"]) {
          
          data = { ...data, ...{ empstatus: empstatus.trim() === "Working" ? "W" : "L" } };
        }
        console.log("data5")
        if (empleftrsnvalue !== admmodemppage_slice.data["reason"]) {
          
          
          data = { ...data, ...{ reason: empleftrsnvalue.trim() } };
        }
        console.log("leftcheck",`${getdateformatvaluenullstr(empleftdatevalue)}`, `${getdateformatvaluenullstr(admmodemppage_slice.data["dol"])}`)
        if (getdateformatvaluenullstr(empleftdatevalue) !== getdateformatvaluenullstr(admmodemppage_slice.data["dol"])) {
          console.log("data2check")
          
          data = { ...data, ...{ dol: empleftdatevalue !== null ? `${getdateformatvaluenullstr(empleftdatevalue)} 00:00:00` : "0000-00-00 00:00:00" }};
          console.log("data2done")
        }
        console.log(data)
  
        if (imgvalue !== "") {
          
          data = { ...data, ...{ photo: imgvalue } };
        }
        console.log(data)
        console.log(namevalue)
        if (empstatus === "Working") {
          console.log(empleftdatevalue)
          if (empleftdatevalue === null && (empleftrsnvalue === "" || empleftrsnvalue === null)) {
            dispatch(
              callupdateemp_adm_service([
                `${loginpage_slice.data["access"]}`,
                data,
                admmodemppage_slice.data.areg_sno,
              ])
            );
            
    
          } else {
            console.log("ss")
            
            setModpageerror("empworl")
          }
         } else if (empstatus === "Left") {
          if (empleftdatevalue !== null && (empleftrsnvalue !== "" || empleftrsnvalue !== null)) {
            console.log(empleftdatevalue)
            dispatch(
              callupdateemp_adm_service([
                `${loginpage_slice.data["access"]}`,
                data,
                admmodemppage_slice.data.areg_sno,
              ])
            );
    
          } else {
            console.log("ss")
            setModpageerror("empworl")
          }
         }
          
  
        
      }
        
     } else {
      console.log(namevalue,joinvalue,desigvalue)
      
      setModpageerror("inputempty")
     }
    
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "110%",
            maxWidth: "800px", // Set your width here
          },
        },
      }}
      open={isdtsheetopen}
    >
      <div className="editdetailsdivoverlaymodemp">
        <div className="editdetailsdivmodemp">
          <div className="sheetheadmodemp">
            <button
              className="cancelbuttonsheetmodemp"
              onClick={() => {
                setIsdtsheetopen((q) => !q);
              }}
            >
              Cancel
            </button>
            <h3>Edit employee details</h3>
          </div>

          <div className="modempdtsheetgridcontainer">
            {admmodemppage_slice.loading && (
              <div className="loader_login">
                <Loader_rotatelines />
              </div>
            )}
            <div className="userinfogridmodempdtsheet">
              <div className="modempdtsheetitem">
                <span>ID</span>
                <input
                  readOnly
                  type="text"
                  placeholder=""
                  value={admmodemppage_slice.data.areg_sno}
                />
              </div>
              <div className="modempdtsheetitem">
                <span>
                  Name<span id="asteriskspan">*</span>
                </span>
                <input
                  value={namevalue}
                  onChange={namechangehandle}
                  type="text"
                  placeholder="Name"
                />
              </div>
              <div className="modempdtsheetitem">
                <span>Father's name</span>
                <input
                  value={fathervalue}
                  onChange={fatherchangehandle}
                  type="text"
                  placeholder="Father's name"
                />
              </div>
              <div className="modempdtsheetitem">
                <span>
                  Mobile
                </span>
                <input
                  value={mobilevalue}
                  onChange={mobilechangehandle}
                  onWheel={(e) => e.target.blur()} type="number"
                  placeholder="01XXXXXXXXX"
                />
              </div>
              <div className="modempdtsheetitem">
                <span>Email</span>
                <input
                  value={mailvalue}
                  onChange={mailchangehandle}
                  type="email"
                  placeholder="abc@example.com"
                />
              </div>
              <div className="modempdtsheetitem">
                <span>
                  Address
                </span>
                <input
                  value={adrvalue}
                  onChange={adrchangehandle}
                  type="text"
                  placeholder="Mazar Road, Dhaka"
                />
              </div>
              
              <div className="modempdtsheetitem">
                <span>Blood Group</span>
                <input
                  value={bloodvalue}
                  onChange={bloodchangehandle}
                  type="text"
                  placeholder="A+"
                />
              </div>
              <div className="modempdtsheetitem">
                <span>
                  Qualification
                </span>
                <input
                  value={qualivalue}
                  onChange={qualichangehandle}
                  type="text"
                  placeholder="MBA"
                />
              </div>
              <div className="modempdtsheetitem">
                <span>
                  Department<span id="asteriskspan">*</span>
                </span>
                <select
                  className="selectdeptmodempdtsheet"
                  value={deptselected}
                  onChange={newdeptselected}
                >
                  {departments.map((q, i) => (
                    <option> {q.department}</option>
                  ))}
                </select>
              </div>
              <div className="modempdtsheetitem">
                <span>
                  Designation<span id="asteriskspan">*</span>
                </span>
                <input
                  value={desigvalue}
                  onChange={desigchangehandle}
                  type="text"
                  placeholder="Assistant Manager"
                />
              </div>
              <div className="modempdtsheetitem">
                <span>Designation sequence</span>
                <input
                  value={desigseqvalue}
                  onChange={desigseqchangehandle}
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  placeholder="0"
                />
              </div>
              <div className="modempdtsheetitem">
                <span>Date of birth</span>
                <input
                  value={birthvalue}
                  onChange={dobchangehandle}
                  type="date"
                  placeholder=""
                />
              </div>
              <div className="modempdtsheetitem">
                <span>
                  Date of joining<span id="asteriskspan">*</span>
                </span>
                <input
                  value={joinvalue}
                  onChange={dojchangehandle}
                  type="date"
                  placeholder=""
                />
              </div>

              <div className="modempdtsheetitem">
                <span>
                  Gender<span id="asteriskspan">*</span>
                </span>
                <select
                  className="selectdeptmodempdtsheet"
                  value={genderselected}
                  onChange={handlegenderselect}
                >
                  <option>Male</option>
                  <option> Female</option>
                  <option> Others</option>
                </select>
              </div>
              <div className="modempdtsheetitem">
                <span>
                  Employee status<span id="asteriskspan">*</span>
                </span>
                <select
                  className="selectdeptmodempdtsheet"
                  value={empstatus}
                  onChange={handleempstatusselect}
                >
                  <option>Working</option>
                  <option> Left</option>
                </select>
              </div>
              <div className="modempdtsheetitem">
                <span>Employee left Reason</span>
                <input
                  value={empleftrsnvalue}
                  onChange={empleftrsnchangehandle}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="modempdtsheetitem">
                <span>Employee left date</span>
                <input
                  value={empleftdatevalue}
                  onChange={dolchangehandle}
                  type="date"
                  placeholder=""
                />
              </div>
              <div className="modempdtsheetitem">
                <input
                  type="file"
                  multiple={false}
                  accept="image/*"
                  placeholder=""
                  onChange={photochangehandle}
                />
                <img className="userimg M" src={previewUrl} alt="" />
              </div>
            </div>

            <button
              onClick={() => callupdateemp_put_api()}
              className="createempbtn"
            >
              Update
            </button>
          </div>
        </div>{" "}
      </div>{" "}
    </Dialog>
  );
};

export default Dtsheet;
