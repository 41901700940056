import React, { useEffect, useRef, useState } from 'react'
import "./Loginpage.css"
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loginslice, { calllogin_service, callreftoken_service, handleloginloading, incrementByAmount } from '../../Redux/Slice/Loginslice';
import { Loader_rotatelines } from '../../App';
import { handlesetstatuserrortoday } from '../../Redux/Slice/Todayatndncslice';
import { handlesetstatuserroruserinfo } from '../../Redux/Slice/Userinfoslice';
import { handlesetstatuserrormonthlyatn } from '../../Redux/Slice/Monthlyatnslice';
import { handlesetstatuserrorlvapp } from '../../Redux/Slice/Leaveapplicationslice';
import { handlesetstatuserrorlvstatus } from '../../Redux/Slice/Leavestatusslice';
import { handlesetstatuserrordashboard } from '../../Redux/Slice/Dashboardslice';
import { handlesetstatuserrormyemp } from '../../Redux/Slice/Myemployeesslices/Myemppanelslice';
import { handlesetstatuserrormyemplvapp } from '../../Redux/Slice/Myemployeesslices/Myemplvappslice';
import { handlesetstatuserrormyempmonth } from '../../Redux/Slice/Myemployeesslices/Myempmonthsumslice';
import { handlesetstatuserroradmpanel } from '../../Redux/Slice/Adminpanelslices/Adminpanelslice';
import { handlesetstatuserroradmlvapp } from '../../Redux/Slice/Adminpanelslices/Admemplvappsslice';
import { handlesetstatuserroradmmonthsum } from '../../Redux/Slice/Adminpanelslices/Admempmonthsumslice';
import { handlesetstatuserroradmdaywise } from '../../Redux/Slice/Adminpanelslices/Admdaywiseatnslice';
import { handlesetstatuserroradmnewemp } from '../../Redux/Slice/Adminpanelslices/Admnewempslice';
import { handlesetstatuserroradmmodifyemp } from '../../Redux/Slice/Adminpanelslices/Modifyempslice';
import { handlesetstatuserroradmallemp } from '../../Redux/Slice/Adminpanelslices/Admallempslice';
import { handlesetstatuserroradmemptl } from '../../Redux/Slice/Adminpanelslices/Admemptlslice';
import { handlesetstatuserroradmcalen } from '../../Redux/Slice/Adminpanelslices/Admcalendarslice';
import { handlesetstatuserroradmdash } from '../../Redux/Slice/Adminpanelslices/Admempdashboardslice';


const Loginpage = ({login}) => {
  const navigate = useNavigate();
  const refpw = useRef('');
  const refid = useRef('');
  
  
  const dispatch = useDispatch();

  
  const { logoutpress } = useLocation();
  const loginpage_slice = useSelector((state) => state.login);
  const handleloginpress = async ()=> {
    console.log(refid.current.value)
    
    if (loginpage_slice.loading === false) {
      var data;
      try {
        dispatch(handleloginloading())
        const response = await fetch("https://api.ipify.org")
       data = await response.text();
      } catch (error) {
        data = "0.0.0.0"
        console.error("Failed to fetch IP:", error)
      }
      dispatch(calllogin_service({sno:`${refid.current.value}`,password:`${refpw.current.value}`,ip:data}))
    

    }

  }
  useEffect(() => {
    dispatch(handlesetstatuserrortoday())
    dispatch(handlesetstatuserroruserinfo())
    dispatch(handlesetstatuserrormonthlyatn())
    dispatch(handlesetstatuserrorlvapp())
    dispatch(handlesetstatuserrorlvstatus())
    dispatch(handlesetstatuserrordashboard())
    dispatch(handlesetstatuserrormyemp())
    dispatch(handlesetstatuserrormyemplvapp())
    dispatch(handlesetstatuserrormyempmonth())
    dispatch(handlesetstatuserroradmpanel())
    dispatch(handlesetstatuserroradmlvapp())
    dispatch(handlesetstatuserroradmmonthsum())
    dispatch(handlesetstatuserroradmdaywise())
    dispatch(handlesetstatuserroradmnewemp())
    dispatch(handlesetstatuserroradmmodifyemp())
    dispatch(handlesetstatuserroradmallemp())
    dispatch(handlesetstatuserroradmemptl())
    dispatch(handlesetstatuserroradmcalen())
    dispatch(handlesetstatuserroradmdash())
    console.log(logoutpress)
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false && loginpage_slice.logoutpressed === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    }
  },[])
  
  useEffect(() => {
    console.log(loginpage_slice.data["loggedin"])
    if (loginpage_slice.data["loggedin"] === true) {
      navigate("/dashboard");
    }
  }, [loginpage_slice.data["loggedin"]]);
    const [passvisible,setPassvisible] = useState(false);
    const [passval,setPassval] = useState('');
    const [idval,setIdval] = useState('');
    const handleChangeID = (event) => {
      setIdval(event.target.value);
    };
    const handleChangePW = (event) => {
      setPassval(event.target.value);
    };
    const fetchIP = async () => {
      try {

        const response = await fetch("https://api.ipify.org")
        const data = await response.text();
      } catch (error) {
        console.error("Failed to fetch IP:", error)
      }
    }
    
    
  return (
    <div className='loginouter'>
      {loginpage_slice.loading && <div className="loader_login"><Loader_rotatelines  /></div> }
      {(loginpage_slice.reftokenfetched === true && loginpage_slice.data["loggedin"] === false) && <div className='loginpage'>
        <div className="logincard">
          
          
        
        <div className="loginelemscontainer">
          <h3 style={{textAlign:"center",paddingBottom:"15px"}}>EMSL HR</h3>
         
        <div className="loginelem">
          <span>Emplyee ID</span>
          <input onBlur={() => document.body.style.zoom = 1} onKeyDown={(event)=> {
            if (event.key === "Enter") {
              if (refid.current.value !== '' && refpw.current.value !== '') {
                handleloginpress()
              }
            }
          }} ref={refid} required type="text" placeholder='ID' autoFocus={true} />
        </div>
        <div className="loginelem">
          <span>Password</span>
          <div className="passcontainer">
            {/*  */}
          <input onBlur={() => document.body.style.zoom = 1}  onKeyDown={(event)=> {
            
            console.log(event.key)
            if (event.key === "Enter") {
              if (refid.current.value !== '' && refpw.current.value !== '') {
                handleloginpress()
              }
            }
          }}  ref={refpw} maxLength={12} type={`${passvisible ? "text" : "password"}`} placeholder='Password' />
          <button className="passwordvisibilitydiv" onClick={()=>setPassvisible(q=>!q)}>{passvisible ? <MdVisibility size={18}/> : <MdVisibilityOff size={18}/>}</button>
          
          
          </div>
        </div>
       {<p className='errormsglogin'>{loginpage_slice.error === "donotmatch" ? "ID and password do not match" : ""}</p>}
        </div>
        <button disabled={refid.current.value === '' || refpw.current.value === ''} className="loginelembtn" onClick={()=>handleloginpress()}>Login</button>
      

        </div>
    </div>}
    </div>
  )
}

export default Loginpage