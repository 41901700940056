import React, { useEffect, useState } from 'react'
import "./Emplvapp.css"
import Leavedetailscontainer from '../../../Elements/Leavedetailscontainer/Leavedetailscontainer'
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../../Redux/Slice/Loginslice';
import { calladmemplvapps_service, changeupdatepressvalueadmlv, cleardatabeforetabchange, handlesetstatuserroradmlvapp } from '../../../Redux/Slice/Adminpanelslices/Admemplvappsslice';
import { Loader_rotatelines } from '../../../App';
import { calladminpanel_service } from '../../../Redux/Slice/Adminpanelslices/Adminpanelslice';
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
const Emplvapp = () => {
  const navigate = useNavigate();
  
  const [empid,setEmpid] = useState("");
  const [resized, setResized] = useState(false);


  const loginpage_slice = useSelector((state) => state.login);
  
  const adminpanelpage_slice = useSelector((state) => state.adminpanel);

  const [tabchange,setTabchange] = useState(false);
  
  

  const admemplvappspage_slice = useSelector((state) => state.admemplvapps);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserroradmlvapp())
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (admemplvappspage_slice.resultsdata !== null) {
      calladmemplvapps_get_api("null")
    }
    }
    const resizefunc = () => {
      console.log(resized);
      if (window.innerHeight >= document.documentElement.scrollHeight) {
        if (resized === false) {
          setResized((prev) => !prev);
        }
      }
    };
    const scrollfunc = () => {
      console.log(resized);

      if (
        window.innerHeight + document.documentElement.scrollTop + 200 >=
        document.documentElement.scrollHeight
      ) {
        if (resized === false) {
          setResized((prev) => !prev);
          console.log("oks");
        } else {
          console.log("ss");
        }
      }
    };
    window.addEventListener("resize", resizefunc);
    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("resize", resizefunc);
      window.removeEventListener("scroll", scrollfunc);
    };
  },[])
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (admemplvappspage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (admemplvappspage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    } else if (admemplvappspage_slice.error === "noemp") {
      console.log("ss")
      dispatch(handlesetstatuserroradmlvapp())
      

    }  else if ((admemplvappspage_slice.status !== '200' && admemplvappspage_slice.status !=="") || admemplvappspage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserroradmlvapp())
      
      navigate("/error")
    }

  },[admemplvappspage_slice.error,admemplvappspage_slice.status])
  useEffect(() => {
    console.log("resss");

    if (resized === true) {
      console.log("res");
      moreload();
    }
  }, [resized]);
  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && admemplvappspage_slice.resultsdata === null) {
      calladmemplvapps_get_api("null") }
  },[loginpage_slice.data['access']])


  

  useEffect(() => {
    if (
      admemplvappspage_slice.resultsdata !== null &&
      (window.innerHeight >= document.documentElement.scrollHeight ||
        window.innerHeight >= document.documentElement.scrollHeight)
    ) {
      moreload();
    } else {
      setResized(false);
    }
  }, [admemplvappspage_slice.resultsdata]);




  useEffect(()=> {
    console.log(admemplvappspage_slice.admupdatepostresponsebody)
    console.log(admemplvappspage_slice.updatepressed)
    if (admemplvappspage_slice.admupdatepostresponsebody != ''  && admemplvappspage_slice.updatepressed === true) {
      
      console.log("sss")
      if (admemplvappspage_slice.admupdatepostresponsebody === "accept done") {
        console.log("sss")
        console.log("sss")
        
        swal("Leave Granted!", {
          buttons: false,
          icon:"success",
          title:"Accepted!"
        });
      } else if (admemplvappspage_slice.admupdatepostresponsebody === "accept leave already") {
        console.log("sss")
        console.log("sss")
        
        swal("You have already granted this employee's leave on requested days. Hence, this leave can not be granted!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      }else if (admemplvappspage_slice.admupdatepostresponsebody === "accept attendance exists") {
        console.log("sss")
        console.log("sss")
        
        swal("This employee already has attendance on leave requested days. Hence, this leave can not be granted!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (admemplvappspage_slice.admupdatepostresponsebody === "accept automatic") {
        console.log("sss")
        console.log("sss")
        
        swal("This employee does not have enough leave balance. Hence this leave can not be granted!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      }else if (admemplvappspage_slice.admupdatepostresponsebody === "accept Holiday exists") {
        console.log("sss")
        console.log("sss")
        
        swal("Holiday found on leave days. Hence, this leave can not be granted!", {
          buttons: false,
          icon:"error",
          title:"Failed!"
        });
      } else if (admemplvappspage_slice.admupdatepostresponsebody === "reject done") {
        console.log("sss")
        console.log("sss")
        
        swal("Leave Rejected!", {
          buttons: false,
          icon:"success",
          title:"Rejected!"
        });
      } else  {
          swal("Your leave approval failed!", {
            buttons: false,
            icon:"error",
            title:"Failed!"
          });
        
      }
      
      
      dispatch(changeupdatepressvalueadmlv(false));

    }
    
  },[admemplvappspage_slice.admupdatepostresponsebody])






















  const calladmemplvapps_get_api = (nextlinkpass)=> {
    if (loginpage_slice.data['access'] !== '') {
    dispatch(calladmemplvapps_service([`${loginpage_slice.data['access']}`,empid === "" ? "null" : empid,tabreview, nextlinkpass === "null" ? "null" : admemplvappspage_slice.next,]))
  }
  
  }
  const calladminpanel_get_api = ()=> {
    dispatch(calladminpanel_service(`${loginpage_slice.data['access']}`))
  
  }

  const moreload = () => {
    console.log(admemplvappspage_slice.next);

    if (
      admemplvappspage_slice.loading === false &&
      admemplvappspage_slice.resultsdata.length < admemplvappspage_slice.count
    ) {
      console.log("1234567");

      calladmemplvapps_get_api("notnull");

      console.log(document.documentElement.scrollHeight);
      console.log(window.innerHeight);
    } else {
      setResized(false);
      console.log("dd");
    }
  };

  











  const [tabreview,setTabreview] =useState("Unreviewed")
  const lvdata = [
    {"hi":1,"accept":true},
    {"hi":1,"accept":false},
    {"hi":1,"accept":false},
  ]

  
  useEffect(()=> {
    if (loginpage_slice.data['access'] !== '' && tabchange === true) {
      calladmemplvapps_get_api("null")
      if (tabchange === true) {
        setTabchange(q=>!q)
      }
    
    }
  
  },[tabchange])

  const handleidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setEmpid("")
    } else {
      setEmpid(event.target.value)
    }
    
  }
  
  
  return (
    <div className='admempcontainermain'>
      {(admemplvappspage_slice.loading || admemplvappspage_slice.updateloading || loginpage_slice.loading || adminpanelpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
       
      <div className='admemplv'>
        <h3>All employees leave status </h3>
        <div className="admlvrevtabscontainer">
            <span>Application status:</span>
            <div className="admlvreviewtabs">
              <button disabled={admemplvappspage_slice.loading} className={`tabbuttonsadmlvrev ${tabreview === "Unreviewed" ? "active":""}`} onClick={()=>{
                setTabreview("Unreviewed")
                if (tabchange === false) {
                  setTabchange(q=>!q)
                }
                
              }}>Unreviewed</button>
              <button disabled={admemplvappspage_slice.loading} className={`tabbuttonsadmlvrev ${tabreview === "Reviewed" ? "active":""}`} onClick={()=>{
                setTabreview("Reviewed")
                if (tabchange === false) {
                  setTabchange(q=>!q)
                }
                
                }}>Reviewed</button>
            </div>
          </div>
          
          <div className="inputinadmemplvappscontainer">
            <input value={empid} onChange={handleidchange} maxLength={6} type="number"
                  onWheel={(e) => e.target.blur()} placeholder='Employee ID'/>
            <button disabled={admemplvappspage_slice.loading} className='srchbtnadmemplv' onClick={()=>calladmemplvapps_get_api("null")}>Search</button>
        </div>
          {(admemplvappspage_slice.resultsdata === null || tabreview !== admemplvappspage_slice.currenttab) ? (admemplvappspage_slice.loading === false && <div className='nonefoundadmlv'>
          <h3>No employee found!</h3>
        </div>) :
        
        <div className="admlvrevcontainer">
          <h3 style={{textAlign:"center",background:"rgba(58, 160, 255, 0.799)",color:"white",padding:"5px",borderRadius:"0px"}}>{admemplvappspage_slice.count} applications</h3>
          {admemplvappspage_slice.resultsdata.map((q,i) => (
            <Leavedetailscontainer leavestatus={q} i={i} tabrev={tabreview} frompage={"adm"} page_slice={admemplvappspage_slice} loginaccessvalue={loginpage_slice.data['access']}/>
          ))}
          
        
          </div>}
      </div>
      
    </div>
  )
}

export default Emplvapp