



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";


export const calladminpanel_service = createAsyncThunk("calladminpanel", async (data) => {
    console.log(data)
    
    const response = await fetch(`${myport}admcheck/`,{headers: {
        'Authorization': `Bearer ${data}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})

export const adminpanelSlice = createSlice({
    name: "adminpanel",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,

      },
      reducers: {
        handlesetstatuserroradmpanel(state,action) {
            state.data = null;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calladminpanel_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladminpanel_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                state.error = null;
                
            
            state.data = action.payload[0];
            
            console.log(state.data);
        } else {
            state.error = null;
        }
        state.status = `${action.payload[1]}`;
                
            state.loading = false;
        });
        builder.addCase(calladminpanel_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserroradmpanel } = adminpanelSlice.actions
export default adminpanelSlice.reducer