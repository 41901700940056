



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";
import axios from 'axios';
import FormData from 'form-data';

export const calladmmodemp_service = createAsyncThunk("calladmmodemp", async (data) => {
    console.log("okkkcakl")
    
    const response = await fetch(`${myport}usermodifygetemp/${data[1]}/`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});

      const jsondata = await response.json();

      console.log(jsondata)
      
      return [jsondata,response.status];
})

export const callupdateemp_adm_service = createAsyncThunk("callupdateempadm", async (data) => {
    console.log(`${myport}userlogin_get/`)
    const response = await axios.put(`${myport}userinfoadminupdate/${data[2]}/`,data[1],{withCredentials:true, headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${data[0]}`,
      }});
      console.log(response)
      

      console.log(response.data)
      console.log("myimg")
      
      return [response.data,response.status,data[1],];
})
export const callupdateemplv_adm_service = createAsyncThunk("callupdateempadmlv", async (data) => {
    console.log(data[1])
    const response = await axios.put(`${myport}userlevmastadminupdate/${data[2]}/`,data[1],{withCredentials:true, headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${data[0]}`,
      }});
      console.log(response)
      

      console.log(response.data)
      console.log("myimg",response.data)
      
      return [response.data,response.status,data[1],];
})

export const admmodempSlice = createSlice({
    name: "admmodemp",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,

      },
      reducers: {
        handlesetstatuserroradmmodifyemp(state,action) {
            state.data = null;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },
          
      },
      extraReducers: (builder) => {
        builder.addCase(calladmmodemp_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladmmodemp_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[0] === "noemp") {
                state.error = "noemp"
                console.log("ss")
                state.data = null;
      
            } else if (action.payload[1] === 200) {
                state.data = action.payload[0];
            
            console.log(state.data);
            state.error = null;
        } else {
            state.error = null;
        }
        state.status = `${action.payload[1]}`;
        state.loading = false;
        
        });
        builder.addCase(calladmmodemp_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });













        builder.addCase(callupdateemp_adm_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callupdateemp_adm_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                if (action.payload[0] === "failed") {
                    console.log("fail")
                    state.error = "failed"
                } else {
                    state.data = action.payload[0]
                
            
           
            state.error = null;
                }
                

           } else {
            state.error = null;

           }
           console.log(state.error)
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(callupdateemp_adm_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });









        builder.addCase(callupdateemplv_adm_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callupdateemplv_adm_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                if (action.payload[0] === "failed") {
                    console.log("fail")
                    state.error = "failed"
                } else {
                    console.log(action.payload[2]["cl"])
                    if ("cl" in action.payload[2]) {
                        state.data["casualleave"] = action.payload[2]["cl"]
                    }
                    if ("ml" in action.payload[2]) {
                        state.data["maternalleave"] = action.payload[2]["ml"]
                    } 
                    if ("sl" in action.payload[2]) {
                        state.data["sickleave"] = action.payload[2]["sl"]
                    } 
                    if ("el" in action.payload[2]) {
                        state.data["earnedleave"] = action.payload[2]["el"]
                    }
                
            
           
            state.error = null;
                }
                

           } else {
            state.error = null;

           }
           console.log(state.error)
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(callupdateemplv_adm_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserroradmmodifyemp,makeerrorvalueonmodemp } = admmodempSlice.actions
export default admmodempSlice.reducer