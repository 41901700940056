



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";


export const callmyemplvapps_service = createAsyncThunk("callmyemplvapps", async (data) => {
    console.log(data)
    
    const response = await fetch(data[3] !== "null" ? data[3] : `${myport}${data[2] === "Unreviewed" ? "leavetransmyemployeesget" : "leavetransmyemployeesReviewedget"}/${data[1]}/?page=1`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[3],data[2]];
})


export const callupdate_myemplv_service = createAsyncThunk("callupdatemyemplv", async (data) => {
   
    const response = await fetch(`${myport}levtransupdatemanager/${data[2]}/`,{method:'PUT',body: JSON.stringify(data[1]),headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include"
      ,});
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status,data[2],data[3],data[4]];
})






export const myemplvappsSlice = createSlice({
    name: "myemplvapps",
    initialState: {
      count:0,
      currenttab: "Unreviewed",
      next: "",
      loading: false,
      status: "",
      error: null,
      resultsdata:null,
      myempupdatepostresponsebody: '',
      updateloading: false,
      updatepressed: false,

      },
      reducers: {
        cleardatabeforetabchange(state, action) {
            console.log(state.error);
            state.resultsdata = null;
            console.log(action.payload);
          },
          changeupdatepressvaluemyemplv(state,action) {
            console.log(action.payload === true);
            state.updatepressed = action.payload
          },
          
          handlesetstatuserrormyemplvapp(state,action) {
            state.resultsdata = null;
            state.currenttab = "Unreviewed";
            state.next = "";
            state.count = 0;
            state.myempupdatepostresponsebody= '';
            state.updatepressed = false;
      state.updateloading = false;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },

      },
      
      extraReducers: (builder) => {
        builder.addCase(callmyemplvapps_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callmyemplvapps_service.fulfilled, (state,action) => {

            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[0] === "noemp") {
                state.error = "noemp"
                console.log("ss")
                state.resultsdata = null;
      
            }  else if (action.payload[1] === 200) {
                

          state.next = action.payload[0].next === null ? "null" : action.payload[0].next;
          state.count = action.payload[0].count;
          if (action.payload[3] === "Unreviewed") {
              state.currenttab = "Unreviewed"
          } else {
              state.currenttab = "Reviewed"
          }

          if (action.payload[2] === "null") {
              state.resultsdata = action.payload[0].results;
         
          } else {
              console.log(state.resultsdata.length)
              state.resultsdata = [...state.resultsdata, ...action.payload[0].results]
              console.log(state.resultsdata.length)
         
          }
          state.error = null;
        } else {
            state.error = null;
        }
          
          state.status = `${action.payload[1]}`;
          state.loading = false;
        });
        builder.addCase(callmyemplvapps_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });


















        

        builder.addCase(callupdate_myemplv_service.pending, (state)=> {
            state.myempupdatepostresponsebody = '';
            state.updateloading = true;
            state.error = null;
        });
        builder.addCase(callupdate_myemplv_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                console.log(""+ action.payload[3].replace(/'/g, "\\'"))
                
                
                console.log(state.resultsdata.length)
                
                
                    
                const result = state.resultsdata.filter((lv)=> lv.lvref !== `${action.payload[2]}` && `${lv.status}`.replace(/'/g, "\\'") !== action.payload[3])
                        
                state.resultsdata = result
                console.log(state.resultsdata.length)
                state.count = state.count - 1
                    
                    

                        //state.resultsdata = []
                
            
           
            state.error = null;

           } else {
            state.error = null;

           }
           state.myempupdatepostresponsebody = `${action.payload[4]} ${action.payload[0]}`
           
            
            
            state.status = `${action.payload[1]}`;
            state.updateloading = false;
        });
        builder.addCase(callupdate_myemplv_service.rejected, (state,action) => {
            state.updateloading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { cleardatabeforetabchange,handlesetstatuserrormyemplvapp,changeupdatepressvaluemyemplv } = myemplvappsSlice.actions;
export default myemplvappsSlice.reducer