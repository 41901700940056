



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../App";


export const callleavestatus_service = createAsyncThunk("callleavestatus", async (data) => {
    console.log(data)
    
    const response = await fetch(data[1] !== "null" ? data[1] : `${myport}leavetransget/`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[1]];
})

export const leavestatusSlice = createSlice({
    name: "leavestatus",
    initialState: {
        count:0,
      currenttab: "Unreviewed",
      next: "",
      loading: false,
      status: "",
      error: null,
      resultsdata:null,

      },
      reducers: {
        handlesetstatuserrorlvstatus(state,action) {
            state.resultsdata = null;
            state.currenttab = "Unreviewed";
            state.next = "";
            state.count = 0;
            console.log(state.status)
            console.log(state.error)
            if (state.status === "401") {
                state.status = ""
            }
            if (state.error === "alreadyloggedout") {
                state.error = null;
            }
          },
      },
      extraReducers: (builder) => {
        builder.addCase(callleavestatus_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callleavestatus_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
            



            state.next = action.payload[0].next === null ? "null" : action.payload[0].next;
            state.count = action.payload[0].count;
            state.error = null;
          

          if (action.payload[2] === "null") {
              state.resultsdata = action.payload[0].results;
         
          } else {
              console.log(state.resultsdata.length)
              state.resultsdata = [...state.resultsdata, ...action.payload[0].results]
              console.log(state.resultsdata.length)
         
          }

           } else {
            state.error = null;

           }
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);















            
          
          
        });
        builder.addCase(callleavestatus_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserrorlvstatus } = leavestatusSlice.actions
export default leavestatusSlice.reducer