



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../../App";


export const calladmallemp_working_service = createAsyncThunk("calladmallempworking", async (data) => {
    console.log(data)
    const dept = encodeURIComponent(data[2])
    
    const response = await fetch(data[4] !== "null" ? data[4] : `${myport}${data[3]}/${data[1]}/?dept=${dept}&page=1
    `,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status,data[4],data[3]];
})
/* export const calladmallemp_left_service = createAsyncThunk("calladmallempleft", async (data) => {
    console.log(data)
    const dept = encodeURIComponent(data[2])
    const response = await fetch(`${myport}usergetempleft/${data[1]}/?dept=${dept}&page=1
    `,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
}) */

export const admallempSlice = createSlice({
    name: "admallemp",
    initialState: {
        count:0,
        currenttab: "working",
        next: "",
        loading: false,
        status: "",
        error: null,
        resultsdata:null,

      },
      reducers: {
        handlesetstatuserroradmallemp(state,action) {
            state.resultsdata = null;
            state.currenttab = "working";
            state.next = "";
            state.count = 0;
            console.log(state.status)
            console.log(state.error)
            state.error = null;
            state.status = ""
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calladmallemp_working_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calladmallemp_working_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"
    
            } else if (action.payload[0] === "noemp") {
                state.error = "noemp"
                console.log("ss")
                state.data = null;
      
            } else if (action.payload[0] === "noemp") {
                state.error = "noemp"
                console.log("assd")
    
            } else if (action.payload[1] === 200) {
                state.error = null;
            state.next = action.payload[0].next === null ? "null" : action.payload[0].next;
            state.count = action.payload[0].count;
            if (action.payload[3] === "usergetempworking") {
                state.currenttab = "working"
            } else {
                state.currenttab = "left"
            }

            if (action.payload[2] === "null") {
                state.resultsdata = action.payload[0].results;
           
            } else {
                console.log(state.resultsdata.length)
                state.resultsdata = [...state.resultsdata, ...action.payload[0].results]
                console.log(state.resultsdata.length)
           
            }
        } else {
            state.error = null;
        }
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
        });
        builder.addCase(calladmallemp_working_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });



/* 
        builder.addCase(calladmallemp_left_service.pending, (state)=> {
            state.loading = true;
        });
        builder.addCase(calladmallemp_left_service.fulfilled, (state,action) => {
            state.loading = false;
            
            state.data = action.payload[0];
            state.status = `${action.payload[1]}`;
            console.log(state.data);
        });
        builder.addCase(calladmallemp_left_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        }); */
      },
    
});

export const { handlesetstatuserroradmallemp } = admallempSlice.actions
export default admallempSlice.reducer