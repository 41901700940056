



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../App";


export const callmonthlyatn_service = createAsyncThunk("callmonthlyatn", async (data) => {
    console.log(data)
    
    const response = await fetch(`${myport}monthly_attendance_get/${data[1]}/${data[2]}/?page=1`,{headers: {
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})

export const monthlyatnSlice = createSlice({
    name: "monthlyatn",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,

      },
      reducers: {
        handlesetstatuserrormonthlyatn(state,action) {
            state.data = null;
            console.log(state.status)
            console.log(state.error)
            if (state.status === "401") {
                state.status = ""
            }
            if (state.error === "alreadyloggedout") {
                state.error = null;
            }
          },
      },
      extraReducers: (builder) => {
        builder.addCase(callmonthlyatn_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callmonthlyatn_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
            state.data = action.payload[0];
            state.error = null;

           } else {
            state.error = null;

           }
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(callmonthlyatn_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserrormonthlyatn } = monthlyatnSlice.actions
export default monthlyatnSlice.reducer