



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { myport } from "../../App";


export const calllvapp_service = createAsyncThunk("calllvapp", async (data) => {
    console.log(data)
    
    const response = await fetch(`${myport}leavemastget/`,{headers: {
        'Authorization': `Bearer ${data}`,
      },credentials:"include",});
      const jsondata = await response.json();
      console.log(jsondata)
      
      return [jsondata,response.status];
})
export const calllvapppost_service = createAsyncThunk("calllvapppost", async (data) => {
    console.log(`${myport}leavetranspost/`)
    const response = await fetch(`${myport}leavetranspost/`,{method:'POST',body: JSON.stringify(data[1]),headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${data[0]}`,
      },credentials:"include"
      ,});
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status];
})

export const lvappSlice = createSlice({
    name: "lvapp",
    initialState: {
        data: null,
        loading: false,
        status: "",
        error: null,
        lvpostresponsebody: ''

      },
      reducers: {
        handlesetstatuserrorlvapp(state,action) {
            state.data = null;
            state.lvpostresponsebody = ''
            if (state.status === "401") {
                state.status = ""
            }
            if (state.error === "alreadyloggedout") {
                state.error = null;
            }
          },
      },
      extraReducers: (builder) => {
        builder.addCase(calllvapp_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calllvapp_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
            state.data = action.payload[0];
            state.error = null;

           } else {
            state.error = null;

           }
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(calllvapp_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        









        builder.addCase(calllvapppost_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
            state.lvpostresponsebody = '';
        });
        builder.addCase(calllvapppost_service.fulfilled, (state,action) => {
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                state.lvpostresponsebody = `${action.payload[0]}`
                console.log(state.lvpostresponsebody === 'true')
                console.log(state.lvpostresponsebody === true)
                
            
           
            state.error = null;

           } else {
            state.error = null;

           }
            
            
            state.status = `${action.payload[1]}`;
            state.loading = false;
            console.log(state.data);
        });
        builder.addCase(calllvapppost_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
        });
      },
    
});

export const { handlesetstatuserrorlvapp } = lvappSlice.actions
export default lvappSlice.reducer