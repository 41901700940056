import React, { useEffect } from 'react'
import "./Myemployeesmonthsum.css"
import Datepicker from "react-datepicker"
import format from 'date-fns/format';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';
import { FaCalendar } from "react-icons/fa";
import Atndncdetailselement from '../../../Elements/Atndncdetailselement/Atndncdetailselement';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../../Redux/Slice/Loginslice';
import { callmyempmonthsum_service, handlesetstatuserrormyempmonth } from '../../../Redux/Slice/Myemployeesslices/Myempmonthsumslice';
import { callmyemppanel_service } from '../../../Redux/Slice/Myemployeesslices/Myemppanelslice';
import { Loader_rotatelines, getdateformatvalue } from '../../../App';
const Myemployeesmonthsum = () => {
  const navigate = useNavigate();

  const [resized, setResized] = useState(false);
  const [ini,setIni] = useState(false);



  const loginpage_slice = useSelector((state) => state.login);
  const myemppanelpage_slice = useSelector((state) => state.myemppanel);
  
  

  const myempmonthsumpage_slice = useSelector((state) => state.myempmonthsum);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserrormyempmonth());
    if (ini === false) {
      setIni(q=>!q)
    }
    console.log(11)
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (myempmonthsumpage_slice.resultsdata !== null) {
        callmyempmonthsum_get_api("null")
      }
      //
    }
    const resizefunc = () => {
      console.log(resized);
      if (window.innerHeight >= document.documentElement.scrollHeight) {
        if (resized === false) {
          setResized((prev) => !prev);
        }
      }
    };
    const scrollfunc = () => {
      console.log(resized);

      if (
        window.innerHeight + document.documentElement.scrollTop + 200 >=
        document.documentElement.scrollHeight
      ) {
        if (resized === false) {
          setResized((prev) => !prev);
          console.log("oks");
        } else {
          console.log("ss");
        }
      }
    };
    window.addEventListener("resize", resizefunc);
    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("resize", resizefunc);
      window.removeEventListener("scroll", scrollfunc);
    };
  }, []);
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  const [err,setErr] = useState(false)
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (myempmonthsumpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (myempmonthsumpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    } else if (myempmonthsumpage_slice.error === "noemp") {
      console.log("ss")
      setErr(myempmonthsumpage_slice.error)
      dispatch(handlesetstatuserrormyempmonth());
      

    }  else if ((myempmonthsumpage_slice.status !== '200' && myempmonthsumpage_slice.status !=="") || myempmonthsumpage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserrormyempmonth());
      
      navigate("/error")
    }

  },[myempmonthsumpage_slice.error,myempmonthsumpage_slice.status])
  useEffect(() => {
    console.log("resss");

    if (resized === true) {
      console.log("res");
      moreload();
    }
  }, [resized]);


  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && myempmonthsumpage_slice.resultsdata === null) {
      callmyempmonthsum_get_api("null") }
  },[loginpage_slice.data['access']])

  
  useEffect(() => {
    if (
      myempmonthsumpage_slice.resultsdata !== null &&
      (window.innerHeight >= document.documentElement.scrollHeight ||
        window.innerHeight >= document.documentElement.scrollHeight)
    ) {
      moreload();
    } else {
      setResized(false);
    }
  }, [myempmonthsumpage_slice.resultsdata]);


  





  const callmyempmonthsum_get_api = (nextlinkpass)=> {
    dispatch(callmyempmonthsum_service([`${loginpage_slice.data['access']}`,empid === "" ? "null" : empid,selectedMonth.getMonth() + 1,selectedMonth.getFullYear(),atnstatusselected,nextlinkpass === "null" ? "null"  :myempmonthsumpage_slice.next]))
  
  }

  const callmyemppanel_get_api = ()=> {
    dispatch(callmyemppanel_service(`${loginpage_slice.data['access']}`))
  
  }


  const moreload = () => {

    if (
      myempmonthsumpage_slice.loading === false &&
      myempmonthsumpage_slice.resultsdata.length < myempmonthsumpage_slice.count
    ) {
      console.log("1234567");

      callmyempmonthsum_get_api("notnull");

      console.log(document.documentElement.scrollHeight);
      console.log(window.innerHeight);
    } else {
      setResized(false);
      console.log("dd");
    }
  };




















  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [atnstatusselected, setAtnstatusselected] = useState("All");
  const [empid, setEmpid] = useState("");
  const handleidchange = (event)=> {
    if (event.target.value.trim() === "") {
      console.log(event.target.value)
      setEmpid("")
    } else {
      setEmpid(event.target.value)
    }
    
  }
  

  const newatnstatusselected = (event)=> {
    setAtnstatusselected(event.target.value);
  }
  
  return (
    <div className='myempmnthsumouter'>
      {(myempmonthsumpage_slice.loading || loginpage_slice.loading || myemppanelpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
    <div className='myempmnthsum'>
      <div className="myempmnthfilter">
      <div className="myempsearchmonthsum">
          <span>Month: </span>
      <Datepicker
      selected={selectedMonth}
      onChange={date => setSelectedMonth(date)}
      maxDate={new Date()}
      closeOnScroll
      showMonthYearPicker
      displayFormat="MMMM YYYY"
      dateFormat= "yyyy, MMMM"
      customInput={<button className='myssmonthpickerempmnth'><div className='myssmonthpickerdetailsempmnth'> <FaCalendar /></div> {format(selectedMonth,"MMMM - yyyy")}</button>}
    />
      </div>
    <div className="myempsearchmonthsum">
          <span>Attendance status: </span>
          <select className="myempselectdeptmonthsum" value={atnstatusselected} onChange={newatnstatusselected}>
                  <option >All</option>
                  <option > Present</option>
                  <option > In</option>
                  <option > Late</option>
                  <option > Leave</option>
                  <option > Absent</option>
                  <option > Remote</option>
                  <option > Holiday</option>
              </select>
        </div>
        
        <div className="myempsearchmonthsum">
          <span>Employee ID:</span>
          <input disabled={(myempmonthsumpage_slice.loading) ? true : false} value={empid} onKeyDown={(event)=> {
                    if (event.key === "Enter") {
                      callmyempmonthsum_get_api("null")
                    }
                  }} onChange={handleidchange} type="number" onWheel={(e) => e.target.blur()} placeholder='Employee ID' />
        </div>
        <button disabled={(myempmonthsumpage_slice.loading) ? true : false} onClick={()=>callmyempmonthsum_get_api("null")} className="myempsearchbtnallemp">Search</button>
      </div>
      {(myempmonthsumpage_slice.resultsdata === null || myempmonthsumpage_slice.resultsdata.length === 0) ? (((err || (myempmonthsumpage_slice.resultsdata === null ? true : myempmonthsumpage_slice.resultsdata.length === 0)) && myempmonthsumpage_slice.loading === false) && <div className='nonefoundmyempmonth'>
          <h3>{"No employee found!"}</h3>
        </div>) : <div className="myempmonthsumdetails">
      
      {myempmonthsumpage_slice.resultsdata.map((q,i)=> (
        <div className="monthsummyempsinglecontainer">
          <div className='monthsummyempsinglecontainerhead'>
          <p>Employee {i + 1} of {myempmonthsumpage_slice.count}</p>
          <p>{format(getdateformatvalue(q.attendance.length !== 0 ? q.attendance[0].wdate : selectedMonth),"MMMM - yyyy")}</p>
          </div>
          <div className='myemptopdetailsmonthsum'>

<div className='myempuserdetailsmonthsum'>
<div className="myempimgholdermonthsum">
    <img className={`myempuserimgmonthsum ${q.sex}`} src={q.photo} alt="" />

</div>
<div className='myempuserinfomonthsum'>
    <h3>{q.ename}</h3>
    <h4>ID:{q.areg_sno}</h4>
    <h4>Designation: {q.designation}</h4>
    <h4>Department: {q.dept}</h4>
</div>
</div>
<div className='myempatndncinfomonthsum'>
    <div className='myempatndncinfochildmonthsum'>
        
        <p><span className='myempatndncinfochildhighlightmonthsum' style={{background:"green"}}></span> Present: {q.presentcount} days</p>
        <p><span className='myempatndncinfochildhighlightmonthsum' style={{background:"orange"}}></span> Late: {q.latecount} days</p>
        <p><span className='myempatndncinfochildhighlightmonthsum' style={{background:"red"}}></span> Absent: {q.absentcount} days</p>
        <p><span className='myempatndncinfochildhighlightmonthsum' style={{background:"purple"}}></span> Leave taken: {q.leavecount} days</p>
        <p><span className='myempatndncinfochildhighlightmonthsum' style={{background:"darkcyan"}}></span> Remote: {q.remotecount} days</p>
    </div>
</div>
</div>
<Atndncdetailselement data={q.attendance}/>
        </div>
      ))}

      </div>}
    </div></div>
  )
}

export default Myemployeesmonthsum