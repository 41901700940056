import Datepicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import "./Emplateabsent.css";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  callreftoken_service,
  callunauth_logout_service,
  handlenoactcookie,
} from "../../../Redux/Slice/Loginslice";
import {
  calladmdaywiseatn_service,
  calladmupdatesingleatnget_service,
  callupdate_atn_service,
  handlesetstatuserroradmdaywise,
  handlesetstatuserroradmdaywisesingle,
} from "../../../Redux/Slice/Adminpanelslices/Admdaywiseatnslice";
import {
  Loader_rotatelines,
  getdateformatvalue,
  getstatuscolorformat,
  getstatusformat,
} from "../../../App";
import { calladminpanel_service } from "../../../Redux/Slice/Adminpanelslices/Adminpanelslice";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
const Emplateabsent = () => {
  console.log("hi");
  const navigate = useNavigate();
  const [resized, setResized] = useState(false);

  const loginpage_slice = useSelector((state) => state.login);
  const adminpanelpage_slice = useSelector((state) => state.adminpanel);

  const admdaywiseatnpage_slice = useSelector((state) => state.admdaywiseatn);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handlesetstatuserroradmdaywise());
    console.log("ss");

    if (
      loginpage_slice.data["access"] === "" &&
      loginpage_slice.problemhappened === false
    ) {
      console.log(1);
      dispatch(callreftoken_service());
    } else {
      if (admdaywiseatnpage_slice.resultsdata !== null) {

        calladmdaywiseatn_get_api("null");
      }
      
    }
    const resizefunc = () => {
      console.log(resized);
      if (window.innerHeight >= document.documentElement.scrollHeight) {
        if (resized === false) {
          setResized((prev) => !prev);
        }
      }
    };
    const scrollfunc = () => {
      console.log(resized);

      if (
        window.innerHeight + document.documentElement.scrollTop + 200 >=
        document.documentElement.scrollHeight
      ) {
        if (resized === false) {
          setResized((prev) => !prev);
          console.log("oks");
        } else {
          console.log("ss");
        }
      }
    };
    console.log(admdaywiseatnpage_slice.next);
    window.addEventListener("resize", resizefunc);
    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("resize", resizefunc);
      window.removeEventListener("scroll", scrollfunc);
    };
  }, []);
  useEffect(() => {
    console.log(loginpage_slice.reftokenfetched);
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/");
        console.log("ss");
      } else {
        console.log("s");
      }
    }
  }, [loginpage_slice.reftokenfetched]);
  useEffect(() => {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"]);
      navigate("/");
    }
  }, [loginpage_slice.problemhappened]);
  useEffect(() => {
    console.log(loginpage_slice.error);

    if (admdaywiseatnpage_slice.error === "alreadyloggedout") {
      console.log("s");

      dispatch(callunauth_logout_service());
    } else if (admdaywiseatnpage_slice.status === "401") {
      dispatch(handlenoactcookie());
    } else if ((admdaywiseatnpage_slice.status !== '200' && admdaywiseatnpage_slice.status !=="") || admdaywiseatnpage_slice.error !== null) {
      console.log("error")
      dispatch(handlesetstatuserroradmdaywise());
      
      navigate("/error")
    }
  }, [admdaywiseatnpage_slice.error, admdaywiseatnpage_slice.status]);
  const [upderror,setUpderror] = useState("")
  useEffect(() => {
    console.log(loginpage_slice.updateerror);

    if (admdaywiseatnpage_slice.updateerror === "alreadyloggedout") {
      console.log("s");

      dispatch(callunauth_logout_service());
    } else if (admdaywiseatnpage_slice.updatestatus === "401") {
      dispatch(handlenoactcookie());
    } else if (admdaywiseatnpage_slice.updateerror === "noemp" || admdaywiseatnpage_slice.updateerror === "noatn") {
      console.log("ss")
      setUpderror(admdaywiseatnpage_slice.updateerror)
      dispatch(handlesetstatuserroradmdaywisesingle());
      

    }  else if ((admdaywiseatnpage_slice.updatestatus !== '200' && admdaywiseatnpage_slice.updatestatus !=="") || admdaywiseatnpage_slice.updateerror !== null) {
      console.log("error")
      dispatch(handlesetstatuserroradmdaywisesingle());
      
      navigate("/error")
    }
  }, [admdaywiseatnpage_slice.updateerror, admdaywiseatnpage_slice.updatestatus]);
  useEffect(() => {
    console.log("resss");

    if (resized === true) {
      console.log("res");
      moreload();
    }
  }, [resized]);

  useEffect(() => {
    console.log(loginpage_slice.data["access"]);
    if (loginpage_slice.data["access"] !== "" && admdaywiseatnpage_slice.resultsdata === null) {
      calladmdaywiseatn_get_api("null");
    }
  }, [loginpage_slice.data["access"]]);

  
  useEffect(() => {
    if (
      admdaywiseatnpage_slice.resultsdata !== null &&
      (window.innerHeight >= document.documentElement.scrollHeight ||
        window.innerHeight >= document.documentElement.scrollHeight)
    ) {
      moreload();
    } else {
      setResized(false);
    }
  }, [admdaywiseatnpage_slice.resultsdata]);

  const calladmdaywiseatn_get_api = (nextlinkpass) => {
    dispatch(
      calladmdaywiseatn_service([
        `${loginpage_slice.data["access"]}`,
        format(selecteddate, "yyyy-MM-dd"),
        daywisestatusselected,
        nextlinkpass === "null" ? "null" : admdaywiseatnpage_slice.next,
      ])
    );
  };
  
  
  const calladmsingleempatn_get_api = () => {
    setUpderror("")
    

    
    dispatch(
      calladmupdatesingleatnget_service([
        `${loginpage_slice.data["access"]}`,
        format(updateselecteddate, "yyyy-MM-dd"),
        empid.trim(),
      ])
    );
  };

  const calladminpanel_get_api = () => {
    dispatch(calladminpanel_service(`${loginpage_slice.data["access"]}`));
  };
  const moreload = () => {
    console.log(admdaywiseatnpage_slice.next);

    if (
      admdaywiseatnpage_slice.loading === false &&
      admdaywiseatnpage_slice.resultsdata.length < admdaywiseatnpage_slice.count
    ) {
      console.log("1234567");

      calladmdaywiseatn_get_api("notnull");

      console.log(document.documentElement.scrollHeight);
      console.log(window.innerHeight);
    } else {
      setResized(false);
      console.log("dd");
    }
  };

  const [selecteddate, setSelecteddate] = useState(new Date());

  const [daywisestatusselected, setDaywisestatusselected] = useState("Late");
  const [empid, setEmpid] = useState("");
  const [updatedetailsstatusselected, setUpdatedetailsstatusselected] =
    useState("All");
  const [updatedeintime, setUpdateintime] = useState("");
  const [updateouttime, setUpdateouttime] = useState("");
  const [updatelatetime, setUpdatelatetime] = useState("0");
  const [datebtnselected, setDatebtnselected] = useState(false);
  const [updatedatebtnselected, setUpdatedatebtnselected] = useState(false);
  const [updateselecteddate, setUpdateselecteddate] = useState(new Date());
  const handledateselected = (date) => {
    setSelecteddate(date);
    setDatebtnselected((q) => !q);
  };
  const handleupdatedateselected = (date) => {
    setUpdateselecteddate(date);
    setUpdatedatebtnselected((q) => !q);
  };
  const newdaywisestatusselected = (event) => {
    setDaywisestatusselected(event.target.value);
  };
  useEffect(() => {
    if (admdaywiseatnpage_slice.updateresultsdata !== null) {
      setUpdatedetailsstatusselected(
        getstatusformat(admdaywiseatnpage_slice.updateresultsdata.status)
      );
      setUpdateintime(admdaywiseatnpage_slice.updateresultsdata.time_in === null ? "" : admdaywiseatnpage_slice.updateresultsdata.time_in);
      setUpdateouttime(admdaywiseatnpage_slice.updateresultsdata.time_out === null ? "" : admdaywiseatnpage_slice.updateresultsdata.time_out);
      setUpdatelatetime(`${admdaywiseatnpage_slice.updateresultsdata.late}`);
    }
  }, [admdaywiseatnpage_slice.updateresultsdata]);
  /* const checkupdatebtndisabledcall = () => {
    console.log(updatedetailsstatusselected)
    if (updatedetailsstatusselected === "Absent") {
      if (updatedeintime === "" && (updateouttime === "") && updatelatetime === "0" && updatedetailsstatusselected === getstatusformat(admdaywiseatnpage_slice.updateresultsdata.status)) {
        if (updatebtndisabled === true) {
          setUpdatebtndisabled(q=>!q);
        } 
      } else {
        if (updatebtndisabled === false) {
          setUpdatebtndisabled(q=>!q);
        }
  
      }
    } else if (updatedetailsstatusselected === "Present") {
      console.log("pre")
      if (
        (updatedeintime !== "" && updateouttime !== "" && updatedeintime ===
          admdaywiseatnpage_slice.updateresultsdata.time_in) ===
        null
          ? null
          : `${admdaywiseatnpage_slice.updateresultsdata.time_in}` &&
            ((updateouttime ===
              admdaywiseatnpage_slice.updateresultsdata.time_out) ===
            null
              ? null
              : `${admdaywiseatnpage_slice.updateresultsdata.time_out}`) &&
            updatelatetime ===
              `${admdaywiseatnpage_slice.updateresultsdata.late}` &&
            updatedetailsstatusselected ===
              getstatusformat(admdaywiseatnpage_slice.updateresultsdata.status)
      ) {
        if (updatebtndisabled === true) {
          setUpdatebtndisabled((q) => !q);
        }
      } else {
        if (updatebtndisabled === false) {
          setUpdatebtndisabled((q) => !q);
        }
      }
    } else if (updatedetailsstatusselected === "In") {
      if (updatedeintime !== "" && updatedeintime === admdaywiseatnpage_slice.updateresultsdata.time_in === null ? null : `${admdaywiseatnpage_slice.updateresultsdata.time_in}` && (updateouttime === admdaywiseatnpage_slice.updateresultsdata.time_out === null ? null : `${admdaywiseatnpage_slice.updateresultsdata.time_out}`) && updatelatetime === `${admdaywiseatnpage_slice.updateresultsdata.late}` && updatedetailsstatusselected === getstatusformat(admdaywiseatnpage_slice.updateresultsdata.status)) {
        if (updatebtndisabled === true) {
          setUpdatebtndisabled(q=>!q);
        } 
      } else {
        if (updatebtndisabled === false) {
          setUpdatebtndisabled(q=>!q);
        }
  
      }
    }
  }; */
  const newupdatestatusselected = (event) => {
    setUpdatedetailsstatusselected(event.target.value);
    console.log("dsd")
    
  };
  const newupdateinselected = (event) => {
    setUpdateintime(event.target.value.trim());
  };
  const newupdateoutselected = (event) => {
    setUpdateouttime(event.target.value.trim());
  };
  const newupdatelateselected = (event) => {
    const newValue = parseFloat(event.target.value);
  
    
    setUpdatelatetime(event.target.value.trim());
  };


  const [showaccorupdate, setShowaccorupdate] = useState(false);
  const handleidchange = (event) => {
    if (event.target.value.trim() === "") {
      console.log(event.target.value);
      setEmpid("");
    } else {
      setEmpid(event.target.value.trim());
    }
  };
  const handleshownewlist = () => {
    calladmdaywiseatn_get_api("null");
  };
  const [updatepressed,setUpdatepressed] = useState(false)
  const [atnpostmsg,setAtnpostmsg] = useState('')


  useEffect(()=> {
    if (admdaywiseatnpage_slice.admupdateatnpostresponsebody != '' && updatepressed === true) {
      
      setAtnpostmsg(admdaywiseatnpage_slice.admupdateatnpostresponsebody)
      console.log("sss")
      setUpdatepressed(q=>!q)
      
      

    }
  },[admdaywiseatnpage_slice.admupdateatnpostresponsebody])
  useEffect(()=> {
    console.log(atnpostmsg)
    if (atnpostmsg !== '') {
      
      if (atnpostmsg === "done") {
        
        swal("Attendance updated!", {
          buttons: false,
          icon:"success",
          title:"Success!"
        });
      } else  {
          swal("Attendance update failed!", {
            buttons: false,
            icon:"error",
            title:"Failed!"
          });
        
      }
    }}
  ,[atnpostmsg])
  const checkinouttimedateparseornot = async (value)=> {
    const timeString = `01-01-2024 ${value}`;
    const parsedTime =new Date(timeString);
    console.log(parsedTime)

    console.log(isNaN(parsedTime));
    return isNaN(parsedTime) === false ? "Success" : "Fail";
  }
  const handleupdateatn_service = async () => {
    if (updatepressed === false) {
      setUpdatepressed(q=>!q)
    }
    setAtnpostmsg("")
    if (updatelatetime !== "") {
      if (parseFloat(updatelatetime) >= 0) {
        // Valid, update state
        console.log("sf")
        
        
      } else {
        console.log("sf")
        swal("Change details properly to update attendance!", {
          button: "Okay",
        });
        return
        // Handle invalid input (e.g., display an error message)
      }
    }
    if (updatedeintime !== "") {
      if (await checkinouttimedateparseornot(updatedeintime) === "Success") {
        // Valid, update state
        console.log("sf")
        
        
      } else {
        console.log("sf")
        swal("Change details properly to update attendance!", {
          button: "Okay",
        });
        return
        // Handle invalid input (e.g., display an error message)
      }
    }
    if (updateouttime !== "") {
      if (await checkinouttimedateparseornot(updateouttime) === "Success") {
        // Valid, update state
        console.log("sf")
        
        
      } else {
        console.log("sf")
        swal("Change details properly to update attendance!", {
          button: "Okay",
        });
        return
        // Handle invalid input (e.g., display an error message)
      }
    }
    
    

    
    
    
    if (updatedetailsstatusselected === "Absent" || updatedetailsstatusselected === "Holiday" || updatedetailsstatusselected === "Leave") {
      
      if ((updatedeintime === "" && updateouttime === "" && (updatelatetime === "0" || updatelatetime === "")) && updatedetailsstatusselected !== getstatusformat(admdaywiseatnpage_slice.updateresultsdata.status)) {
        
        console.log("ss")
        callupdate_put_api()
      } else {
        console.log("ss")
        
        swal("Change details properly to update attendance!", {
          button: "Okay",
        });
  
      }
    } else if (updatedetailsstatusselected === "Present" ) {
      console.log("pre")
      console.log(updatedeintime)
      console.log(admdaywiseatnpage_slice.updateresultsdata.time_in)
      console.log(updatedeintime !== `${admdaywiseatnpage_slice.updateresultsdata.time_in}`)
      console.log(`${admdaywiseatnpage_slice.updateresultsdata.time_in}`)
      if (
        (updatedeintime !== "" && updateouttime !== "") && (`${updatedeintime}` !==
          (admdaywiseatnpage_slice.updateresultsdata.time_in ===
        null
          ? ""
          : `${admdaywiseatnpage_slice.updateresultsdata.time_in}`) ||
            `${updateouttime}` !==
              (admdaywiseatnpage_slice.updateresultsdata.time_out ===
            null
              ? ""
              : `${admdaywiseatnpage_slice.updateresultsdata.time_out}`))
      ) {
        
        callupdate_put_api()
      } else {
        swal("Change details properly to update attendance!", {
          button: "Okay",
        });
      }
    } else if (updatedetailsstatusselected === "In") {
      
      if (updatedeintime !== "" && `${updatedeintime}` !== (admdaywiseatnpage_slice.updateresultsdata.time_in === null ? "" : `${admdaywiseatnpage_slice.updateresultsdata.time_in}`) && updateouttime === "") {
        
        callupdate_put_api()
        
      } else {
        swal("Change details properly to update attendance!", {
          button: "Okay",
        });
  
      }
    } else if (updatedetailsstatusselected === "Remote") {
      
      if (updatedeintime !== "") {
        callupdate_put_api()
        
      } else {
        swal("Change details properly to update attendance!", {
          button: "Okay",
        });
  
      }
    }
  };
  const callupdate_put_api = ()=> {
    dispatch(callupdate_atn_service([`${loginpage_slice.data["access"]}`,{time_in:updatedeintime.trim() === "" ? null : updatedeintime.trim(),time_out: updateouttime.trim() === "" ? null : updateouttime.trim(),late: updatelatetime.trim() === "" ? "0" : "0",status: updatedetailsstatusselected=== "Absent" ? "A" : updatedetailsstatusselected === "Present" ? "P" : updatedetailsstatusselected  === "In" ? "I" : updatedetailsstatusselected === "Remote" ? "R" : updatedetailsstatusselected === " Leave" ? "L" : "H" },format(admdaywiseatnpage_slice.updateresultsdata.wdate, "yyyy-MM-dd"),admdaywiseatnpage_slice.updateresultsdata.empdetails
       .areg_sno]))

  }
  const pdfref = useRef();
  const downloadpdf = useReactToPrint({
    content: () => pdfref.current,

    documentTitle: `Attendance_${getdateformatvalue(
      selecteddate
    )}_status_${daywisestatusselected}`,
  });
  return (
    <div className="lateabsentchklist">
      <div className="updateladivchklist">
        {admdaywiseatnpage_slice.updategetloading && (
          <div className="loadforupdateadmatnsingle">
            <Loader_rotatelines />
          </div>
        )}
        <button
          onClick={() => setShowaccorupdate((q) => !q)}
          className="updatetogglebtnchklist"
        >
          {" "}
          {showaccorupdate ? (
            <IoIosArrowDropupCircle size={20} />
          ) : (
            <IoIosArrowDropdownCircle size={20} />
          )}{" "}
          Update attendance
        </button>
        {showaccorupdate && (
          <div className="laupdateaccorchklist">
            <div className="atnupdatetopline">
              <div className="laaccorelementinputchklist">
                <span>Date</span>
                <div
                  className="lachklistdatepicker"
                  onClick={() => {
                    setDatebtnselected((q) => !q);
                  }}
                >
                  <Datepicker
                    selected={updateselecteddate}
                    onChange={(date) => handleupdatedateselected(date)}
                    maxDate={new Date()}
                    closeOnScroll
                    onCalendarClose={() => setUpdatedatebtnselected((q) => !q)}
                    displayFormat="MMMM YYYY"
                    dateFormat="yyyy, MMMM"
                    customInput={
                      <button
                        className={`monthpickerlachklist ${
                          updatedatebtnselected ? "active" : ""
                        }`}
                      >
                        {format(updateselecteddate, "dd-MM-yyyy")}
                      </button>
                    }
                  />
                </div>
              </div>
              <div className="laaccorelementinputchklist">
                <span>
                  Employee ID <span id="asteriskspan">*</span>
                </span>
                <input
                disabled={(admdaywiseatnpage_slice.updategetloading) ? true : false}
                  value={empid}
                  type="number"
                  onChange={handleidchange}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(event)=> {
                    if (event.key === "Enter") {
                      if (empid !== "") {
                        calladmsingleempatn_get_api()
                      }
                    }
                  }}
                  placeholder="Employee ID"
                />
              </div>
              <button
                disabled={(empid === "" ? true : false || admdaywiseatnpage_slice.updategetloading) ? true : false}
                className="laempsearbtnchklist"
                onClick={() => calladmsingleempatn_get_api()}
              >
                Search
              </button>
            </div>
            {admdaywiseatnpage_slice.updateresultsdata === null ? ((upderror) && <div className='nonefoundadmday'>
          <h3>{upderror === "noemp" ? "No employee found!" : "No attendance found!"}</h3>
        </div>) : (
              <div className="lachklistupdatedetailssection">
                <div className="updatedetgridcontainer">
                  <div className="updateinfoelem">
                    <span>Employee name:</span>
                    <input
                      readOnly
                      type="text"
                      placeholder="Employee name"
                      value={
                        admdaywiseatnpage_slice.updateresultsdata.empdetails
                          .ename
                      }
                    />
                  </div>
                  <div className="updateinfoelem">
                    <span>Employee ID:</span>
                    <input
                      readOnly
                      type="text"
                      placeholder="Employee ID"
                      value={
                        admdaywiseatnpage_slice.updateresultsdata.empdetails
                          .areg_sno
                      }
                    />
                  </div>

                  <div className="updateinfoelem">
                    <span>Department:</span>
                    <input
                      readOnly
                      type="text"
                      placeholder="Department"
                      value={
                        admdaywiseatnpage_slice.updateresultsdata.empdetails
                          .dept
                      }
                    />
                  </div>
                  <div className="updateinfoelem">
                    <span>Date:</span>
                    <input
                    readOnly
                    onChange={newupdateinselected}
                      type="text"
                      placeholder="--:--:--"
                      value = {format(admdaywiseatnpage_slice.updateresultsdata.wdate, "dd-MM-yyyy")}
                    />
                  </div>
                  <div className="updateinfoelem">
                    <span>In time:</span>
                    <input
                    onChange={newupdateinselected}
                      type="text"
                      placeholder="--:--:--"
                      value={updatedeintime}
                    />
                  </div>
                  <div className="updateinfoelem">
                    <span>Out time:</span>
                    <input
                    onChange={newupdateoutselected}
                      type="text"
                      placeholder="--:--:--"
                      value={updateouttime}
                    />
                  </div>
                  <div className="updateinfoelem">
                    <span>Late:</span>
                    <input onChange={newupdatelateselected} type="number" onWheel={(e) => e.target.blur()} placeholder="" value={updatelatetime} />
                  </div>
                  <div className="updateinfoelem">
                    <span>Status: </span>
                    <select
                      className="seslectdeptmonthsum"
                      value={updatedetailsstatusselected}
                      onChange={newupdatestatusselected}
                    >
                      <option> Present</option>
                      <option> In</option>
                      <option> Leave</option>
                      <option> Absent</option>
                      <option> Remote</option>
                      <option> Holiday</option>
                    </select>
                  </div>
                </div>
                <button
                  disabled={false}
                  onClick={() => handleupdateatn_service()}
                  className="laempupdatebtnchklist"
                >
                  Update
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {(admdaywiseatnpage_slice.loading ||
        loginpage_slice.loading ||
        adminpanelpage_slice.loading) && (
        <div className="loader_login">
          <Loader_rotatelines />
        </div>
      )}

      {admdaywiseatnpage_slice.resultsdata !== null && (
        <div className="lacheckdivchklist">
          <h3 style={{ textAlign: "center" }}>Employee daily attendance</h3>
          <div className="searchempchklist">
            <div className="searchemplaelementinputchklist">
              <span>Date</span>
              <div
                className="lachklistdatepicker"
                onClick={() => {
                  setDatebtnselected((q) => !q);
                }}
              >
                <Datepicker
                  selected={selecteddate}
                  onChange={(date) => handledateselected(date)}
                  maxDate={new Date()}
                  closeOnScroll
                  onCalendarClose={() => setDatebtnselected((q) => !q)}
                  displayFormat="MMMM YYYY"
                  dateFormat="yyyy, MMMM"
                  customInput={
                    <button
                      className={`monthpickerlachklist ${
                        datebtnselected ? "active" : ""
                      }`}
                    >
                      {format(selecteddate, "dd-MM-yyyy")}
                    </button>
                  }
                />
              </div>
            </div>
            <div className="searchemplaelementinputchklist">
              <span>Status</span>
              <select
                className="selectdeptlachklist"
                value={daywisestatusselected}
                onChange={newdaywisestatusselected}
              >
                <option>All</option>
                <option> Present</option>
                <option> In</option>
                <option> Late</option>
                <option> Leave</option>
                <option> Absent</option>
                <option> Remote</option>
                <option> Holiday</option>
              </select>
            </div>

            <button
            disabled={(admdaywiseatnpage_slice.loading) ? true : false}
              onClick={() => handleshownewlist()}
              className="laempsearbtnchklist"
            >
              Search
            </button>
          </div>
          <div className="dailyattnlistcon">
            <h3
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
                background: "rgb(47, 124, 255)",
                width: "100%",
                color: "white",
              }}
            >
              Total {admdaywiseatnpage_slice.count} attendances
            </h3>
            <div className="emplaheadchklist">
              <p className="emplaheaditemchklist">Name</p>
              <p className="emplaheaditemchklist">ID</p>
              <p className="emplaheaditemchklist">Department</p>
              <p className="emplaheaditemchklist">In time</p>
              <p className="emplaheaditemchklist">Out time</p>
              <p className="emplaheaditemchklist">Status</p>
            </div>
            {admdaywiseatnpage_slice.resultsdata.map((q, i) => (
              <div key={i} className="empladetailschklist">
                <p className="empdetailsitemchklist">
                  <span>{i + 1}. </span> {q.empdetails.ename}
                </p>
                <p className="empdetailsitemchklist">{q.empdetails.areg_sno}</p>
                <p className="empdetailsitemchklist">{q.empdetails.dept}</p>
                <p className="empdetailsitemchklist">{q.time_in}</p>
                <p className="empdetailsitemchklist">{q.time_out}</p>
                <p
                  style={{ color: getstatuscolorformat(q.status) }}
                  className="empdetailsitemchklist"
                >
                  {getstatusformat(q.status)}
                </p>
              </div>
            ))}
            <div
              className="printwholedivlateabsent"
              style={{ display: "none" }}
            >
              <div
                className="printdetailslateabsent"
                ref={pdfref}
                id="printpdfidlateabsent"
              >
                <div className="toplinelateabsent"></div>
                <p className="printmonthplateabsent">
                  {getdateformatvalue(selecteddate)}
                </p>
                <p className="printdeptplateabsent">
                  Attendane Status:{" "}
                  <span className="printstatusspan">
                    {daywisestatusselected}
                  </span>
                </p>

                <div className="tablecontainerlateabsent">
                  <table id="lateabsentprinttable">
                    <tr className="lasteabsentprinttr">
                      <th className="lateabsentprinthead">SL NO</th>
                      <th className="lateabsentprinthead">Name</th>
                      <th className="lateabsentprinthead">ID</th>
                      <th className="lateabsentprinthead">Department</th>
                      <th className="lateabsentprinthead">In Time</th>
                    </tr>
                    {admdaywiseatnpage_slice.resultsdata.map((q, i) => (
                      <tr key={i}>
                        <td className="lateabsentprinthead">{i + 1}.</td>
                        <td className="lateabsentprinthead">
                          {q.empdetails.ename}
                        </td>
                        <td className="lateabsentprinthead">
                          {q.empdetails.areg_sno}
                        </td>
                        <td className="lateabsentprinthead">
                          {q.empdetails.dept}
                        </td>
                        <td className="lateabsentprinthead">{q.time_in}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
            {admdaywiseatnpage_slice.resultsdata.length ===
              admdaywiseatnpage_slice.count && (
              <button
                className="downloadpdflachklist"
                onClick={() => downloadpdf()}
              >
                Download PDF
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Emplateabsent;
