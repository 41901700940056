import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import "./Myemployees.css"
import { useDispatch, useSelector } from 'react-redux';
import { callreftoken_service, callunauth_logout_service, handlenoactcookie } from '../../Redux/Slice/Loginslice';
import { callmyemppanel_service } from '../../Redux/Slice/Myemployeesslices/Myemppanelslice';
import { Loader_rotatelines } from '../../App';
const Myemployees = () => {
    const navigate = useNavigate();

  const loginpage_slice = useSelector((state) => state.login);
  
  

  const myemppanelpage_slice = useSelector((state) => state.myemppanel);
  const dispatch = useDispatch();
  useEffect(() => {
    if (loginpage_slice.data['access'] === "" && loginpage_slice.problemhappened === false) {
      console.log(1)
      dispatch(callreftoken_service());
      
    } else {
      if (myemppanelpage_slice.data !== null) {
        callmyemppanel_get_api()

      }
      //
    }
  },[])
  useEffect(()=> {
    console.log(loginpage_slice.reftokenfetched)
    if (loginpage_slice.reftokenfetched === true) {
      if (loginpage_slice.data["loggedin"] === false) {
        navigate("/")
        console.log("ss")
    } else {
      console.log("s")
    }
    }
  },[loginpage_slice.reftokenfetched])
  useEffect(()=> {
    if (loginpage_slice.problemhappened === true) {
      console.log(loginpage_slice.data["loggedin"])
    navigate("/")
    }
  },[loginpage_slice.problemhappened])
  useEffect(()=> {
    console.log(loginpage_slice.error)
    
    if (myemppanelpage_slice.error === "alreadyloggedout") {
      console.log("s")
      
      dispatch(callunauth_logout_service())
    } else if (myemppanelpage_slice.status==="401") {
      
      dispatch(handlenoactcookie())
    }

  },[myemppanelpage_slice.error,myemppanelpage_slice.status])

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (loginpage_slice.data['access'] !== '' && myemppanelpage_slice.data === null) {
      callmyemppanel_get_api() }
  },[loginpage_slice.data['access']])


  const callmyemppanel_get_api = ()=> {
    dispatch(callmyemppanel_service(`${loginpage_slice.data['access']}`))
  
  }

  useEffect(()=> {
    console.log(loginpage_slice.data['access'])
    if (myemppanelpage_slice.data != null) {

      if (myemppanelpage_slice.data !== "done") {
        navigate("/dashboard")
      }
    }
  },[myemppanelpage_slice.data])



























  
  return (
    <div className='myempouter'>
      {(myemppanelpage_slice.loading || loginpage_slice.loading) && <div className="loader_login"><Loader_rotatelines  /></div> }
      {(myemppanelpage_slice.loading === false && loginpage_slice.loading === false) && <div className='myemppanels'>
      <NavLink className={"myempnav"} contextMenu  to="/myemployees/empleaveapplications">
    
    <div contextMenu className="myemppanelelement" onClick={()=>navigate("/myemployees/empleaveapplications")}>
      <div className="myemppanelcircle" style={{background:"red"}}></div>
      <div className="myemppaneltitle">Employee Leave Applications</div>
    </div>
    </NavLink>
    <NavLink className={"myempnav"} contextMenu  to="/myemployees/empmonthsummary">
    <div className="myemppanelelement" onClick={()=>navigate("/myemployees/empmonthsummary")}>
      <div className="myemppanelcircle" style={{background:"green"}}></div>
      <div className="myemppaneltitle">Employee Monthly Attendance Summary</div>
    </div>
                </NavLink>
    
                
    
    
    

  </div>}
    </div>
  )
}

export default Myemployees