import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useContext } from "react";
import { Context, myport } from "../../App";
import { useDispatch } from "react-redux";



export const calllogin_service = createAsyncThunk("calllogin", async (data) => {
    console.log(`${myport}userlogin_get/`)
    const response = await fetch(`${myport}userlogin_get/`,{method:'POST',body: JSON.stringify(data),headers: {
        'content-type': 'application/json'
      },credentials:"include"
      ,});
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status];
})
export const calllogout_service = createAsyncThunk("calllogout", async (data) => {
    console.log(`${myport}userlogin_get/`)
    const response = await fetch(`${myport}userlogout/`,{method:'GET',headers: {
        'Authorization': `Bearer ${data}`,
      },credentials:"include"
      ,});
      
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status];
})
export const callunauth_logout_service = createAsyncThunk("callunauth_logout", async (data) => {
    console.log(`${myport}userlogin_get/`)
    const response = await fetch(`${myport}unauthorizeduserlogout/`,{method:'GET',credentials:"include"
      ,});
      
      console.log(response.status)
      const jsondata = await response.json();
      console.log(data)
      
      return [jsondata,response.status,data];
})

export const callreftoken_service = createAsyncThunk("callreftoken", async () => {
    
    const response = await fetch(`${myport}reftoken/`,{credentials:"include"} );
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status];
})
export const callreftoken_interval_service = createAsyncThunk("callreftokeninterval", async () => {
    
    const response = await fetch(`${myport}reftoken/`,{credentials:"include"} );
      console.log(response.status)
      const jsondata = await response.json();
      
      return [jsondata,response.status];
})

export const loginSlice = createSlice({
    name: "login",
    initialState: {
        data: {
            'refresh':"",
            'access':"",
            "tl":0,
            "adm":0,
            "assthd":0,
            "hd":0,
            "mysno":0,
            "departments": null,
            "loggedin":false
        },
        loading: false,
        status: "",
        error: null,
        reftokenfetched:false,
        problemhappened: false,
        logoutpressed: false

      },
      reducers: {
        handlenoactcookie(state, action) {
            state.problemhappened = false;
            console.log(state.error);
            state.error = null;
            state.data["refresh"] = '';
            state.data["access"] = '';
            state.data["tl"] = '';
            state.data["adm"] = '';
            state.data["assthd"] = '';
            state.data["hd"] = '';
            state.data["mysno"] = '';
            state.data["departments"] = '';
            state.data["loggedin"] = false;
            state.status = "";
            state.loading = false;
            state.problemhappened = true;
                
          },
        handlepasswordchange(state, action) {
            state.problemhappened = false;
            console.log(state.error);
            state.error = null;
            state.data["refresh"] = '';
            state.data["access"] = '';
            state.data["tl"] = '';
            state.data["adm"] = '';
            state.data["assthd"] = '';
            state.data["hd"] = '';
            state.data["mysno"] = '';
            state.data["departments"] = '';
            state.data["loggedin"] = false;
            state.status = "";
            state.loading = false;
            state.problemhappened = true;
                
          },
          handleloginloading(state,action) {
            state.loading = true;
          }
          
      },
      
      extraReducers: (builder) => {
        
        builder.addCase(calllogin_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(calllogin_service.fulfilled, (state,action) => {
            
            
            if (action.payload[1] ===  200) {
                state.problemhappened = false;
                state.error = null;
                console.log(action.payload[0]['access'])
            
            
            state.data["refresh"] = action.payload[0]['refresh'];
            state.data["access"] = action.payload[0]['access'];
            state.data["tl"] = action.payload[0]['tl'];
            state.data["adm"] = action.payload[0]['adm'];
            state.data["assthd"] = action.payload[0]['assthd'];
            state.data["hd"] = action.payload[0]['hd'];
            state.data["mysno"] = action.payload[0]['mysno'];
            state.data["departments"] = action.payload[0]['departments'];
            state.data["loggedin"] = true;
            state.logoutpressed = false;
            state.status = `${action.payload[1]}`;
            console.log(action.payload[0].access)


            console.log(state.data);
            } else {
                console.log(action.payload[1])
                state.error = "donotmatch"
            }


            state.loading = false;
        });
        builder.addCase(calllogin_service.rejected, (state,action) => {
            state.loading = false;
            
            state.error = action.error.message;
        });




        builder.addCase(calllogout_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
            
        });
        builder.addCase(calllogout_service.fulfilled, (state,action) => {
           
            if (action.payload[0] === "alreadyloggedout") {
                state.error = "alreadyloggedout"

            } else if (action.payload[1] === 200) {
                console.log(action.payload[0]['access'])

            
            console.log(action.payload[0]['access'])
            state.error = null;
            state.data["refresh"] = "";
            state.data["access"] = "";
            state.data["tl"] = 0;
            state.data["adm"] = 0;
            state.data["assthd"] = 0;
            state.data["hd"] = 0;
            state.data["mysno"] = 0;
            state.data["departments"] = null;
            state.data["loggedin"] = false;
            state.reftokenfetched = true;

            
           
            
            console.log(action.payload[0].access)


            console.log(state.data);
            } else {
                state.error = null;
                

            }
            state.logoutpressed = true;
            state.status = `${action.payload[1]}`;
            state.loading = false;
        });
        builder.addCase(calllogout_service.rejected, (state,action) => {
            state.loading = false;
            
            state.error = action.error.message;
            
           
        });





        builder.addCase(callunauth_logout_service.pending, (state)=> {
            state.problemhappened = false;
            state.loading = true;
            state.error = null;
            state.data["refresh"] = "";
            state.data["access"] = "";
            state.data["tl"] = 0;
            state.data["adm"] = 0;
            state.data["assthd"] = 0;
            state.data["hd"] = 0;
            state.data["mysno"] = 0;
            state.data["departments"] = null;
            state.data["loggedin"] = false;

            state.status = "";
            state.problemhappened = true;

           
            
        });
        builder.addCase(callunauth_logout_service.fulfilled, (state,action) => {
           
            console.log(action.payload[0]['access'])
            if (state.data["access"] !== '') {
                state.reftokenfetched = false;
                console.log(action.payload[0]['access'])
            state.error = null;
            state.data["refresh"] = "";
            state.data["access"] = "";
            state.data["tl"] = 0;
            state.data["adm"] = 0;
            state.data["assthd"] = 0;
            state.data["hd"] = 0;
            state.data["mysno"] = 0;
            state.data["departments"] = null;
            state.data["loggedin"] = false;

            state.status = "";
            


            }
            state.reftokenfetched = true;

            
            
           
            
            state.loading = false;
        });
        builder.addCase(callunauth_logout_service.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
            
           
        });






        builder.addCase(callreftoken_service.pending, (state)=> {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(callreftoken_service.fulfilled, (state,action) => {
            console.log(action.payload[0].access)
            state.reftokenfetched = true;
            if (action.payload[1] === 200) {
                state.error = null;
                state.data["refresh"] = action.payload[0]['refresh'];
                state.data["access"] = action.payload[0]['access'];
                state.data["tl"] = action.payload[0]['tl'];
                state.data["adm"] = action.payload[0]['adm'];
                state.data["assthd"] = action.payload[0]['assthd'];
                state.data["hd"] = action.payload[0]['hd'];
                state.data["mysno"] = action.payload[0]['mysno'];
                state.data["departments"] = action.payload[0]['departments'];
                state.data["loggedin"] = true;
                state.status = `${action.payload[1]}`;
                
                
                
                console.log(action.payload[0].access)

                console.log(state.data);

            } else {
                state.error = null;
                state.status = `${action.payload[1]}`;

            }
            state.loading = false;
            
            
            
        });
        builder.addCase(callreftoken_service.rejected, (state,action) => {
            state.loading = false;
            state.reftokenfetched = true;
            state.error = action.error.message;
        });









        builder.addCase(callreftoken_interval_service.pending, (state)=> {
            
        });
        builder.addCase(callreftoken_interval_service.fulfilled, (state,action) => {
            console.log(action.payload[0].access)
            if (action.payload[1] === 200) {
                state.data["refresh"] = action.payload[0]['refresh'];
                state.data["access"] = action.payload[0]['access'];
                state.data["tl"] = action.payload[0]['tl'];
                state.data["adm"] = action.payload[0]['adm'];
                state.data["assthd"] = action.payload[0]['assthd'];
                state.data["hd"] = action.payload[0]['hd'];
                state.data["mysno"] = action.payload[0]['mysno'];
                state.data["departments"] = action.payload[0]['departments'];
                state.data["loggedin"] = true;
                
                
                

            } else {
               
            }
            
            
            
        });
        builder.addCase(callreftoken_interval_service.rejected, (state,action) => {
          
        });
      },
    
});

export const { handlenoactcookie,handlepasswordchange,handleloginloading } = loginSlice.actions
export default loginSlice.reducer