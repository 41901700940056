import React from 'react'
import "./Atndncdetailselement.css"
import { format } from "date-fns"
import { getdateformatvalue, getstatuscolorformat, getstatusformat } from '../../App'
const Atndncdetailselement = ({data}) => {
  
  


    
  return (
    <div className="lastfewdaysatndnc">

            
            <div className="atnhead">
                <p className='atnheaditem'>Date</p>
                <p className='atnheaditem'>In-Time</p>
                <p className='atnheaditem'>Out-Time</p>
                <p className='atnheaditem'>Late</p>
                <p className='atnheaditem'>Status</p>
            </div>
            {data.map((q,i) => (
                <div key={i} className="atndetails" >
                <p className='atndetailsitem'>{getdateformatvalue(q.wdate)}</p>
                <p className='atndetailsitem'>{q.time_in}</p>
                <p className='atndetailsitem'>{q.time_out}</p>
                <p className='atndetailsitem'>{q.late === 0 ? "" : q.late}</p>
                <p style={{color:getstatuscolorformat(q.status)}} className='atndetailsitem inout'>{getstatusformat(q.status)}</p>
            </div>
            ))}
            
            
            

        </div>
  )
}

export default Atndncdetailselement